<ion-fab vertical="top" horizontal="end" slot="fixed">
 <ion-fab-button size="small" (click)="dismiss()">
  <ion-icon name="close"></ion-icon>
 </ion-fab-button>
</ion-fab>

<ion-content class="ion-padding" color="white">

  <h3>Select Themes</h3>

  <ion-grid *ngIf="sets">
    <ion-row *ngFor="let set of sets | keyvalue">

      <ng-container *ngFor="let item of set.value">

        <ng-container *ngFor="let preset of item.presets">

          <ion-col [title]="preset.title" class="ion-text-center" *ngIf="preset.is_active" size="3" (click)="select(preset.combined)">
            <img [src]="preset.thumb"/>
            <!--<div class="ion-text-center">{{ preset.title }}</div>-->
          </ion-col>
        </ng-container>

      </ng-container>

    </ion-row>
  </ion-grid>


</ion-content>
