<ion-fab vertical="top" horizontal="end" slot="fixed">
  <ion-fab-button size="small" (click)="dismiss()">
   <ion-icon name="close"></ion-icon>
  </ion-fab-button>
</ion-fab>

<ion-content class="ion-padding" color="white">

  <div class="box">

      <b>
        Crop This Image
      </b>

      <div id="eA" [attr.style]="'background-image: url(' + this.thumb + ')'">
        <div class="editor-area thumbnail-supported" [ngClass]="{ 'loaded' : ready }">

          <ion-spinner color="white" *ngIf="!ready"></ion-spinner>

          <image-cropper [ngClass]="{ 'ion-hide': !ready }"
              [imageURL]="image"
      
              format="png"
              output="blob"
      
              (imageCropped)="imageCropped($event)"
              (imageLoaded)="imageLoaded($event)"
              (cropperReady)="cropperReady()"
              (loadImageFailed)="loadImageFailed()"
      
              [imageQuality]="90"
              [initialStepSize]="10"
              [maintainAspectRatio]="false"
      
              [cropper]="cropperPosition"
          ></image-cropper>          

        </div>
      </div>

  </div>

</ion-content>

<ion-footer>
  
  <ion-button [disabled]="!this.ready" size="large" color="primary" (click)="saveAsRecent()">
    Save Cropped
  </ion-button>
  
  <ion-button size="large" color="dark" fill="outline" (click)="dismiss()">Cancel</ion-button>

</ion-footer>