import { Component, OnInit, HostListener, ViewChild, Input } from '@angular/core';
import { ItemReorderEventDetail } from '@ionic/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AlertController, PopoverController, ModalController, IonReorderGroup, AnimationController } from '@ionic/angular';

import { FormProcessorService } from '@services/form-processor.service';
import { DataSourceService } from '@services/data-source.service';
import { AuthService } from '@services/auth.service';

import { Storage } from '@ionic/storage';

import { environment } from '@env/environment';

import { LoadingBoxComponent } from '@components/loading-box/loading-box.component';
import { AiService } from '@app/-core/services/ai.service';

import { Tracking } from '@tracking';
import { on } from 'events';

// point to app-v2 page module location
const remotePath = './../../../../app/app-v2';
const remoteTplPath = '@app/-core/components-v2/navigation-toolbar/';

@Component({
    selector: 'app-app-v2-modal-notifications',
    templateUrl: 'notifications.component.html',
    styleUrls: [ './shared-actions.scss' ],
  })
  export class ModalNotificationsComponent {
    constructor(public modalController: ModalController) {}
  
    public dismiss() {
      return this.modalController.dismiss();
    }
  }
  
  @Component({
    selector: 'app-app-v2-popover-project',
    templateUrl: 'project-actions.component.html',
    styleUrls: [ './shared-actions.scss' ],
  })
  export class PopoverProjectsComponent {
    // @ViewChild(IonReorderGroup) reorderGroup: IonReorderGroup;
  
    // public selected;
    constructor(
      public popoverController: PopoverController,
      public formProcessor: FormProcessorService,
    ) {}
  
  
    dismiss(index) {
      this.popoverController.dismiss({ index });
    }
    /*
    doReorder(ev: CustomEvent<ItemReorderEventDetail>) {
      console.log('Dragged from index', ev.detail.from, 'to', ev.detail.to);
      ev.detail.complete();
    }
    */
  }
  
  @Component({
    selector: 'app-app-v2-popover-sites',
    templateUrl: 'sites-actions.component.html',
    styleUrls: [ './shared-actions.scss' ],
  })
  export class PopoverSitesComponent {
    // @ViewChild(IonReorderGroup) reorderGroup: IonReorderGroup;
  
    // public selected;
  
    constructor(
      public popoverController: PopoverController,
      public formProcessor: FormProcessorService,
    ) {}
  
  
    dismiss(index) {
      this.popoverController.dismiss({ index });
    }
    /*
    doReorder(ev: CustomEvent<ItemReorderEventDetail>) {
      console.log('Dragged from index', ev.detail.from, 'to', ev.detail.to);
      ev.detail.complete();
    }
    */
  }
  
  @Component({
    selector: 'app-app-v2-popover-profile',
    templateUrl: 'profile.component.html',
    styleUrls: [ './shared-actions.scss' ],
  })
  export class PopoverProfileComponent {
    constructor(
      public formProcessor: FormProcessorService,
      public popoverController: PopoverController,
      public auth: AuthService,
    ) {}
  
    public dismiss() {
      return this.popoverController.dismiss();
    }
  
    public openDesignMap() {
      const event = new CustomEvent(
          'openDesignMap',
          { detail: { } }
      );
  
      (window as any).document.dispatchEvent(event);
    }
  
    intercomSupport() {
      //
      console.log( (window as any).Intercom('show') );
      return this.popoverController.dismiss();
    }
  
  }
  
  @Component({
    selector: 'app-app-v2-modal-getting-started',
    templateUrl: 'getting-started.component.html',
    styleUrls: [ './shared-actions.scss' ],
  })
  export class ModalGettingStartedComponent {
  
    @Input() sidemenuProgress;
  
    constructor(
      public modalController: ModalController,
      public formProcessor: FormProcessorService,
      public dataSource: DataSourceService,
    ) {
    }
  
    ngOnInit() {
      console.log('sidemenuProgress', this.sidemenuProgress);
      let ret = false;
      this.sidemenuProgress.data.forEach( option => {
        if ( !option.progress ) {
          if ( ret === false ) {
            option.open = true;
            ret = true;
          }
        }
      });
    }
  
    toggleOption(option) {
      if ( !option.open ) {
        this.sidemenuProgress.data.forEach( option => {
          option.open = false;
        });
        option.open = true;
      }
    }
  
    checkmarkDone(option) {
      console.log('option', option);
      option.progress = true;
      this.dataSource.postSidemenuProgress(this.dataSource.currentActiveProject.id, option.slug).subscribe( res => {
  
      });
    }
  
    public dismiss() {
      return this.modalController.dismiss();
    }
  }
  
  @Component({
    selector: 'app-app-v2-modal-create-project-ai',
    templateUrl: 'create-project-ai.component.html',
    styleUrls: [ './shared-actions.scss' ],
  })
  export class ModalCreateProjectAIComponent {
  
    data: any = {};
  
    constructor(
      public modalController: ModalController,
      public formProcessor: FormProcessorService,
      public ai: AiService,
      public dataSource: DataSourceService,
      public tracking: Tracking,
    ) {}
  
    public cancelCreation() {
      this.modalController.dismiss({ cancel: true });
    }
  
    public updateName() {
      // return this.data.projectName;
      // this.data.aiGenerationStep = true;
      this.modalController.dismiss({ update: this.data.projectName });
    }
  
    public skipModal() {
      this.modalController.dismiss();
    }
  
    public generateAI() {
      this.ai.startPlansGeneration(false);
      this.tracking.event('generate_ai_btn_campaign_create_popup', { event_category: 'Generate AI', event_label: 'Location: Campaign Create Onboarding Popup' });
      this.modalController.dismiss();
    }
  
  }
  
  @Component({
    selector: 'app-app-v2-modal-design-map',
    templateUrl: 'design-map.component.html',
    styleUrls: [ './shared-actions.scss' ],
  })
  export class ModalDesignMapComponent {
  
    prewiewComplexDropdown = 'cat';
    prewiewComplexDropdown1:any = null;
    prewiewComplexMultiDropdown = ['bird', 'cat'];

    public menuToggle: any = [
      false, false, false, false, false, false, false, false, false,
      false, false, false, false, false, false, false, false, false,
    ];
  
    public colors = [
      { key: 'primary' },
      { key: 'secondary' },
      { key: 'tertiary' },
      { key: 'success' },
      { key: 'warning' },
      { key: 'danger' },
      { key: 'dark' },
      { key: 'medium' },
      { key: 'light' },
      { key: 'white' },
    ];
  
    public colorsVariations = [
      '', '-shade', '-tint', '-translucent'
    ];
  
    public typography = [
      { key: '--ion-font-family', desc: 'Default ionic variable that applies to all elements' },
      { key: '--gw-font-menu', desc: 'Font used in outer application menu' },
      { key: '--gw-font-buttons', desc: 'Font for general buttons' },
      { key: '--gw-font-misc', desc: 'Misc font for other cases' },
    ];
  
    public inputsColor = null;
  
    constructor(
      public modalController: ModalController,
      public formProcessor: FormProcessorService,
    ) {
    }
  
    testUpdateStack() {
      for( let i = 0; i < 5; i++ ) {
        setTimeout(() => {
          console.log('testUpdateStack', i);
          this.formProcessor.promptAppUpdate();
        }, 2000 * i);
      }
    }
  
    async presentLoadingModal() {
      const modal = await this.modalController.create({
        component: LoadingBoxComponent,
        cssClass: 'loading-box-modal',
      });
      await modal.present();
      setTimeout( () => {
        // modal.dismiss();
      }, 10000);
      // return modal;
    }
  
    segmentChanged(ev) {
      console.log('segmentChanged', ev);
    }
  
    fetchDocumentValue( set, key ) {
      const target = set.find( x => x.key === key );
      if( !target ) {
        return getComputedStyle(document.documentElement.querySelector('.application-v2')).getPropertyValue(key);
      }
      if ( !target.value ) {
        const value = getComputedStyle(document.documentElement.querySelector('.application-v2')).getPropertyValue(key);
        target.value = value;
        return value;
      } else {
        return target.value;
      }
    }
  }