import { 
  Injectable,
  // ViewContainerRef,
  Injector,
  ComponentFactoryResolver,
  EmbeddedViewRef,
  ApplicationRef,

  Input
 } from '@angular/core';

import { BrainstormingUserInterfaceComponent } from './brainstorming/user-interface/user-interface.component';

import { ModalController, AlertController } from '@ionic/angular';

import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';

import { AuthService } from '../services/auth.service';

import { BehaviorSubject } from 'rxjs';

import { FunnelsCoreService } from '@app/app-v2/funnels/-core/services/funnels-core.service';
import { DataSourceService } from '../services/data-source.service';
import { AiService } from '../services/ai.service';

@Injectable({
  providedIn: 'root'
})
export class BrainstormingService {

  interfaceComponentRef: any;

  @Input() prompt: any = null;
  @Input() context: any = null;

  private textContent: string = '';

  // public data: any = null;

  generateProcessing: any = null;

  // public activeFunnelId: any = null; - Legacy
  public activeCampaignId: any = null;

  // if first use know the status, if status known dont clear on request if first clear and then execute
  public _requestHistoryCache: any = {

  };

  isRequestedHistroyRecordExists(pre) {
    if ( !this._requestHistoryCache[pre.campaign] ) return false;
    if ( !this._requestHistoryCache[pre.campaign][pre.category] ) return false;
    if ( !this._requestHistoryCache[pre.campaign][pre.category][pre.prompt_category] ) return false;
    return true;
  }

  createRequestedHistory(pre, payload, data) {
    console.log('createRequestedHistory', pre, payload, data);
    // analyze payload and decide
    if ( !this._requestHistoryCache[pre.campaign] ) {
      this._requestHistoryCache[pre.campaign] = {};

    }
    if ( !this._requestHistoryCache[pre.campaign][pre.category] ) {
      this._requestHistoryCache[pre.campaign][pre.category] = {};

    }
    if ( !this._requestHistoryCache[pre.campaign][pre.category][pre.prompt_category] ) {
      this._requestHistoryCache[pre.campaign][pre.category][pre.prompt_category] = payload;
      return true;
    }
    return false;
  }

  

  failCounter = 0;
  processQuery(payload, customPromptInput = false) {

    if ( this.ui.ai_permissions && this.ui.ai_permissions.ai_available_limit && this.ui.ai_permissions.ai_available_limit < 1 ) {

        this.ai.showAiHitLimitModal();
        return;
    }

    this.setState(2);
    this.stopProcessing();

    this.generateProcessing = true;

    if ( !this.contextPayload.selectedCategory ) {
      this.contextPayload.selectedCategory = this.ui.selectedCategory;
    }

    const prePayload = {
      campaign: this.activeCampaignId,
      category: this.contextPayload.selectedCategory,
      prompt_category: this.contextPayload.selectedPromptCategory,
    }

    if ( customPromptInput  ) {

      prePayload['category'] = 'landing_content';
      prePayload['prompt_category'] = 'custom';

      if ( ( this.contextPayload.selectedCategory && this.contextPayload.selectedPromptCategory ) && this.contextPayload.mode === 'generate' ) {
        prePayload['category'] = this.contextPayload.selectedCategory;
        prePayload['prompt_category'] = this.contextPayload.selectedPromptCategory;
      }

    }

    if ( customPromptInput && this.contextPayload.mode === 'edit' ) {

      prePayload['category'] = 'edit_content';
      prePayload['prompt_category'] = 'no_category';
      payload['prompt_slug'] = 'custom';

    }
    
    this.contextPayload.execute = {
      pre: prePayload,
      pay: payload,
    }

    let getPayload = {};
    if ( this.contextPayload.funnel && this.contextPayload.origin && this.contextPayload.origin === 'website' ) {
      getPayload['funnel'] = this.contextPayload.funnel;
    }

    const executeNewChatRequest = () => {

      this.dataSourceService.postBrainstormCategoryPromptsV2(

        prePayload.campaign,
        prePayload.category,
        prePayload.prompt_category,
  
        payload,
        getPayload
      ).subscribe((data: any) => {
        console.log('BrainstormingService dataPrompts', data);

        // History created for session
        this.createRequestedHistory(prePayload, payload, data);
  
        this.ui.results_count = data.results_count;
        this.ui.results = data.results;
        if ( !this.ui.prompts && data.prompts ) {
          this.ui.prompts = data.prompts;
        }

        this.generateProcessing = setInterval(() => {
  
          console.log('BrainstormingService processQuery', payload);
          // campaignId, aiFlowSlug, categorySlug, data
          this.dataSourceService.getBrainstormCategoryPromptsV2(
  
            prePayload.campaign,
            prePayload.category,
            prePayload.prompt_category,
            getPayload
  
          ).subscribe((data: any) => {

            console.log('BrainstormingService dataPrompts', {
              ui: this.ui,
              data: data
            });

            this.ui.ai_permissions = data.ai_permissions;

            console.log('data.ai_permissions.ai_available_limit', data);                        
            if ( data && data.ai_permissions && data.ai_permissions.ai_available_limit && data.ai_permissions.ai_available_limit < 1 ) {

              this.ai.showAiHitLimitModal();
              this.stopProcessing();
              return;
              // this.setState(-1);

            }

    
            this.ui.results = data.results;
            this.ui.results_count = data.results_count;


            // Course / Profile Polisher Step / Hooks & Headlines / Actions
            this.contextPayload.breadcrumbs = '';

            try{

            
              if ( this.contextPayload.selectedCategory ) {
                this.contextPayload.breadcrumbs +=  '  ' +this.ui.group_selector_data.find((category) => category.ai_flow_slug === this.contextPayload.selectedCategory).title;
              }

            } catch (err) {
              console.log('BrainstormingService breadcrumbs error', err);
            }try{

              if ( this.contextPayload.selectedStep ) {
                this.contextPayload.breadcrumbs +=  ' / ' +this.ui.stage_selector_data.find((step) => step.step_index === this.contextPayload.selectedStep).label;
              }

            } catch (err) {
              console.log('BrainstormingService breadcrumbs error', err);
            }try{

              if ( this.contextPayload.selectedPromptCategory ) {
                this.contextPayload.breadcrumbs +=  ' / ' +this.ui.categories.find((category) => category.slug === this.contextPayload.selectedPromptCategory).desc;
              }

            } catch (err) {
              console.log('BrainstormingService breadcrumbs error', err);
            }try{

              if ( this.contextPayload.execute.pay.prompt_slug ) {
                this.contextPayload.breadcrumbs +=  ' / ' + this.ui.prompts.find((prompt) => prompt.slug === this.contextPayload.execute.pay.prompt_slug).title;
              }

            } catch (err) {
              console.log('BrainstormingService breadcrumbs error', err);
            }


            if ( data.status === 1 || (data.results_count !== this.ui.results_count) ) {
              
              this.ui.results_count = data.results_count;
              this.ui.results = data.results;
              this.ui.prompts = data.prompts;
  
              this.ui.status = 1;
              this.stopProcessing();

            }


          }, (err) => {

            this.ai.presentAiOfflineAlert();

            this.setState(-1);
            this.stopProcessing();
          });
    
        }, 5000);
  
      }, (err) => {

        this.ai.presentAiOfflineAlert();

        this.setState(-1);
        this.stopProcessing();
      });

    };


    setTimeout(executeNewChatRequest, 500);
    /* OBSOLETE AS NATALKA IMPLEMENTED ON BACKEND SIDE
    if ( !this.isRequestedHistroyRecordExists(prePayload) ) {

      // Forcefully preclear if no last known history recorded
      this.dataSourceService.getBrainstormCategoryForgetAllV2(
        prePayload.campaign,
        prePayload.category,
        prePayload.prompt_category
      ).subscribe((data: any) => {
        executeNewChatRequest();
      });

    } else {

      setTimeout(executeNewChatRequest, 500);

    }
      */





  }


  // ? Universal AI Brainstormin Communicate //
  
  // Main functions to use
  @Input() contextPayload: any = null;

  public setup(setupString?: string, contextPayload?: any, launchMode?: any) {
    this.activeCampaignId = this.dataSourceService.currentActiveProject.id;
    this.contextPayload = contextPayload;
    this.show();
    this.setState(-1);
    this.requestState(true, launchMode);
    if ( setupString ) {
      this.setText(setupString);
    }
  }

  prepareUiData(init) {
    const preparedGroupSelector = [];
    this.ui.group_selector_data.forEach(category => {
      if ( category.selected  ) {
        if ( init || (!init && !this.contextPayload.selectedCategory )) {
          this.ui.selectedCategory = category.ai_flow_slug;
          this.contextPayload.selectedCategory = category.ai_flow_slug;
        }

      }
      if ( category.hidden ) {
        // return false;
      } else {
        preparedGroupSelector.push({
          label: category.title,
          value: category.ai_flow_slug,
        });
      }

    });
    
    // prepare data and options
    this.ui.categoryOptions = [
      {
        group: {
          label: 'Choose type for better results',
          id: 'category',
          options: preparedGroupSelector,
        }
      }
    ];

    this.ui.stepOptions = [
      {
        group: {
          label: 'Choose type for better results',
          id: 'step',
          options: this.ui.stage_selector_data.map((step) => {
            if ( step.selected ) {
              if ( init || (!init && !this.contextPayload.selectedStep )) {
                this.ui.selectedStep = step.step_index;
                this.contextPayload.selectedStep = step.step_index;
              }
              
            }
            const ind = ( parseInt(step.step_index, 10) );
            return {
              label: step.label,
              value: step.step_index,
              badge: ( ind === 1 ? 'General' : 'Step ' + (ind - 1) ),
            }
          })
        }
      }
    ];
  }

  setMode(mode){

    this.contextPayload.mode = mode;
    // this.contextPayload.selectedCategory = null;
    // delete this.contextPayload.selectedCategory;

    if ( mode === 'edit' ) {
      this.contextPayload.selectedCategory = 'edit_content';
      this.contextPayload.selectedPromptCategory = 'no_category';
    } else {
      if ( this.contextPayload.mode === 'generate' ) {
        if ( this.contextPayload.selectedCategory === 'edit_content') {
          this.contextPayload.selectedCategory = null;
        }
      }
      this.contextPayload.selectedCategory = this.ui.selectedCategory;
      this.contextPayload.selectedPromptCategory = this.ui.selectedPromptCategory;
    }
    
    this.setState(0);
  }

  requestState(init = true, launchMode?: any) {
    // this.selectedCategory = null;

    console.log('BrainstormingService activeCampaignId', this.contextPayload);
    // * New Universal
    const payload = {};
    if ( this.contextPayload ) {
      if ( this.contextPayload.asset ) {
          payload['asset'] = this.contextPayload.asset;
      }
      if ( this.contextPayload.funnel ) {
          payload['funnel'] = this.contextPayload.funnel;
    }
      if ( this.contextPayload.selectedCategory ) {
          payload['ai_flow_slug'] = this.contextPayload.selectedCategory;
      }
      if ( this.contextPayload.selectedStep ) {
          payload['step_index'] = this.contextPayload.selectedStep;
      }
    }

    if ( init ) {
      
      // delete this.contextPayload.mode;
      delete this.contextPayload.selectedCategory;
      delete this.contextPayload.selectedStep;
      delete this.contextPayload.selectedPromptCategory;
      // this.ui = null; 
    }

    this.dataSourceService.getBrainstormCategoriesV2(
      this.activeCampaignId,
      payload
    ).subscribe((nav: any) => {

      console.log('BrainstormingService init', {init});

      if (init) {


        this.ui = nav;
        this.ui.setup_array = nav.setup.split(' ');
        
        this.prepareUiData(true);

      } else {

        {
          this.ui.categories = nav.categories;
          if ( nav.prompts ) {
            this.ui.prompts = nav.prompts;
          }
          if ( nav.setup ) {
            this.ui.setup = nav.setup;
            this.ui.setup_array = nav.setup.split(' ');
          }
          if ( nav.setup_state ) {
            this.ui.setup_state = nav.setup_state;
          }
          if ( nav.launch_state ) {
            this.ui.launch_state = nav.launch_state;
          }
        }
        
        this.prepareUiData(false);

      }

      if ( launchMode ) {
        this.setMode(launchMode);
      }

      console.log('BrainstormingService ui', this.ui);

      // console.log('BrainstormingService ui', this.ui);
      console.log('NAV', nav);

      setTimeout( () => {
        if ( nav.categories && nav.categories.length === 0 ) {
          this.setState(1);
        } 
      }, 50);


    });
  }

  requestPrompts() {

    let getPayload = {};
    if ( this.contextPayload.funnel && this.contextPayload.origin && this.contextPayload.origin === 'website' ) {
      getPayload['funnel'] = this.contextPayload.funnel;
    }

    this.dataSourceService.getBrainstormCategoryPromptsV2(
      this.activeCampaignId,
      this.contextPayload.selectedCategory,
      this.contextPayload.selectedPromptCategory,
      getPayload
    ).subscribe((data: any) => {
      console.log('BrainstormingService dataPrompts', data);
      this.ui.prompts = data.prompts;
    });
    
  }

  selectCategory(category) {
    if ( this.ui && this.ui.changeLock ) return;

    setTimeout( () => {
      if ( this.contextPayload.mode === 'edit' ) {
        this.contextPayload.selectedCategory = 'edit_content';
      } else {
        this.contextPayload.selectedCategory = category;
      }

      console.log('BrainstormingService selectCategory', category, this.contextPayload, this.ui);

      this.requestState(false);
    }, 50);

  }

  selectStep(step) {
    if ( this.ui && this.ui.changeLock ) return;

    this.contextPayload.selectedStep = step;
    console.log('BrainstormingService selectStep', step, this.contextPayload, this.ui);

    // this.requestState(false);
  }

  selectPromptCategory(category) {
    if ( this.ui && this.ui.changeLock ) return;

    this.contextPayload.selectedPromptCategory = category;
    console.log('BrainstormingService selectPromptCategory', category, this.contextPayload, this.ui);

    if ( this.ui.mode === 'generate' ) {
      this.selectCategory(this.ui.selectedCategory);
    }
    this.selectStep(this.ui.selectedStep);

    this.requestPrompts();
    this.setState(1);
  }
  

  public ui: any = null;
  interfaceState: any = -1; // 0 - dashboard/actions(Edit Polish Mode) / 2 - Generate Mode / -1 - unloaded / 1 - results / 3 - result
  setState(state: any) {
    this.interfaceState = state;

    if (state === -1) {
      this.requestState(true);
    }
    if (state === 0){
      this.requestState(false);
    }
      
  }

  getState() {
    return this.interfaceState;
  }

  isState(state) {
    return this.interfaceState === state;
  }

  setText(trimmedContent: string) {
    this.textContent = trimmedContent;
  }

  getText(): string {
    return this.textContent;
  }
  


  public setHasClosedOnce(value: boolean): void {
    localStorage.setItem('hasClosedOnce', String(value));
  }

  public getHasClosedOnce(): boolean {
    const hasClosedOnce = localStorage.getItem('hasClosedOnce');
    return hasClosedOnce === 'true';
  }

  userInterfaceChangeState = new BehaviorSubject(false);

  constructor(
    public modalController: ModalController,
    public alertController: AlertController,

    // private viewRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,

    public auth: AuthService,

    public funnelsCoreService: FunnelsCoreService,
    public dataSourceService: DataSourceService,

    public ai: AiService,
  ) { 
  }

  appendComponentToBody(component: any) {
    // 1. Create a component reference from the component 
    const componentRef = this.componentFactoryResolver
      .resolveComponentFactory(component)
      .create(this.injector);

    (componentRef.instance as any).brainstorming = this;

    // 2. Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(componentRef.hostView);
    
    // 3. Get DOM element from component
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;
    
    // 4. Append DOM element to the body
    // document.body.appendChild(domElem);
    const appRoot = document.querySelector('ion-app');
    appRoot.appendChild(domElem);

    return componentRef;
  }


  public async showUserInterface(newText?: string, ev?: any): Promise<void> {

    console.log('showUserInterface event payload', { ev });

    this.userInterfaceChangeState.next(true);

    this.hideUserInterface();
    this.interfaceComponentRef = this.appendComponentToBody(BrainstormingUserInterfaceComponent);
    // Set updated text
    this.setText(newText);
    // console.log('compRef => ', this.interfaceComponentRef);
  }

  public hideUserInterface(): void {

    if ( this.interfaceComponentRef ) {
      this.appRef.detachView(this.interfaceComponentRef.hostView);
      this.interfaceComponentRef.destroy();
      this.interfaceComponentRef = null;

      this.setState(-1);
      this.userInterfaceChangeState.next(false);
    }
  }

  public isAvailiable(override?: boolean) {
    if ( override ) {
      return override;
    }
    return this.auth && this.auth.data;
  }


  public isPresented() {
    if ( this.interfaceComponentRef ) {
      return true;
    } else {
      return false;
    }
  }

  // aliases
  show(newText?: string) {
    return this.showUserInterface(newText);
  }

  hide() {
    return this.hideUserInterface();
  }

  forgetContext() {
    
    this.clearSet(true);

  }

  resultPointer = 0;
  isCanMoveResultPointer(direction: any) {
    if ( direction === 1 ) {
      return this.resultPointer < this.ui.results.length - 1;
    } else {
      return this.resultPointer > 0;
    }
  }
  moveResultPointer(direction: any) {
    if ( direction === 1 ) {
      this.resultPointer++;
    } else {
      this.resultPointer--;
    }
  }
  selectedResult(prepared) {
    try{
      let ret = this.ui.results[this.resultPointer];
      if (prepared) {
        ret = ret.result;
        if ( Array.isArray(ret) ) {
          
        } else {
          ret = [ret];
        
        }
      }
      return ret;
    } catch (err) {
      return false;
    }
    
  }

  stopProcessing() {
    if ( this.generateProcessing ) {
      clearInterval(this.generateProcessing);
      this.generateProcessing = null;
    }
  }

  clearSet(all = false){
    
    // getBrainstormCategoryForgetLastV2

    // alert('Clearing');

    let prePayload = {
      campaign: this.activeCampaignId,
      category: this.contextPayload.selectedCategory,
      prompt_category: this.contextPayload.selectedPromptCategory,
    };

    if ( !this.contextPayload.selectedCategory || !this.contextPayload.selectedPromptCategory ) {
      if ( this.contextPayload.mode === 'generate' ) {
        prePayload['category'] = 'landing_content';
        prePayload['prompt_category'] = 'custom';
      }
      if ( this.contextPayload.mode === 'edit' ) {
        prePayload['category'] = 'edit_content';
        prePayload['prompt_category'] = 'no_category';
      }
    }

    
    let endpoint = this.dataSourceService.getBrainstormCategoryForgetLastV2(
      prePayload.campaign,
      prePayload.category,
      prePayload.prompt_category
    );
    if ( all ) {
      endpoint = this.dataSourceService.getBrainstormCategoryForgetAllV2(
        prePayload.campaign,
        prePayload.category,
        prePayload.prompt_category
      );
    }


    if ( prePayload.campaign && prePayload.category && prePayload.prompt_category ) {

      endpoint.subscribe((data: any) => {
        this.resultPointer = 0;
        console.log('BrainstormingService dataCategories', data);
        this.ui.results = data.results;
        
        if ( data.prompts ) {
          this.ui.prompts = data.prompts;
        }
        
  
      }
      );

    }



  }

}
