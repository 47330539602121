import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { DataSourceService } from '@services/data-source.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.page.html',
  styleUrls: ['./alert.page.scss'],
})
export class AlertPage implements OnInit {

  constructor(
    public modalCtrl: ModalController,
    public dataSource: DataSourceService
  ) { }

  ngOnInit() {
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      dismissed: true
    });
  }

  ok() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      forward: true
    });
  }

}
