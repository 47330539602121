// Angular
import { Component, OnInit, ViewChild } from '@angular/core';

// Ionic
import { LoadingController, ModalController, PopoverController, AlertController, AnimationController } from '@ionic/angular';

@Component({
  selector: 'wizard-wrapper-abstraction',
  template: '',
  // templateUrl: './element-modal.component.html',
  // styleUrls: ['./element-modal.component.css']
})
export class WizardWrapperAbstraction implements OnInit {

  isHydrated = false;

  @ViewChild('wizardRef', {static: false}) wizardRef: any;

  loading: any = false;
  loadingInterval: any = null;
  loadingPhraseSet = [
    'AI is waiting for your request...',

    "OK!",
    "Now we are cooking with gas",
    "This may take me a full 2 minutes",
    "To generate your product roadmap steps",
    "This product is so bad ass",
    "Working on cool names for each step",
    "HA… that’s a funny one",
    "Oh man your customers are going to freak out",
    "Even Aaron is going to love this",
    "Do you know the human head weighs 8 pounds?",
    "Be patient, it's not like we're saving you weeks of work... Oh, wait.",
    "In the time it takes us, you could learn a new language. Or not.",
    "Just think, you could be doing this yourself. Terrifying, right?",
    "Crunching weeks into minutes... Grab a coffee.",
    "Re-calibrating flux capacitor... Hang tight!",
    "Doing the impossible. Applause welcome.",
    "Summoning unicorns. Magic takes time!",
    "Lightning-speed, with a slight limp.",
    "Brewing productivity potion. Patience!",
    "Saving you from manual labor. Hold on.",
    "Faster than drying paint. Promise.",
    "Condensing time... Enjoy the suspense.",
    "Hide and seek with data. We're winning!",
    "Rushing? Remember, we're your time-savers.",
    "Data feast simmering. Sit tight.",
    "Working while you're chilling... Hang tight.",
    "Beating a time machine. Hang on.",
    "Ensuring it's not half-baked. Wait up."
  ];

  constructor(
    public modalController: ModalController,
  ) { 
    setTimeout( () => {
      this.isHydrated = true;
    }, 250);
  }

  ngOnInit(): void {
    console.log('Wizard Wrapper Abstraction');
    this.endLoadingAnimation();
  }

  closeWizard() {
    this.endLoadingAnimation();
    this.modalController.dismiss();
  }

  currentLoadingPhrase() {
    if ( this.loading ) {
        return this.loadingPhraseSet[this.loading];
    } else {
        return this.loadingPhraseSet[0];
    }
  }

  startLoadingAnimation() {
    this.loading = 1;
    clearInterval(this.loadingInterval);
    this.loadingInterval = setInterval( () => {
        this.loading++;
        if (this.loading >= this.loadingPhraseSet.length) {
          this.loading = 1;
        }
    }, 2500);
  }

  endLoadingAnimation() {
    clearInterval(this.loadingInterval);
    this.loading = false;
  }

}