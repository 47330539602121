import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { FormProcessorService } from '@app/-core/services/form-processor.service';

import { ImageBrowserComponent } from '@app/-core/components/image-browser/image-browser.component';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'gw-image-uploader',
  templateUrl: './image-uploader-interface.component.html',
  styleUrls: ['./image-uploader-interface.component.scss'],
})
export class ImageUploaderInterfaceComponent implements OnInit {

  ready = true;

  @Input() public value: any;
  @Input() public name: any;

  @Output() onValueChange = new EventEmitter();


  constructor(
    public formProcessor: FormProcessorService, // use for invoke image uploader
    public modalController: ModalController,
  ) { }

  ngOnInit() {}

  // use form processor to invoke image uploader
  async invokeUploader(ev: any) {

    const modal = await this.modalController.create({
      component: ImageBrowserComponent,
      cssClass: 'application-v2 modal-v2 solid-backdrop image-browser',
      showBackdrop: true,
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    console.log('ImageUploaderInterfaceComponent ImageBrowserComponent', data);

    if ( data && data.value ) {
      this.value = data.value;
      if ( data.image ) {
        this.name = data.image.name;
      }
      // this.name = data.image.name;
      // this.formProcessor.setFormValue(this.name, data.value);
      this.onValueChange.emit(data.value);
      console.log('emitImageSelect', data.value);
    }

  }

  clearDataValue(ev: any) {
    this.ready = false;
    setTimeout(() => {
      this.onValueChange.emit(null);
      this.value = null;
      this.name = null;
      this.ready = true;
    }, 1000);
  }

}
