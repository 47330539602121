import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { DataSourceService } from '@services/data-source.service';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.page.html',
  styleUrls: ['./detail.page.scss'],
})
export class DetailPage implements OnInit {

  @Input() data;
  item = null;

  constructor(
    public modalCtrl: ModalController,
    public dataSource: DataSourceService
  ) { }

  ngOnInit() {
    console.log(this.data);
    // api/v1/growthworks/templates/{id}/detail/
    this.dataSource.viewTemplate(this.data.id).subscribe( (res) => {
      console.log('res', res);
      this.item = res;
      // this.item.is_published = Boolean(Math.round(Math.random()));
    });
  }

  dismiss() { 
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      dismissed: true
    });
  }

  selectTemplate(template) {
    console.log(template);
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      selected: template
    });
  }

}
