// GrowthWorks
import { WizardWrapperAbstraction } from './../wizard-modal-builder.class';
import { DataSourceService } from '@app/-core/services/data-source.service';

// Angular
import { Component, OnInit, Input, ViewChild } from '@angular/core';

// Ionic
import { LoadingController, ModalController, PopoverController, AlertController, AnimationController } from '@ionic/angular';
import { Router } from '@angular/router';
import { FunnelsCoreService } from '@app/app-v2/funnels/-core/services/funnels-core.service';

import { WebsiteHitLimitComponent } from '@app/-core/components-v2/website-hit-limit/website-hit-limit.component';

// Implementation
@Component({
  selector: 'app-wizard-website-create-modal',
  templateUrl: './wizard-website-create-modal.page.html',
  styleUrls: ['./wizard-website-create-modal.page.scss'],
})
export class WizardWebsiteCreateModalPage extends WizardWrapperAbstraction implements OnInit {

  // Overriding loading set
  loadingPhraseSet = [
    'AI is waiting for your request...',

    'Creating your authority site now',
    'Checking the specs on the rotary girder',
    'This thing is going to be amazing',
    'Make sure you have a custom domain setup for your new site!',
    'Putting final touches on it as we speak',
    'Did you know that the human head weighs 8 pounds?',
    'Fastest land animal?',
    'Cheetah... (You should know that)',
    'One on one sec',
    'Ok I am back',
    'Needed a quick AI nap',
    'Even AI has to rest you know...',
    'Wait for it',
    'Wait for it',
    'Wait for it',
    'Wait for it',
  ];

  values = {
    campaign: null,
    hot_step: null,
  };

  // id = 0;

  data = null;

  @Input() inputs: any = {};

  retryInterval;

  constructor(
    public modalController: ModalController,
    public router: Router,

    public dataSource: DataSourceService,
    public funnelService: FunnelsCoreService
  ) {
    super(modalController);
  }

  ngOnInit() {
    console.log('Wizard Website Create Modal Initiated', this.inputs);
    // load wizard state
    this.dataSource.getFunnelWebsiteData().subscribe((data) => {
      this.data = data;
    }, err => {
      this.closeWizard();
    });
  }

  checkLandingBackendBuilt(funnelId) {
    clearTimeout(this.retryInterval);

    const projectId = 0; //
    
    this.dataSource.getFunnelDetails(projectId, funnelId).subscribe( res => {

      if ( res && res.map_status === 'READY' ) {
        setTimeout( () => {
          this.closeWizard();
          this.router.navigate(['/app/funnel/' + 0 + '/view/' + res.id + '/map'], { queryParams: { generated_at: + new Date() } }).then(()=>{
            // do whatever you need after navigation succeeds
            setTimeout( () => {
              (window as any).location.reload();
            });
          });
        }, 2000);
      } else {
        this.retryInterval = setTimeout( () => {
          this.checkLandingBackendBuilt(funnelId);
        }, 5000);
      }

      // console.log('FUNNELS :: setActiveProject', this.activeFunnelData);
    }, err => {

      console.log('FUNNELS :: Error / Wait');
      this.retryInterval = setTimeout( () => {
        this.checkLandingBackendBuilt(funnelId);
      }, 5000);

    });
  }

  saveOptions() {
    //startLoadingAnimation
    this.startLoadingAnimation();

    // const campaignId = this.values.campaign;

    const data: any = {};

    if ( !this.values.hot_step ) {
      delete this.values.hot_step;
    } else {
      data.hot_step = parseInt(this.values.hot_step, 10) - 1;
    }

    let campaignId = 0;
    try {
      campaignId = this.data.campaigns[this.values.campaign - 1].id;
    } catch (err) {
      // -
    }

    // delete this.values.campaign;

    console.log('campaignId', campaignId);
    // return;

    this.dataSource.createFunnelWithQuery(campaignId, this.inputs.selectedFunnelItem, this.inputs.selectedThemeItem, this.inputs.selectedColorItem, data).subscribe( (res) => {
      console.log('this.dataSource.createFunnel', res);
      setTimeout( () => {
        // Hook into landing buitness status

        const funnelId = res.id;

        this.checkLandingBackendBuilt(funnelId);

      }, 100);
    }, err => {

      this.modalController.create({
        component: WebsiteHitLimitComponent,
        cssClass: 'application-v2 modal-v2 websites-hit-limit',
        componentProps: {
          // any if needed (...obj)
        },
      }).then( async (modal) => {
        await modal.present();
      });

      this.endLoadingAnimation();
    });

  }

}
