<div class="application-v2">
	<div>
	
	  <div>
	
	    <div (click)="action(0)" class="menu-item icon-end no-margin">
		<div class="label">
		  <div class="icon">
		    <ion-icon name="reload-outline"></ion-icon>
		  </div>
		  <div class="title">Start Chat Over</div>
		</div>
	    </div>

	    <div (click)="action(1)" class="menu-item icon-end no-margin">
		<div class="label">
		  <div class="icon">
		    <ion-icon name="backspace-outline"></ion-icon>
		  </div>
		  <div class="title">Clear Last Result</div>
		</div>
	    </div>

	    <div (click)="action(2)" class="menu-item icon-end no-margin">
		<div class="label">
		  <div class="icon">
		    <ion-icon name="trash-bin-outline"></ion-icon>
		  </div>
		  <div class="title">Clear All Result</div>
		</div>
	    </div>

	  </div>
	</div>
</div>
