<ion-content class="wrapper">
  <div class="btn-wrap">
    <ion-buttons>
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </div>
  
  <div class="title">What do you want to duplicate?</div>
  <div class="subtitle">Choose necessary items you want to duplicate</div>

  <div class="checkbox">

    <div class="item-box" *ngFor="let item of items; let i = index;">

      <div class="item">
        <div class="item-wrap">
          <ion-checkbox slot="start" color="dark" [checked]="item.checked" [disabled]="item.disabled" (ionChange)="onCheckboxChange(item, $event)"></ion-checkbox>
          <ion-label (click)="onCheckboxChange(item, {detail: {checked: !item.checked}})">{{item.name}}</ion-label>
        </div>
        <div class="inside-img" (click)="clickToggle(item);" *ngIf="item.id === 'asset'" [ngStyle]="{'background-image': !item.toggle ? 'url(/assets/v2/icons/arrow-up.png)' : 'url(/assets/v2/icons/arrow-down.png)'}"></div>
      </div>

      <ng-container *ngIf="!item.toggle">

        <ion-content>

          <ng-container *ngIf="item.id === 'asset'">

            <div class="item-sub" *ngFor="let subItem of cloningData.assets;">
              <div class="item-sub-wrap">
                <ion-checkbox slot="start" color="dark" [checked]="subItem.checked" (ionChange)="onSubItemCheckboxChange(item, subItem, $event)"></ion-checkbox>
                <ion-label (click)="onSubItemCheckboxChange(item, subItem, {detail: {checked: !subItem.checked}})">{{subItem.title}}</ion-label>
              </div>
              <ion-label class="update-text">Last Updated {{ subItem.updated | date:'MMMM d' }}</ion-label>
            </div>

          </ng-container>
          
        </ion-content>

      </ng-container>
    
    </div>

  </div>

  <div class="info-box">
    <img src="/assets/v2/icons/alert.png" width="24" height="24" alt="info">
    <div class="content">Duplicating assets may take up to hours</div>
  </div>

  <div class="footer">
    <ion-button (click)="duplicateCampaign()" color="primary" size="large">Duplicate Selected<ion-icon slot="end" name="copy-outline"></ion-icon></ion-button>
    <ion-button (click)="dismiss()" color="dark" fill="outline" size="large">Cancel</ion-button>
  </div>
</ion-content>