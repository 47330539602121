<ion-fab vertical="top" horizontal="end" slot="fixed">
  <ion-fab-button size="small" (click)="dismiss()">
    <ion-icon name="close"></ion-icon>
  </ion-fab-button>
  </ion-fab>
  
  <ion-content class="ion-padding exp" color="white">
  
    <div *ngIf="mode === 'subscription'">
      
    </div>

    <div class="focus">

      <svg xmlns="http://www.w3.org/2000/svg" width="51" height="73" viewBox="0 0 51 73" fill="none">
        <g clip-path="url(#clip0_11534_282712)">
        <path d="M41.0539 6.22121L12.3242 0.263428L0.267822 9.70317L18.8211 47.478L16.8822 72.7367L28.9386 63.2969L50.7322 27.2868L37.1815 24.3316L41.0539 6.22121Z" fill="white"/>
        <path d="M16.8822 73C16.839 73.0003 16.7965 72.9894 16.759 72.9684C16.7067 72.9452 16.6633 72.906 16.6353 72.8567C16.6074 72.8074 16.5963 72.7505 16.6037 72.6945L18.5372 47.5096L0.0267832 9.8138C-0.00370746 9.75893 -0.0129122 9.69504 0.000878979 9.63401C0.0146702 9.57297 0.0505197 9.51893 0.101767 9.48193L12.1582 0.0579877C12.1888 0.0343028 12.2243 0.0173475 12.2622 0.00826151C12.3 -0.00082449 12.3395 -0.0018307 12.3778 0.00531051L41.1128 5.9631C41.1471 5.96995 41.1797 5.9835 41.2086 6.00294C41.2376 6.02238 41.2622 6.04732 41.2812 6.07628C41.3002 6.10525 41.313 6.13766 41.3191 6.17159C41.3251 6.20553 41.3242 6.24031 41.3163 6.27389L37.4921 24.1314L50.7858 27.0287C50.8276 27.0365 50.8667 27.0546 50.8995 27.0813C50.9323 27.108 50.9577 27.1425 50.9732 27.1815C50.9917 27.2183 51.0012 27.2589 51.0012 27.3C51.0012 27.3411 50.9917 27.3816 50.9732 27.4185L29.1689 63.4339C29.1536 63.46 29.1337 63.4832 29.11 63.5024L17.0482 72.9421C17.0009 72.9789 16.9425 72.9993 16.8822 73ZM0.599877 9.78746L19.0621 47.3621C19.0752 47.4068 19.0752 47.4543 19.0621 47.499L17.1982 72.1677L28.735 63.1336L50.3144 27.4659L37.1226 24.5897C37.0539 24.5746 36.9941 24.5335 36.956 24.4754C36.918 24.4172 36.9047 24.3467 36.919 24.2789L40.7379 6.42139L12.3938 0.526814L0.599877 9.78746Z" fill="black"/>
        <path d="M25.1196 33.7713L37.1814 24.3316L41.0538 6.22119L28.9974 15.6609L25.1196 33.7713Z" fill="white"/>
        <path d="M25.1198 34.0348C25.0703 34.0337 25.0221 34.0191 24.9805 33.9926C24.9337 33.9638 24.8969 33.9217 24.8749 33.8719C24.8529 33.822 24.8468 33.7668 24.8574 33.7134L28.7351 15.603C28.7512 15.5439 28.7848 15.4907 28.8315 15.4503L40.8879 6.0158C40.9336 5.98476 40.9877 5.96814 41.0432 5.96814C41.0987 5.96814 41.1529 5.98476 41.1986 6.0158C41.2462 6.04365 41.2833 6.08586 41.3046 6.13617C41.3258 6.18648 41.3299 6.24218 41.3164 6.29499L37.4386 24.3843C37.429 24.4447 37.3964 24.4993 37.3476 24.5371L25.2858 33.9768C25.2385 34.0137 25.1801 34.034 25.1198 34.0348ZM29.2386 15.8032L25.5376 33.1077L36.9566 24.1842L40.663 6.87971L29.2386 15.8032Z" fill="black"/>
        <path d="M50.7321 27.2869L37.1814 24.3317L25.1196 33.7714L38.6757 36.7266L50.7321 27.2869Z" fill="white"/>
        <path d="M38.6759 36.9847H38.617L25.0609 34.0243C25.0123 34.0141 24.9676 33.9903 24.9325 33.9557C24.8973 33.9211 24.8731 33.8772 24.8627 33.8294C24.8497 33.7807 24.8513 33.7294 24.8675 33.6817C24.8836 33.6339 24.9137 33.5919 24.9538 33.5607L37.0102 24.1262C37.0413 24.1015 37.0777 24.0839 37.1166 24.0748C37.1555 24.0657 37.196 24.0653 37.2351 24.0736L50.7912 27.0288C50.8403 27.0392 50.8854 27.063 50.9214 27.0975C50.9574 27.1319 50.9828 27.1757 50.9948 27.2237C51.0053 27.2728 51.0019 27.3239 50.9849 27.3713C50.9679 27.4187 50.9379 27.4605 50.8983 27.4923L38.842 36.9321C38.7941 36.9674 38.7357 36.9859 38.6759 36.9847ZM25.709 33.6292L38.5956 36.4369L50.111 27.4238L37.2566 24.6161L25.709 33.6292Z" fill="black"/>
        <path d="M28.9975 15.6609L0.267822 9.70312V41.7572L16.8822 45.808V72.7366L38.6758 36.7265L25.1197 33.7713L28.9975 15.6609Z" fill="#FCDA48"/>
        <path d="M16.8822 73.0001H16.8126C16.756 72.9851 16.7059 72.9522 16.6701 72.9066C16.6343 72.8609 16.6148 72.8049 16.6144 72.7472V46.0136L0.203593 42.0154C0.145543 42.0003 0.0941511 41.9669 0.0573619 41.9202C0.0205727 41.8736 0.000435654 41.8163 6.41959e-05 41.7573V9.70321C-0.00272805 9.66095 0.00509414 9.61867 0.022841 9.58008C0.0405879 9.5415 0.0677144 9.5078 0.101829 9.48197C0.131723 9.45696 0.167149 9.43917 0.205277 9.43003C0.243406 9.42088 0.28318 9.42063 0.321425 9.42929L29.0511 15.4029C29.0859 15.4091 29.1191 15.4222 29.1486 15.4414C29.1781 15.4606 29.2034 15.4854 29.2229 15.5145C29.2424 15.5435 29.2558 15.5761 29.2621 15.6103C29.2685 15.6446 29.2678 15.6797 29.26 15.7137L25.4358 33.5712L38.7348 36.4685C38.7762 36.4772 38.815 36.4956 38.8476 36.5222C38.8803 36.5488 38.9059 36.5828 38.9222 36.6212C38.9407 36.6581 38.9503 36.6987 38.9503 36.7398C38.9503 36.7809 38.9407 36.8214 38.9222 36.8583L17.1393 72.8737C17.1131 72.9166 17.075 72.9513 17.0295 72.9737C16.9839 72.9961 16.9329 73.0052 16.8822 73.0001ZM0.535666 41.5518L16.9465 45.5553C17.0069 45.5702 17.0598 45.6062 17.0953 45.6566C17.1307 45.707 17.1464 45.7683 17.1393 45.8292V71.799L38.2581 36.9004L25.0609 34.0243C24.9922 34.0091 24.9324 33.968 24.8944 33.9099C24.8563 33.8517 24.843 33.7812 24.8573 33.7135L28.6815 15.8612L0.535666 10.0087V41.5518Z" fill="black"/>
        </g>
        <defs>
        <clipPath id="clip0_11534_282712">
        <rect width="51" height="73" fill="white"/>
        </clipPath>
        </defs>
      </svg>

      <br/><br/>

      <h2>{{ mode === 'upgrade' ? 'Agency Upgrade Available' : 'Manage Subscription' }}</h2>
    
      <br/>

      <div *ngIf="mode === 'upgrade'">
        
        This upgrade button will take you to an upgrade page specific to your current account and automatically apply all applicable discounts for you.<br/><br/>
        If you have any questions email support: <ion-text color="dark">support@growthworks.io</ion-text>
    
      </div>

      <div *ngIf="mode === 'subscription'">
        
        If you are on a free trial you can use the button below to cancel or upgrade your subscription at any time.<br/><br/>
        If you would like to upgrade or change your plan or have any other questions please contact <ion-text color="dark">support@growthworks.io</ion-text>
        
      </div>
    
    </div>
  
  </ion-content>
  
  <ion-footer>
  
    <ion-button size="large" color="primary" [href]="link ? link : 'https://secure.growthworks.io/updateinfo/?lang=en'" target="_blank">
      <!-- Rocket Icon -->
      <ion-icon slot="end" name="rocket"></ion-icon>
      {{ mode === 'upgrade' ? 'Upgrade Now' : 'Open Cutomer Hub' }}
    </ion-button>

  </ion-footer>
  