import { Injectable } from '@angular/core';
import { ApiServiceInterface } from '../interfaces/api-service-interface';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class BaseApiService implements ApiServiceInterface {

    public _requestBay: any = [];

    protected constructor(protected http: HttpClient) {}

    /**
     * Request options (default)
     */
    private defaultOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        }),
        withCredentials: false // true (CORS)
    };

    setHeader(header: string, value: string) {
        this.defaultOptions.headers = this.defaultOptions.headers.set(header, value);
    }

    unsetHeader(header: string) {
        this.defaultOptions.headers = this.defaultOptions.headers.delete(header);
    }

    get(url: string, queryParams?: object, options: object = {}): Observable<any> {
        const opts = {
            ...options,
            params: queryParams,
        };

        return this.request('GET', url, opts);
    }

    post(url: string, data?: object, options: object = {}): Observable<any> {
        const opts = {
            ...options,
            body: data,
        };

        return this.request('POST', url, opts);
    }

    put(url: string, data?: object, options: object = {}): Observable<any> {
        const opts = {
            ...options,
            body: data,
        };

        return this.request('PUT', url, opts);
    }

    delete(url: string, data?: object, options: object = {}): Observable<any> {
        const opts = {
            ...options,
            body: data,
        };

        return this.request('DELETE', url, opts);
    }

    patch(url: string, data?: object, options: object = {}): Observable<any> {
        const opts = {
            ...options,
            body: data,
        };

        return this.request('PATCH', url, opts);
    }

    request(method: string, url: string, options: object): Observable<any> {
        const subject: Subject<any> = new Subject();

        const opts = {
            ...this.defaultOptions,
            ...options
        };

        // console.log(opts);

        const request = this.http.request(method, url, opts).pipe(
            catchError(error => {
                this.onError(error);

                return throwError(error);
            })
        ).subscribe(subject);

        this._requestBay.push({method, url, opts, request});
        // Clear Bay.
        this._requestBay = this._requestBay.filter( item => item.request.closed === false );
        // request.unsubscribe();

        return subject;
    }

    onError(error: HttpErrorResponse): void {
    }
}
