
<!-- UPGRADE BANNER -->

<ng-container *ngIf="presentUpgradeBanner && (!formProcessor.isPlatformUniversal() && (this.auth.data.banner_data && this.auth.data.banner_data.center_title))">
 
      
  <div (click)="triggerBannerAction('banner', 'action')" id="upgradeBanner" class="banner account-upgrade">

        
    <div class="reminder" [innerHTML]="this.auth.data.banner_data.reminder">
    </div>

    <a  [attr.href]="triggerBannerAction('banner', 'link')" [attr.target]="getTriggerTarget(triggerBannerAction('banner', 'link'))">
      <ng-container>

      <span  [innerHTML]="this.auth.data.banner_data.center_title"></span>
      <small  [innerHTML]="this.auth.data.banner_data.center_subtitle"></small>
        
      </ng-container>
    
      <div class="action">
        <svg class="dark" xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40" fill="none">
      <path d="M16.0563 24.4196L24.8893 15.5867" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M17.832 15.5835L24.932 15.5688L24.9172 22.6687" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    
        <svg class="light" xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40" fill="none">
      <path d="M16.0563 24.4196L24.8893 15.5867" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M17.832 15.5835L24.932 15.5688L24.9172 22.6687" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </a>
    
    <div class="close action-temp" data-timeout="24h" (click)="this.dismissUpgradeBanner()">
      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
        <path d="M0.811136 0.809348L9.19165 9.18986" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.19081 0.809309L0.810303 9.18982" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>

  </div>
    
</ng-container>

<!-- UPGRADE BANNER -->



<div class="active-bar">
  
    <div class="project-select">
      <div class="projects-box" [ngClass]="{ 'universal-platform' : formProcessor.isPlatformUniversal() }" (click)="presentSitesPopover($event)">
        <div class="logo"></div>
        <div class="project-name"><ion-img [src]="formProcessor.getPlatformLogotype()" [alt]="formProcessor.getPlatformName()" [title]="formProcessor.getPlatformName()"></ion-img></div>
        <div class="project-select-arrow" *ngIf="!formProcessor.isPlatformUniversal()"></div>
      </div>
    </div>

    <div class="tab-shortcuts">
      <div class="menu-items">
        <ng-container *ngFor="let item of menuItems; let i = index;">
          <div class="menu-item tab-shortcut {{ mathesPathInclusions(item) ? 'active path-inclusion': 'no-inclusion'}}" [ngClass]="{'tab-coming': item.badge === 'coming' && !this.auth.data.is_staff}" *ngIf="item.expression()" [routerLink]="[item.url]" [routerLinkActive]="mathesPathInclusions(item) ? 'angular-router-active' : 'active'" [routerLinkActiveOptions]="{exact: false}">
            
            <ion-icon name="{{item.icon}}" class="menu-item-icon"></ion-icon>
            <div class="menu-item-title">{{item.title}}</div>

            <ion-badge class="new" *ngIf="item.badge === 'new'">NEW</ion-badge>
            <ion-badge class="soon" *ngIf="item.badge === 'coming'">COMING SOON</ion-badge>

          </div>
        </ng-container>
      </div>
    </div>

    <div class="profile-box"  *ngIf="this.auth.data">

      <div class="actions">


        <!-- UPGRADE BUTTON -->

        <ng-container *ngIf="!formProcessor.isPlatformUniversal() && (this.auth.data.banner_data && this.auth.data.banner_data.button_text)">
          <span (click)="triggerBannerAction('button', 'action')">
            <a [attr.href]="triggerBannerAction('button', 'link')" [attr.target]="getTriggerTarget(triggerBannerAction('button', 'link'))" class="{{ this.auth.data.banner_data.button_style ? this.auth.data.banner_data.button_style : 'upgrade-and-save-button'}}">
              <span [innerHTML]="this.auth.data.banner_data.button_text"></span>
            </a>
          </span>
        </ng-container>

        <!-- UPGRADE BUTTON -->



        <!--
        <ng-container *ngIf="true">
          <ion-button (click)="formProcessor.upgradeProfileModal('subscription')" size="small"><ion-icon slot="start" name="caret-up-circle-sharp"></ion-icon>Manage Subscription</ion-button>          
        </ng-container>
        -->

        <div class="divider"></div>

        <div class="action" (click)="presentNotifications($event)"><ion-icon name="notifications-outline" class="action-icon"></ion-icon></div>
        <div class="divider"></div>

        <ng-container *ngIf="!formProcessor.isPlatformUniversal()">
          <div class="action" (click)="formProcessor.callSupport()"><ion-icon name="help-buoy-outline" class="action-icon"></ion-icon></div>
          <div class="divider"></div>
        </ng-container>

      </div>
      <div class="profile-btn" (click)="this.presentProfilePopover($event)">
        <div class="profile-avatar">

          <ion-avatar>
            <img *ngIf="!this.auth.data.avatar" src="/assets/v2/avatar.png"/>
            <img *ngIf="this.auth.data.avatar" [src]="this.auth.data.avatar"/>
          </ion-avatar>

        </div>
        <div class="profile-name">
          {{ (this.auth.data.first_name || this.auth.data.last_name) ? this.auth.data.first_name + ' ' + this.auth.data.last_name : this.auth.data.email }}
        </div>
        <div class="profile-arrow">
          <ion-icon color="medium" name='chevron-down-outline'></ion-icon>
        </div>
      </div>

    </div>

</div>
