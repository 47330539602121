import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { FormsModule } from '@angular/forms';

// Services
import { AuthService } from '@services/auth.service';
import { FormProcessorService } from '@services/form-processor.service';
import { NabuApiService } from '@services/api/nabu-api.service';
import { DataSourceService } from '@services/data-source.service';
import { AiService } from '@services/ai.service';

// Modals
/*
import { AuthModalPageModule } from '@modals/auth-modal/auth-modal.module';
import { RegistrationModalPageModule } from '@modals/registration-modal/registration-modal.module';
import { FollowModalPageModule } from '@modals/follow-modal/follow-modal.module';
import { MobileFiltersModalPageModule } from '@modals/mobile-filters-modal/mobile-filters-modal.module';
*/

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        FormsModule
    ],
})

export class EngineModule {
    constructor(@Optional() @SkipSelf() parentModule: EngineModule) {
        if (parentModule) {
            throw new Error('EngineModule already loaded. Import it in the AppModule only');
        }
    }

    static forRoot(): ModuleWithProviders<EngineModule> {
        return {
            ngModule: EngineModule,
            providers: [
                AuthService,
                FormProcessorService,
                NabuApiService,
                DataSourceService,
                AiService
            ],
        };
    }
}
