<!-- <ion-card *ngIf="progress > 0">
  <ion-card-content class="full">
      <div>
          <div class="img">
            <ion-img src="/assets/v2/icons/document.png"></ion-img>
          </div>
          <div class="title">{{title}}</div>
          <div class="subtitle">{{subtitle}}</div>
          <div>
            <ion-progress-bar value="{{progress/100}}" color="success"></ion-progress-bar>
            <span class="percent">{{progress}}%</span>
          </div>
      </div>
  </ion-card-content>
</ion-card> -->

<ion-card class="activatable">
  <ion-card-content class="medium">
      <div class="box">
        <div class="box-title">
          <div class="title">{{title}}</div>
          <ion-button fill="outline" size="small" shape="round" color="light" class="ion-float-right step-btn non-interactive">{{stepbtn}}</ion-button>
        </div>
        <div class="img">
          <ion-img src="{{image}}"></ion-img>
        </div>
        <div class="subtitle">{{subtitle}}</div>
        <div class="progress-line">
          <ion-progress-bar value="{{progress/100}}" color="success"></ion-progress-bar>
          <span class="percent">{{progress.toFixed(0)}}%</span>
        </div>
      </div>
  </ion-card-content>
</ion-card>

<!-- <ion-card *ngIf="progress===0" class="fill">
  <ion-card-content class="medium">
      <div>
        <div>
          <span class="title">{{title}}</span>
          <ion-button (click)="fill()" fill="outline" class="ion-float-right fill-btn">Fill</ion-button>
        </div>
        <div class="img-fill"></div>
        <div class="subtitle">{{subtitle}}</div>
        <div>
          <ion-progress-bar value="{{progress/100}}" color="success"></ion-progress-bar>
          <span class="percent">{{progress}}%</span>
        </div>
      </div>
  </ion-card-content>
</ion-card> -->
