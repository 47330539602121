<ng-container *ngIf="!( data && data.aiGenerationStep )">

  <ion-header>
    <ion-toolbar class="no-border">
      <ion-title></ion-title>
      <ion-buttons slot="primary">
        <ion-button color="dark" (click)="cancelCreation()">
          <ion-icon slot="icon-only" name="close-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-content color="white" class="application-v2" style="--padding-top: 0px; --padding-bottom: 0px; --padding-start: 42px;">

    <div>

      <h2>Create Campaign</h2>

      <!--
      <p style="line-height: 28px;">Hey there, we noticed you have not published your product design asset yet.</p>
      -->

      <br/><br/>

      <b style="font-size: 14px; line-height: 24px; color: #000000;">Campaign Name</b>

      <ion-input style="margin-top: 8px;" class="form" [(ngModel)]="data.projectName" placeholder="My Awesome Campaign Name"></ion-input>

    </div>


  </ion-content>

  <ion-footer>
    <ion-button [disabled]="!data.projectName" size="large" color="primary" (click)="updateName()">
      Create
      <ion-icon slot="end" name="add-outline"></ion-icon>
    </ion-button>
    
    <ion-button size="large" color="dark" fill="outline" (click)="cancelCreation()">
      Cancel
    </ion-button>
  </ion-footer>

</ng-container>

<!-- GENERATION STEP -->

<ng-container *ngIf="( data && data.aiGenerationStep )">

  <ion-header>
    <ion-toolbar class="no-border">
      <ion-title></ion-title>
      <ion-buttons slot="primary">
        <ion-button color="dark" (click)="skipModal()">
          <ion-icon slot="icon-only" name="close-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-content color="white" class="application-v2" style="--padding-top: 0px; --padding-bottom: 0px; --padding-start: 42px;">

    <gw-ai-blob style="height: 72px; display: flex; margin-top: 0px;" quality="lq"></gw-ai-blob>

    <div style="margin-top: 14px;">

      <h2>Generate Campaign Planner & Product Roadmap with AI</h2>

      <br/>

      <!--
      <span style="line-height: 28px;">Hey there, we noticed you have not published your product design asset yet.</span>
      -->

    </div>


  </ion-content>

  <ion-footer>
    <ion-button size="large" color="primary" (click)="generateAI()">
      Generate with AI
      <ion-icon slot="end" name="add-outline"></ion-icon>
    </ion-button>
    
    <ion-button size="large" color="dark" fill="outline" (click)="skipModal()">
      Later
    </ion-button>
  </ion-footer>

</ng-container>