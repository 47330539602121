import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { DataSourceService } from '@services/data-source.service';

@Component({
  selector: 'app-video',
  templateUrl: './video.page.html',
  styleUrls: ['./video.page.scss'],
})
export class VideoPage implements OnInit {

  @Input() data;

  constructor(
    public modalCtrl: ModalController,
  ) { }

  ngOnInit() {
    console.log(this.data);
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      dismissed: true
    });
  }

}
