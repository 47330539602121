import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { ModalController } from '@ionic/angular';

@Component({
  selector: 'wizard-wrapper',
  templateUrl: './wizard-wrapper-component.component.html',
  styleUrls: ['./wizard-wrapper-component.component.scss'],
})
export class WizardWrapperComponentComponent implements OnInit {

  @Input() modalRef: any;

  constructor(
    public modalController: ModalController,
    public router: Router,
  ) { }

  ngOnInit() {
    console.log('Wizard Wrapper Initiated', this.modalRef);
  }

  dismiss() {
    if ( this.modalRef.loading ) {
      this.modalRef.endLoadingAnimation();
    } else {
      this.modalController.dismiss();
    }
  }


}
