import { Component, OnInit, Input } from '@angular/core';
import { AiService } from '@app/-core/services/ai.service';
import { ModalController, AlertController } from '@ionic/angular';
// import { FormProcessorService } from '@app/-core/services/form-processor.service';

@Component({
  selector: 'app-ai-loading-screen',
  templateUrl: './ai-loading-screen.component.html',
  styleUrls: ['./ai-loading-screen.component.scss'],
})
export class AiLoadingScreenComponent implements OnInit {

  message = '';
  index = 0;

  @Input() public subscription: any;

  messages = [
    "This is going to take me a minute",
    "Work of 100 people. Tea, anyone?",
    "Watched screens don't load faster. Relax!",
    "Faster than viral cat videos. Sit tight.",
    "Burning the midnight oil so you don't have to... You're welcome.",
    "Faster than your WiFi... Well, almost there.",
    "Doing the hard yards. Now's your chance to slack off.",
    "Working harder than your coffee machine... Hang tight.",
    "Doing in minutes what would take you weeks. Who's the hero now?",
    "While we're crunching data, feel free to judge our progress bar.",
    "We're the ones doing the heavy lifting. You just sit pretty.",
    "We're on it! What's your excuse for doing nothing?",
    "Data crunching: because somebody's got to do it, and it's not you.",
    "Calm down, we're probably faster than your last date.",
    "We're busting our bytes so you don't have to.",
    "We're like a good cup of tea, worth the wait.",
    "If only your breakfast was ready this fast, right?",
    "Loading... Feel free to pretend you're busy.",
    "Might be faster to learn quantum physics... just saying.",
    "Shh... The data elves are crunching your numbers.",
    "Weeks of work, compressed. Your procrastination thanks us.",
    "We'd make a snail jealous. Well, maybe not, but close.",
    "Working our circuits off. No need for you to lift a finger.",
    "Breaking the laws of time... You can thank us later."
  ]

  constructor(
    // public aiService: AiService
    public modalController: ModalController,
    public alertController: AlertController,
  ) { }

  ngOnInit() {
    this.index = 0;
    this.message = this.messages[this.index];
    this.index++;
    setInterval( () => {
      // Cucle throught the messages and start over if array is over
      const message = this.messages[this.index];
      if ( message ) {
        this.message = message;
      } else {
        this.index = 0;
        this.message = this.messages[this.index];
      }
      this.index++;
    }, 5000);
  }

  async dissmis() {
    // this.aiService.dismissAiLoadingScreen();
    if ( this.subscription ) {
      const options: any = {
        message: 'Are you sure you want to leave generation process?',
        buttons: [
          {
            text: 'Leave',
            cssClass: 'danger',
            handler: () => {

              // this.subscription.unsubscribe();

              this.modalController.dismiss({
                dismissed: true,
                unsubscribed: true
              });
            },
          },
          {
            text: 'Cancel',
            role: 'cancel',
          }
        ]
      };

      const alert = await this.alertController.create({
        ...options,
        cssClass: 'application-v2 alert-v2 ' + (options.cssClass ? options.cssClass : ''),
      });
      return await alert.present();

    } else {

      this.modalController.dismiss({
        dismissed: true
      });

    }

  }

}
