import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { DataSourceService } from '@services/data-source.service';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-color-picker-popover',
  templateUrl: './color-picker.popover.html',
  styleUrls: ['./color-picker.component.scss'],
})
export class ColorPickerPopoverComponent implements OnInit {

  @Input() value;
  @Input() self;
  @Input() opacity = false;
  stateValue: any;
  brandData: any = {};
  recentData: any = {};

  constructor(
    public popoverController: PopoverController,
    public dataSource: DataSourceService,
    private storage: Storage
  ) {

    if ( !this.value ) {
      this.value = null;
    }
    this.setColor(this.value);

    // Set default colors and override em by backend (if present)
    this.brandData.colors = [
      { color_hex: '#111111', },
      { color_hex: '#333333', },
      { color_hex: '#4F4F4F', },
      { color_hex: '#828282', },
      { color_hex: '#BDBDBD', },
      { color_hex: '#E0E0E0', },
      { color_hex: '#F2F2F2', },
      { color_hex: '#FFFFFF', },

      { color_hex: '#EB5757', },
      { color_hex: '#F2994A', },
      { color_hex: '#F2C94C', },
      { color_hex: '#219653', },
      // { color_hex: '#27AE60', },
      { color_hex: '#6FCF97', },
      { color_hex: '#2F80ED', },
      { color_hex: '#2D9CDB', },
      { color_hex: '#56CCF2', },

    ];

    /*
    this.dataSource.getBrandData().subscribe( (res) => {
      console.log('Brand Data: ', res);
      // this.brandData = res;

      // Reset defaults
      this.brandData.colors.forEach( (localColor, index) => {
        const find = res.color.find( x => x.link_type === localColor.link_type );
        if ( find ) {
          // console.log('found', find, localColor, index);
          localColor = find;
          this.brandData.colors[index] = find;
        }
      });

      // Push missings (type 5)
      const others = res.color.filter( x => x.link_type === 5 );
      others.forEach( (remoteOtherColor) => {
        this.brandData.colors.push(remoteOtherColor);
      });
      */

  }

  async ngOnInit() {

    console.log(this.value);

    /*
    let colors = await this.storage.get('recentColors');
    {
      console.log('colors', colors);
      // let colors;
      try{
        colors = JSON.parse(colors);
      } catch (err) {
        colors = [];
      }
      this.recentData.colors = colors;
    }
    */

    this.recentData.colors = [];
    for( let i = 0; i < 16; i++ ) {
      this.recentData.colors.push('#fefefe');
    }

    this.dataSource.getRecentColors().subscribe( (res) => {
      if ( res ) {
        console.log( res );
        if ( res.length ) {
          for( let i = 0; i < res.length; i++ ) {
            this.recentData.colors[i] = res[i];
          }
          // this.recentData.colors = (res);
        }

      }
    });
  }

  public changeComplete(ev) {
    console.log(ev);
    this.setColor(ev.color.hex.toUpperCase());
  }

  public change(ev) {
    console.log('change', ev);
    this.stateValue = ev.color.hex.toUpperCase();
    // this.setColor(ev.color.hex.toUpperCase());
  }

  public changeCompleteAlpha(ev) {
    console.log('alpha ch', ev);
    let opacity = ev.color.rgb.a;
    let _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    let hexOpacity = _opacity.toString(16).toUpperCase();
    this.setColor(ev.color.hex.toUpperCase() + hexOpacity);
  }

  public changeAlpha(ev) {
    console.log('alpha on', ev);
    // this.stateValue = ev.color.hex.toUpperCase();
    // this.setColor(ev.color.hex.toUpperCase());
  }

  public validateInput(ev, pickerState) {
    console.log(ev, pickerState);

    this.setColor(pickerState.hex.toUpperCase());
    // alert('validate');
  }

  public onValidInput(ev, pickerState) {
    console.log('onValidInput', ev, pickerState);
    const reg=/^[\#]{0,1}([0-9a-f]{6}){1}$/i;
    if (reg.test(this.value) ) {
      console.log('valid');
      setTimeout( () => {
        this.setColor(pickerState.hex.toUpperCase());
      }, 100);
    }
    // this.setColor(pickerState.hex.toUpperCase());
    // alert('validate');
  }

  async setColor(color) {
    this.value = color;

    const top = await (this.popoverController.getTop() as any);
    console.log('popoverController', top);

    top.value = this.value;
    top.tinyColorPickerValue = this.value;

    (this.self as any).value = this.value;
    (this.self as any).tinyColorPickerValue = this.value;

    // console.log((this.self as any).value);
    // self.value = this.value;
  }

  async submit(ev) {
    await this.popoverController.dismiss({
      value: this.value
    });
  }

  removeColor() {
    this.setColor(null);
  }

}

@Component({
  selector: 'input-color',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
})
export class ColorPickerComponent implements OnInit, OnDestroy {

  @Input() value = null;
  oldValue;
  clock;
  @Input() opacity = false;
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  
  @HostListener('click', ['$event']) public click(ev) {
    console.log(ev);
    if ( ev ) {
      ev.preventDefault();
      this.initiate(ev);
    }
  }

  constructor(
    public popoverController: PopoverController,
    private storage: Storage,
    public dataSource: DataSourceService,
  ) { }

  ngOnInit() {
    console.log(this.value);
    this.oldValue = this.value;
    this.clock = setInterval( () => {
      // console.log('c t');
      if ( this.oldValue !== this.value ) {
        console.log( 'live change', this.value );
        if ( this.value ) {
          this.change.emit({ returnValue: true, srcElement: { value: this.value } });
        } else {
          this.change.emit({ returnValue: true, srcElement: { value: '' } });
        }

        this.oldValue = this.value;
      }
    }, 500);
  }

  ngOnDestroy() {
    try {
      clearInterval(this.clock);
    } catch (err) {
      // -
    }
  }

  public async initiate(ev) {
    console.log('click', ev);

    const originalColor = this.value;

    const popover = await this.popoverController.create({
      component: ColorPickerPopoverComponent,
      cssClass: 'application-v2 popover-v2 color-picker-popover',
      event: ev,
      translucent: true,
      backdropDismiss: true,
      showBackdrop: false,
      componentProps: {
        value: this.value,
        self: this,
        opacity: this.opacity
      }
    });
    await popover.present();

    // return;
    const dismiss = await popover.onWillDismiss();
    console.log('onDidDismiss resolved with role', dismiss, {popover},  );

    const top = await ( this.popoverController.getTop() );
    console.log('top', ( top as any ).value);

    if ( dismiss && ( top as any ).value ) {
      this.value = ( top as any ).value;

      let colors = await this.storage.get('recentColors');
      {
        // let colors;
        try{
          colors = JSON.parse(colors);
          if (!colors) {
            colors = [];
          }
        } catch (err) {
          colors = [];
        }
        console.log('colors', colors, this.value, originalColor);
        if ( this.value ) {
          /*
          if ( colors.length ) {
            colors.unshift(this.value);
          } else {
            colors.push(this.value);
          }
          */
          if ( originalColor != this.value ) {
            this.dataSource.getRecentColors().subscribe( (sres) => {
              console.log('sres', sres);
              if ( sres.findIndex(x => x === this.value) === -1 ) {
                this.dataSource.putRecentColors(this.value).subscribe( (res) => {});
              }
            });
          }

          // colors.splice(16, 999);
          // console.log('colors unshift', colors);
          // this.storage.set('recentColors', JSON.stringify(colors));
        }
      }

      this.change.emit({ returnValue: true, srcElement: { value: this.value } });
    } else {
      this.change.emit({ returnValue: true, srcElement: { value: '' } });
    }

  }

}
