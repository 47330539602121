<div class="main-frame" *ngIf="!dataSource.isCoaching">

<ion-grid>
  <ion-row>
    <ion-col size="3">

      <div *ngIf="!logo"
      [routerLink]="['/app/dashboard']"
      routerDirection="root"
      [replaceUrl]="true"
      class="active-item">
        <ion-img class="logo" [src]="formProcessor.getPlatformLogotype()" [alt]="formProcessor.getPlatformName()" [title]="formProcessor.getPlatformName()"><!-- Universal Platform Logo --></ion-img>
      </div>

      <div *ngIf="logo==='enterprise'"
      [routerLink]="['/app/dashboard']"
      routerDirection="root"
      [replaceUrl]="true"
      class="active-item">
        <ion-img class="logo" src="assets/GW_Enterprise.png"></ion-img>
      </div>


    </ion-col>
    <ion-col class="ion-align-self-center ion-text-right">


      <div class="avatar">
        <div *ngIf="auth.data.avatar" class="sub-avatar" [ngStyle]="{ 'background-image': 'url(' + env.apiUrl + auth.data.avatar + ')'}"></div>
      </div>

      <div (click)="presentPopover($event)" class="active-item exc">{{ auth.data.first_name ? auth.data.first_name : auth.data.email }} <b>▾</b></div>


    </ion-col>
  </ion-row>
</ion-grid>

</div>
