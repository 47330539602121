import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'gw-button-actions',
  templateUrl: './button-actions.component.html',
  styleUrls: ['./button-actions.component.scss'],
})
export class ButtonActionsComponent implements OnInit {

  @Output() action = new EventEmitter();

  public emitAction(value) {
    console.log('emitAction', value);
    this.action.emit(value);
  }

  constructor() { }

  ngOnInit() {}

}
