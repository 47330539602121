import { Component, OnInit } from '@angular/core';

import { Platform, ModalController, AnimationController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Storage } from '@ionic/storage';

import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

import { AuthService } from '@services/auth.service';
import { DataSourceService } from '@services/data-source.service';
import { FormProcessorService } from '@services/form-processor.service';

import { ImageBrowserComponent } from '@components/image-browser/image-browser.component';
import { environment } from '../environments/environment';

declare const require: any;
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { SwUpdate } from '@angular/service-worker';
import { filter } from 'rxjs/operators';

import { Tracking } from '@tracking';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  env = environment;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public auth: AuthService,
    private storage: Storage,
    public dataSource: DataSourceService,
    public modalController: ModalController,
    protected animationCtrl: AnimationController,
    private http: HttpClient,
    private serviceWorkerUpdate: SwUpdate,
    public formProcessor: FormProcessorService,

    public tracking: Tracking,

    public titleService: Title,
  ) {
    this.initializeApp();
    this.subscribeToVersionCheck();

    this.router.events.subscribe((url: any) => {

      if (url instanceof NavigationEnd) {

        console.log('Nav', url);
        if ( url && url.urlAfterRedirects ) {
          if ( url.urlAfterRedirects === '/auth/logout' ) {
            return;
          }

          if ( // Landing
            url.urlAfterRedirects === '/'
          ) {
            this.storage.get('authToken').then( (res) => {
              console.log('Auth Token', res);
              if ( !res ) {
                this.router.navigate(['/auth']);
              } else {
                this.router.navigate(['/app']);
              }
            });
          }

          if ( // Auth
            url.urlAfterRedirects === '/auth'
          ) {
            this.storage.get('authToken').then( (res) => {
              console.log('Auth Token', res);
              if ( !res ) {
                this.router.navigate(['/auth/login']);
              } else {
                this.router.navigate(['/app']);
              }
            });
          }

          if ( url.urlAfterRedirects === '/app' ) {
            this.router.navigate(['/app/dashboard']);
          }

          // Intercom
          this.hashToIntercom();

        }
      }
    });

    /*
    this.route.queryParams.subscribe((queryParams: any) => {
    });
    */
  }

  hashToIntercom() {
    // Detect (/#support) hash in url to invoke intercom
    // dynamicly subscribe to hash changes
    // window.location.hash = 'support';
    console.log('hashToIntercom', (window.location));
    try{
      if ( window.location.hash === '#support' ) {
        console.log( (window as any).Intercom('show') );
        // consume hash
        // window.location.hash = '';
      }
    } catch (err) {
      console.log('hashToIntercom Error', err);
    }

  }
  
  //environment.appVersion - 'Cache-Control': 'no-store, no-cache'
  public subscribeToVersionCheck() {
    if (this.serviceWorkerUpdate.isEnabled) {
      this.serviceWorkerUpdate.available.subscribe(ev => {
        console.log('this.serviceWorkerUpdate.versionUpdates', ev);
        if ( ev.type === 'UPDATE_AVAILABLE' ) {
          this.formProcessor.promptAppUpdate();
        }
      });

      this.platform.ready().then(() => {
        console.log('this.serviceWorkerUpdate.checkForUpdate() init', this.serviceWorkerUpdate);
        this.serviceWorkerUpdate.checkForUpdate();
      });
      setInterval( () => {
        console.log('this.serviceWorkerUpdate.checkForUpdate()', this.serviceWorkerUpdate);
        this.serviceWorkerUpdate.checkForUpdate();
      }, this.env.serviceWorkerUpdateTimeout * 60 * 1000);
    }
  }

  public recoverMainToken() {
    this.auth.storage.get('recoverAuthToken').then( (recoverToken) => {
      this.auth.storage.set('authToken', recoverToken);
      this.auth.storage.remove('recoverAuthToken');
      setTimeout( ( ) => (window as any).location.replace('/app/agency/contacts'), 500 );
    });
  }

  public recoverCoaching() {
    setTimeout( ( ) => (window as any).location.replace('/app/agency/contacts'), 500 );
  }

  initializeApp() {
    this.platform.ready().then(() => {
      // Debug Popups/Modals for development
      // this.browseImage();
      // this.formProcessor.presentAccountUpgradeModal('premium-template');
      

      // Title Hack for
      this.titleService.setTitle( ( this.formProcessor.getPlatformName() ) || 'GrowthWorks' );

      this.statusBar.styleDefault();
      this.splashScreen.hide();
      console.log('Platform is Ready !');
      console.log('Platform Info: ', this.platform.platforms() );

      console.log('Branding Info: ', this.formProcessor.getPlatformName(), this.formProcessor.getPlatformLogo() );
    });
  }

  async browseImage() {
    const modal = await this.modalController.create({
      component: ImageBrowserComponent,
      cssClass: 'application-v2 modal-v2 solid-backdrop image-browser',
      showBackdrop: true,
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    console.log('ImageBrowserComponent', data);
  }

  ngOnInit() {

  }

}
