import { Component, OnInit, OnDestroy, Input } from '@angular/core';

@Component({
  selector: 'app-loading-box',
  templateUrl: './loading-box.component.html',
  styleUrls: ['./loading-box.component.scss'],
})
export class LoadingBoxComponent implements OnInit {

  public interval;
  @Input() aiAnimation = false;

  constructor() { }

  ngOnInit() {
    console.log('LoadingBoxComponent');
    let i = 0;
    if( !this.aiAnimation ) {

      this.interval = setInterval( () => {
        try{
          i++;
          console.log('LoadingBoxComponent setInterval');
          (window as any).document.querySelector('.box-loadingbox').scrollBy({
            top: 50 + ( i % 2 ),
            left: 0,
            behavior: 'smooth'
          });
          const current = (window as any).document.querySelector('.active-loadingbox');
          if ( current.nextElementSibling ) {
            current.classList.remove("active-loadingbox");
            current.nextElementSibling.classList.add("active-loadingbox");
            console.log('current', {current});
          } else {
            clearInterval(this.interval);
          }
        } catch(e) {
          clearInterval(this.interval);
        }
      }, 1500);

    }
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

}
