<!-- <ion-card *ngIf="progress===0" class="fill">
  <ion-card-content class="medium">
      <div>
        <div>
          <ion-img></ion-img>
          <span class="title">{{title}}</span>
          <ion-button (click)="fill()" fill="outline" class="ion-float-right fill-btn">Fill</ion-button>
        </div>
        <div class="img-fill"></div>
        <div class="subtitle">{{subtitle}}</div>
        <div>
          <ion-progress-bar value="{{progress/100}}" color="success"></ion-progress-bar>
          <span class="percent">{{progress}}%</span>
        </div>
      </div>
  </ion-card-content>
</ion-card> -->



<ion-card class="activatable">
  <ion-card-content class="medium">
    <div>

      <div class="container">

        <div class="box">
          <div class="img" *ngIf="item.icon">
            <ion-img src="/assets/v2/blocks/asset-cards/icons/{{item.icon}}.png"></ion-img>
          </div>

          <div class="text" (click)="openAsset()">
            <span class="title">{{item.title}}</span>
            <div class="subtitle">{{ item.tags.join(' • ') }}</div>
          </div>

          <div class="dots" (click)="presentPopover($event)">
            <ion-icon name="ellipsis-vertical"></ion-icon>
          </div>
        </div>

      </div>

      <div class="progress-line">
        <ion-progress-bar value="{{item.progress/100}}" color="success"></ion-progress-bar>
        <span class="percent">{{item.progress.toFixed(0)}}%</span>
      </div>

    </div>
  </ion-card-content>
</ion-card>
