<ion-content [ngClass]="{ 'mode-radio' : !dropdownOptions.multiple }">
  <ion-searchbar *ngIf="dropdownOptions.search" [(ngModel)]="dropdownOptions.searchQuery" (ionChange)="searchForItems($event)" mode="ios" [placeholder]="dropdownOptions.searchPlaceholder || 'Quick Search...'"></ion-searchbar>

  <!-- if filteredResults false it means search debound is in progress -->
  <div class="loading" *ngIf="dropdownOptions && dropdownOptions.searchLoading">
    <ion-spinner name="dots"></ion-spinner>
  </div>

  <div class="no-results" *ngIf="!(dropdownOptions && dropdownOptions.searchLoading) && dropdownOptions.searchQuery && dropdownOptions.searchQuery.length > 0 && filteredResults()[0].group.options.length === 0">No `{{ this.dropdownOptions.searchQuery }}` query found in none of a searched records</div>

  <div class="option-list" *ngIf="(dropdownOptions.options && dropdownOptions.options.length) && filteredResults().length">

    <ion-item-group *ngFor="let item of filteredResults()">

      <ion-item-divider *ngIf="item.group && item.group.label" (click)="item.group.fold = !item.group.fold">
        <ion-label>
          {{ item.group.fold ? '+' : '' }}  
          {{ item.group.label }}</ion-label>
      </ion-item-divider>
    
      <div *ngIf="!item.group || !item.group.fold">
        <div class="interactive-item" (click)="selectItem($event, option)" *ngFor="let option of (item.group) ? item.group.options : [item]">
        
          <ion-item [ngClass]="{ 'item-checkbox-checked': option.checked, 'item-disabled': option.disabled }">
            <ion-checkbox class="non-interactive" *ngIf="this.dropdownOptions.multiple" slot="start" [checked]="option.checked"></ion-checkbox>

            <ion-label>
              <ng-container *ngIf="option.icon || (item.group && item.group.icon)">
                <ion-icon name="{{ (option.icon) ? option.icon : item.group.icon }}"></ion-icon>
              </ng-container>
              {{ option.label }}
            </ion-label>

            <ion-badge class="tag" *ngIf="option.badge" slot="end" color="medium">{{ option.badge }}</ion-badge>

            <ion-icon class="select-icon" *ngIf="option.checked && !this.dropdownOptions.multiple" slot="end" color="dark" name="checkmark-circle"></ion-icon>
            
          </ion-item>
        </div>
      </div>

    </ion-item-group>

  </div>

</ion-content>
