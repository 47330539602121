<ion-header>
  <ion-toolbar>
    <ion-title>Customize Background</ion-title>
    <ion-buttons slot="secondary">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content #scrollArea [scrollEvents]="true" *ngIf="sharedData" class="ion-no-padding">
  <!--
  <pre>
  {{ sharedData.elements[index] | json }}
  </pre>
  -->
  <!--
  <pre>
  {{ originalValues | json }}
  </pre>
  <pre>
  {{ settingsValues | json }}
  </pre>
  -->

  <!-- <div class="tool-bar" *ngIf="mode !== 'picker'">
    <ion-button *ngIf="mode === 'layer'" mode="ios" size="small" fill="clear" color="dark" (click)="activeTab = 0;" [ngClass]="{ 'active-menu' : activeTab === 0 }">Presets</ion-button>
    <ion-button mode="ios" size="small" fill="clear" color="dark" (click)="activeTab = 4;" [ngClass]="{ 'active-menu' : activeTab === 4 }">Unsplash</ion-button>
    <ion-button mode="ios" size="small" fill="clear" color="dark" (click)="activeTab = 1;" [ngClass]="{ 'active-menu' : activeTab === 1 }">Assets</ion-button>
    <ion-button mode="ios" size="small" fill="clear" color="dark" (click)="activeTab = 2;" [ngClass]="{ 'active-menu' : activeTab === 2 }">Upload</ion-button>
    <ion-button mode="ios" size="small" fill="clear" color="dark" (click)="activeTab = 3;" [ngClass]="{ 'active-menu' : activeTab === 3 }">Advanced</ion-button>
  </div> -->

  <div class="highlight-toolbar" *ngIf="mode !== 'picker'">
    <div class="highlight" *ngIf="mode === 'layer'" (click)="activeTab = 0;" [ngClass]="{ 'active' : activeTab === 0 }">Presets</div>

    <!--
    <div class="highlight" (click)="activeTab = 4;" [ngClass]="{ 'active' : activeTab === 4 }">Unsplash</div>
    <div class="highlight" (click)="activeTab = 1;" [ngClass]="{ 'active' : activeTab === 1 }">Assets</div>
    -->

    <div class="highlight" (click)="activeTab = 2;" [ngClass]="{ 'active' : activeTab === 2 }">Settings</div>
    <div class="highlight" (click)="activeTab = 3;" [ngClass]="{ 'active' : activeTab === 3 }">Advanced</div>
  </div>

  <div class="highlight-toolbar" *ngIf="mode === 'picker'">

    <!--
    <div class="highlight" (click)="activeTab = 4;" [ngClass]="{ 'active' : activeTab === 4 }">Unsplash</div>
    <div class="highlight" (click)="activeTab = 1;" [ngClass]="{ 'active' : activeTab === 1 }">Assets</div>
    -->

    <div class="highlight" (click)="activeTab = 2;" [ngClass]="{ 'active' : activeTab === 2 }">Settings</div>
  </div>

  <div>
    <div class="ion-padding-md">
      <ng-container *ngIf="activeTab === 4">
        <!--{{ getLayerIndexesArray() | json }}-->

        <ion-grid>
          <ion-row>
            <ion-col class="ion-padding-bottom search-toolbar">

              <ion-searchbar placeholder="Search" [(ngModel)]="unsplashSearch"></ion-searchbar>

              <ion-button color="primary" [disabled]="unsplashSearch.length === 0" (click)="nameSeacrh($event)" size="large">Search</ion-button>

            </ion-col>
            <!-- <ion-col size="3">
              <ion-button (click)="nameSeacrh($event)" size="large">Search</ion-button>
            </ion-col> -->
          </ion-row>
          <ion-row>
            <ion-col class="no-padding-top">
              <ion-button class="unsplash-btn" size="small" fill="outline" color="light" *ngFor="let tag of unsplashSearchTags" (click)="unsplashSearch = unsplashSearch+ ' ' +tag">{{tag}}</ion-button>
            </ion-col>
          </ion-row>
          <ion-row>
            <!--
            <ion-col class="ion-align-self-center" size="3" *ngFor="let item of searchResults">
              <img (click)="this.sharedData.elements[this.index].data.value=item.full" [src]="item.thumbnail"/><br/>
              <div class="ion-text-center">
              <a target="_blank" [href]="item.author_link">{{ item.author }}</a>
              </div>
            </ion-col>
            -->
            <ion-col>
              <ngx-masonry>
                <div ngxMasonryItem class="masonry-item" *ngFor="let item of searchResults">
                 <img (click)="setActiveUnsplash(item)" [src]="item.thumbnail"/>
                 <div class="author">
                   <a target="_blank" [href]="item.author_link">{{ item.author }}</a>
                 </div>
               </div>
              </ngx-masonry>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="page!==0">
            <ion-col><ion-button (click)="loadMoreUnsplash()" expand="full" fill="clear">Load More</ion-button></ion-col>
          </ion-row>
        </ion-grid>

      </ng-container>
      <ng-container *ngIf="activeTab === 0">
        <!--{{ getLayerIndexesArray() | json }}-->

        <ion-grid>

          <ion-row>

            <ng-container *ngFor="let item of getLayerIndexesArray()">
              <ion-col size="6">
                <div class="asset-card" (click)="setPresetIndex(item)" [ngStyle]="{'background-image': 'url(' + item.src + ')'}">
                  <!--{{ item | json }}-->
                  <!--<img [src]="item.src"/>-->
                </div>
              </ion-col>
            </ng-container>

          </ion-row>
        </ion-grid>

      </ng-container>

      <ng-container *ngIf="activeTab === 1">
        <!--{{ getLayerIndexesArray() | json }}-->

        <!--
        <ng-container *ngFor="let item of getAssetsIndexesArray()">
          <div (click)="setAssetIndex(item)">
            {{ item | json }}
            <img [src]="item.src"/>
          </div>
        </ng-container>
        -->


        <ion-grid>

          <ion-row>
            <ion-col class="ion-padding-bottom">

              <ion-select class="form form-select" placeholder="- Choose asset -" interface="alert" (ionChange)="setActiveAssets($event)">
                <ion-select-option [value]="i" *ngFor="let item of projectAssetsList; let i = index;">{{ item.title }}</ion-select-option>
              </ion-select>

            </ion-col>
          </ion-row>

          <ion-row *ngIf="projectAssetsList && projectAssetsList.length > 0">

            <ng-container *ngFor="let item of projectAssetsList[projectAssetsListIndex].stages">
              <ion-col size="6">
                <div class="asset-card" (click)="setAssetIndex(item)" [ngStyle]="{'background-image': 'url(' + item.thumbnail + ')'}">
                  <!--{{ item | json }}-->
                  <!--<img [src]="item.src"/>-->
                </div>
              </ion-col>
            </ng-container>

          </ion-row>
        </ion-grid>

      </ng-container>

      <ng-container *ngIf="activeTab === 2">
        <!--{{ getLayerIndexesArray() | json }}-->



        <div class="ion-padding-sm">
          <ion-row>
            <ion-col>
              <b class="b-small">Preview</b>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col size="4">
              <div class="loader-tr">
                <div class="loader-thumb" [ngStyle]="{ 'background-image': 'url(' + sharedData.elements[index].data.value + ')' }">

                </div>
              </div>
            </ion-col>
            <ion-col size="6" class="ion-align-self-center">
              <ion-button color="primary" (click)="browseImage(index)">Change Image</ion-button>
              <!--
              <input style="display: none;" #bgUpload type="file" (change)="uploadImage($event, 3, 'media', 'background_image')" accept="image/*"/>
              -->
            </ion-col>
            <ion-col size="2" class="ion-align-self-center">

              <ion-button *ngIf="sharedData.elements[index] && sharedData.elements[index].data.value && sharedData.elements[index].data.value.length" size="small" color="dark" fill="clear" (click)="removeImage(index)">
                <ion-icon slot="icon-only" name="trash-outline"></ion-icon>
              </ion-button>

            </ion-col>
          </ion-row>
        </div>

      </ng-container>

      <ng-container *ngIf="activeTab === 3">
<!--
<pre>
  {{ this.sharedData.elements[index] | json }}
</pre>
-->
        <div class="ion-padding-sm">
          <div class="set-line ion-float-left"><b class="b-medium">Settings</b></div>
          <ion-button (click)="applyAllLayer(this.sharedData.elements[index], index)" class="ion-float-right apply-btn" fill="clear" color="primary" size="small">Apply to all</ion-button>
          <br style="clear: both"/>


          <ng-container *ngIf="this.mode==='logo' && ( this.sharedData.elements[index].data && this.sharedData.elements[index].data.key )">
            <div class="set-line ion-float-left"><b class="b-medium">Logo</b></div>

            <ion-button class="ion-float-right apply-btn" size="small" fill="clear" (click)="applyAll($event, index)">Apply to all</ion-button>

            <br/><br/><br/>
          </ng-container>

          <!-- <ion-row *ngIf="this.mode!='layer'" class="toggable-elm toggle-logo" (click)="toggleImageShow()">
            <ion-col size="2" class="ion-text-left ion-align-self-center">
              <ion-text color="medium">Hide</ion-text>
            </ion-col>
            <ion-col size="3">
              <div class="no-pointer"><ion-toggle color="primary" [checked]="this.sharedData.elements[index].data.show" fill="clear" size="small"></ion-toggle></div>
            </ion-col>
            <ion-col size="2" class="ion-align-self-center">
              Show
            </ion-col>
          </ion-row> -->

          <div class="show-hide-menu mini" *ngIf="this.mode!='layer'" (click)="toggleImageShow()">
            <div class="container">
              <span>Hide</span>
            </div>

            <div class="container">
              <ion-toggle class="ion-no-padding" [checked]="this.sharedData.elements[index].data.show" fill="clear" size="small"></ion-toggle>
            </div>

            <div class="container">
              <span>Show</span>
            </div>
          </div>


          <ion-row>

            <ion-col><!-- size="6" -->


              <div class="title-t3">Transperancy</div>
              <div class="box">
                <div class="boxy-t3 trans">

                  <span>0%</span>
                  <ion-range [ticks]="false" min="0.1" max="100" step="10" snaps="true" [value]="sharedData.elements[index].data.transparency || 100" (ionChange)="changeBackgroundTransparancy($event)">
                  </ion-range>
                  <span class="span-lg">100%</span>
                  <!-- <span>
                    {{ ( (sharedData.elements[index].data.transparency || 100) * 1) | number: '1.0-0' }} %
                  </span> -->

                </div>
                <div class="input-box">
                  {{ ( (sharedData.elements[index].data.transparency || 100) * 1) | number: '1.0-0' }}
                </div>
              </div>

            </ion-col>

          </ion-row>


          <ion-row>

            <ion-col class="addition-padding-top"><!-- size="6" -->

              <div class="title-t3" (click)="dropColor(index)">Background Color</div>

                <div class="box">
                  <div class="boxy-t3 trans-color">

                    <div (click)="picker.click($event)" class="preview" [ngStyle]="{'background': sharedData.elements[index].data.color}">
                      <!-- style="opacity: 0; width: 0px;" -->
                      <input-color
                      style="display: none;"
                      type="color"
                      [value]="sharedData.elements[index].data.color"
                      #picker
                      (change)="pickerChangeCustomColor(sharedData.elements[index].data.color, $event, index)">
                      </input-color>

                    </div>

                      <ion-input class="cpt-text color-text" (ionChange)="setColor(sharedData.elements[index].data.color, $event, index)" type="text" value="{{ sharedData.elements[index].data.color || 'Theme Color' }}"></ion-input>

                  </div>
                </div>

            </ion-col>

          </ion-row>


          <ion-row class="addition-margin-top-md toggable-elm box sub-line" (click)="toggleImageRepeat()">

            <ion-col size="6" class="ion-align-self-center no-padding-left">

              <div class="title-t3">Background Repeat</div>

            </ion-col>

            <ion-col size="6" class="ion-align-self-center no-padding-right repeat-toggle">

              <div class="no-pointer"><ion-toggle [checked]="this.sharedData.elements[index].data.repeat" class="ion-no-padding ion-float-right" fill="clear" size="small"></ion-toggle></div>

            </ion-col>
          </ion-row>



          <ion-row *ngIf="this.mode!='layerч'" class="addition-margin-top">

            <ion-col size="6" class="ion-align-self-center no-padding-bottom no-padding-top">

              <div class="title-t3">Background Size</div>

            </ion-col>

          </ion-row>

          <ion-row>

            <ion-col size="12" class="no-padding-bottom ion-align-self-center no-padding-top">

              <ion-list class="list-exp no-padding-bottom" lines="none">
                <ion-radio-group value="cover" [(ngModel)]="this.sharedData.elements[index].data.fill">

                  <ion-item>
                    <ion-label class="ion-no-margin">Cover (Resize the background image to cover the entire space)</ion-label>
                    <ion-radio slot="start" value="cover"></ion-radio>
                  </ion-item>

                  <ion-item>
                    <ion-label class="ion-no-margin">Contain (Resize image so that image is fully visible)</ion-label>
                    <ion-radio slot="start" value="contain"></ion-radio>
                  </ion-item>

                  <ion-item>
                    <ion-label class="ion-no-margin">Auto (Scales the background image in the corresponding direction)</ion-label>
                    <ion-radio slot="start" value="auto"></ion-radio>
                  </ion-item>


                </ion-radio-group>
              </ion-list>

              <!-- <div></div>
              <div></div> -->
              <div class="background-line"></div>

            </ion-col>

          </ion-row>

          <ion-row class="ion-margin-top">

            <ion-col>

              <div class="title-t3">Horizontal Position (x)</div>
              <div class="box">
                <div class="boxy-t3 trans">

                  <span>0%</span>
                  <ion-range min="0.1" max="100" step="10" snaps="true" [value]="sharedData.elements[index].data.posx || 100" (ionChange)="changeBackgroundPos($event, 'x')">
                  </ion-range>
                  <span class="span-lg">100%</span>

                </div>
                <div class="input-box">
                  {{ ( (sharedData.elements[index].data.posx || 100) * 1) | number: '1.0-0' }}
                </div>
              </div>

            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col class="addition-padding-top">

              <div class="title-t3">Vertical Position (y)</div>
              <div class="box">
                <div class="boxy-t3 trans">

                  <span>0%</span>
                  <ion-range min="0.1" max="100" step="10" snaps="true" [value]="sharedData.elements[index].data.posy || 100" (ionChange)="changeBackgroundPos($event, 'y')">
                  </ion-range>
                  <span class="span-lg">100%</span>

                </div>
                <div class="input-box">
                  {{ ( (sharedData.elements[index].data.posy || 100) * 1) | number: '1.0-0' }}
                </div>
              </div>

            </ion-col>
          </ion-row>


          <!-- :: BORDER - SETTINGS :: -->
          <ng-container>

            <ion-row *ngIf="this.mode!='layer'">
              <ion-col class="addition-padding-top">

                <div class="title-t3">Border Radius</div>
                <div class="box">
                  <div class="boxy-t3 trans">

                    <span>0%</span>
                    <ion-range [ticks]="false" min="0.0" max="0.5" step="0.01" snaps="true" [value]="this.sharedData.elements[index].data.round || 0" (ionChange)="changeBorderRadius($event)">
                    </ion-range>
                    <span class="span-lg">100%</span>

                  </div>
                  <div class="input-box">
                    {{ ( (this.sharedData.elements[index].data.round || 0) * 200) | number: '1.0-0' }}
                  </div>
                </div>

              </ion-col>
            </ion-row>

            <ion-row *ngIf="this.mode!='layer'">
              <ion-col class="addition-padding-top">

                <div class="title-t3">Border Width</div>
                <div class="box">
                  <div class="boxy-t3 trans">

                    <span>0<sup>px</sup></span>
                    <ion-range [ticks]="false" min="0" max="50" step="1" snaps="true" [value]="this.sharedData.elements[index].data.borderWidth || 0" (ionChange)="changeBorderWidth($event, scrollArea)">
                    </ion-range>
                    <span class="span-lg">50<sup>px</sup></span>

                  </div>
                  <div class="input-box">
                    {{ ( (this.sharedData.elements[index].data.borderWidth || 0) * 1) | number: '1.0-0' }}
                  </div>
                </div>

              </ion-col>
            </ion-row>

            <ion-row *ngIf="this.mode!='layer' && (this.sharedData.elements[index].data.borderWidth || 0)">
              <ion-col class="addition-padding-top">

                <div class="title-t3">Border Style</div>
                <div class="box" style="display: block!important;">

                  <ion-list class="list-exp no-padding-bottom" lines="none">
                    <ion-radio-group value="cover" [(ngModel)]="this.sharedData.elements[index].data.borderStyle">

                      <ion-row>
                        <ion-col>
                          <ion-item>
                            <ion-label class="ion-no-margin">Solid</ion-label>
                            <ion-radio slot="start" value="solid"></ion-radio>
                          </ion-item>

                          <ion-item>
                            <ion-label class="ion-no-margin">Dashed</ion-label>
                            <ion-radio slot="start" value="dashed"></ion-radio>
                          </ion-item>
                        </ion-col>
                        <ion-col>
                          <ion-item>
                            <ion-label class="ion-no-margin">Dotted</ion-label>
                            <ion-radio slot="start" value="dotted"></ion-radio>
                          </ion-item>

                          <ion-item>
                            <ion-label class="ion-no-margin">Double</ion-label>
                            <ion-radio slot="start" value="double"></ion-radio>
                          </ion-item>
                        </ion-col>
                      </ion-row>

                    </ion-radio-group>
                  </ion-list>

                </div>

              </ion-col>
            </ion-row>

            <ion-row *ngIf="this.mode!='layer' && (this.sharedData.elements[index].data.borderWidth || 0)">
              <ion-col class="addition-padding-top">

                <div class="title-t3">Border Color</div>
                <div class="box">
                  <div class="boxy-t3 trans-color">

                    <div (click)="pickerborder.click($event)" class="preview" [ngStyle]="{'background': sharedData.elements[index].data.borderColor}">
                      <input-color
                      style="display: none;"
                      type="color"
                      [value]="sharedData.elements[index].data.borderColor"
                      #pickerborder
                      (change)="tagPickerChangeCustomColor(sharedData.elements[index].data.borderColor, $event, index, 'borderColor')">
                      </input-color>
                    </div>

                    <ion-input class="cpt-text color-text" (ionChange)="setColorTag(sharedData.elements[index].data.borderColor, $event, index, 'borderColor')" type="text" value="{{ sharedData.elements[index].data.borderColor || 'Default' }}"></ion-input>

                  </div>
                </div>

              </ion-col>
            </ion-row>

          </ng-container>


          <!-- :: SHADOW - SETTINGS :: -->
          <ng-container *ngIf="this.mode!='layer'">

            <ion-row>
              <ion-col class="addition-padding-top">

                <div class="title-t3">Shadow Radius</div>
                <div class="box">
                  <div class="boxy-t3 trans">

                    <span>0<sup>px</sup></span>
                    <ion-range [ticks]="false" min="0" max="50" step="1" snaps="true" [value]="this.sharedData.elements[index].data.shadowRadius || 0" (ionChange)="changeShadowRadius($event, scrollArea)">
                    </ion-range>
                    <span class="span-lg">50<sup>px</sup></span>

                  </div>
                  <div class="input-box">
                    {{ ( (this.sharedData.elements[index].data.shadowRadius || 0) * 1) | number: '1.0-0' }}
                  </div>
                </div>

              </ion-col>
            </ion-row>

            <ng-container *ngIf="this.sharedData.elements[index].data.shadowRadius">
              <ion-row>
                <ion-col class="addition-padding-top">

                  <div class="title-t3">Shadow Spread</div>
                  <div class="box">
                    <div class="boxy-t3 trans">

                      <span>0<sup>px</sup></span>
                      <ion-range [ticks]="false" min="0" max="50" step="1" snaps="true" [value]="this.sharedData.elements[index].data.shadowSpread || 0" (ionChange)="changeShadowSpread($event)">
                      </ion-range>
                      <span class="span-lg">50<sup>px</sup></span>

                    </div>
                    <div class="input-box">
                      {{ ( (this.sharedData.elements[index].data.shadowSpread || 0) * 1) | number: '1.0-0' }}
                    </div>
                  </div>

                </ion-col>
              </ion-row>

              <ion-row class="ion-margin-top">

                <ion-col>

                  <div class="title-t3">Shadow Horizontal Position (x)</div>
                  <div class="box">
                    <div class="boxy-t3 trans">

                      <span style="width: 17%">-50<sup>px</sup></span>
                      <ion-range [ticks]="false" min="-50" max="50" step="1" snaps="true" [value]="sharedData.elements[index].data.shadowPosx || 0" (ionChange)="changeShadowPos($event, 'x')">
                      </ion-range>
                      <span class="span-lg">50<sup>px</sup></span>

                    </div>
                    <div class="input-box">
                      {{ ( (sharedData.elements[index].data.shadowPosx || 0) * 1) | number: '1.0-0' }}
                    </div>
                  </div>

                </ion-col>
              </ion-row>

              <ion-row>
                <ion-col class="addition-padding-top">

                  <div class="title-t3">Shadow Vertical Position (y)</div>
                  <div class="box">
                    <div class="boxy-t3 trans">

                      <span style="width: 17%">-50<sup>px</sup></span>
                      <ion-range [ticks]="false" min="-50" max="50" step="1" snaps="true" [value]="sharedData.elements[index].data.shadowPosy || 0" (ionChange)="changeShadowPos($event, 'y')">
                      </ion-range>
                      <span class="span-lg">50<sup>px</sup></span>

                    </div>
                    <div class="input-box">
                      {{ ( (sharedData.elements[index].data.shadowPosy || 0) * 1) | number: '1.0-0' }}
                    </div>
                  </div>

                </ion-col>
              </ion-row>

              <ion-row>
                <ion-col class="addition-padding-top">

                  <div class="title-t3">Shadow Color</div>
                  <div class="box">
                    <div class="boxy-t3 trans-color">

                      <div (click)="pickershadow.click($event)" class="preview" [ngStyle]="{'background': sharedData.elements[index].data.shadowColor}">
                        <input-color
                        style="display: none;"
                        type="color"
                        [value]="sharedData.elements[index].data.shadowColor"
                        #pickershadow
                        (change)="tagPickerChangeCustomColor(sharedData.elements[index].data.shadowColor, $event, index, 'shadowColor')">
                        </input-color>
                      </div>

                      <ion-input class="cpt-text color-text" (ionChange)="setColorTag(sharedData.elements[index].data.shadowColor, $event, index, 'shadowColor')" type="text" value="{{ sharedData.elements[index].data.shadowColor || 'Default' }}"></ion-input>

                    </div>
                  </div>

                </ion-col>
              </ion-row>

            </ng-container>

          </ng-container>


          <ng-container *ngIf="this.mode!='layer'">
            <!-- :: TRANSFORM - SETTINGS :: -->
            <ng-container>

              <ion-grid style="--ion-grid-padding: 0px; --ion-grid-column-padding: 0px;">
                <ion-row>
                  <ion-col class="addition-padding-top">

                    <div class="title-t3">Rotation Angle</div>
                    <div class="box">
                      <div class="boxy-t3 trans">

                        <span>0<sup>°</sup></span>
                        <ion-range [ticks]="false" min="0" max="360" step="1" snaps="true" [value]="this.sharedData.elements[index].data.rotateAngle || 0" (ionChange)="changeRotateAngle($event)">
                        </ion-range>
                        <span class="span-lg">360<sup>°</sup></span>

                      </div>
                      <div class="input-box">
                        {{ ( (this.sharedData.elements[index].data.rotateAngle || 0) * 1) | number: '1.0-0' }}
                      </div>
                    </div>

                  </ion-col>
                </ion-row>
              </ion-grid>

            </ng-container>
            <!-- :: TRANSFORM - SETTINGS :: -->


            <!-- :: COLORIZE / HUENIZE - SETTINGS :: -->
            <!-- COLORIZE -->
            <ng-container *ngIf="(this.sharedData.elements[index].data.source && this.sharedData.elements[index].data.source === 'graphic')">

              <!--{{ sharedData.elements[index].data | json }}-->

              <ion-grid style="--ion-grid-padding: 0px; --ion-grid-column-padding: 0px;">
                <ion-row>
                  <ion-col class="addition-padding-top">

                    <div class="title-t3">Graphic Color</div>

                    <div class="box">
                      <div class="boxy-t3 trans-color">

                        <div (click)="pickerGraphic.click($event)" class="preview" [ngStyle]="{'background': sharedData.elements[index].data.colorGraphic}">
                          <!-- style="opacity: 0; width: 0px;" -->
                          <input-color
                          style="display: none;"
                          type="color"
                          [value]="sharedData.elements[index].data.colorGraphic"
                          #pickerGraphic
                          (change)="tagPickerChangeCustomColor(sharedData.elements[index].data.colorGraphic, $event, index, 'colorGraphic')">
                          </input-color>

                        </div>

                          <ion-input class="cpt-text color-text" (ionChange)="setColorTag(sharedData.elements[index].data.colorGraphic, $event, index, 'colorGraphic')" type="text" value="{{ sharedData.elements[index].data.colorGraphic || 'Default Color' }}"></ion-input>

                      </div>
                    </div>

                  </ion-col>
                </ion-row>
              </ion-grid>

            </ng-container>
            <!-- COLORIZE -->
            <!-- HUE -->
            <ng-container *ngIf="!this.sharedData.elements[index].data.source || (this.sharedData.elements[index].data.source && this.sharedData.elements[index].data.source !== 'graphic')">

              <!--{{ sharedData.elements[index].data | json }}-->

              <ion-grid style="--ion-grid-padding: 0px; --ion-grid-column-padding: 0px;">
                <ion-row>
                  <ion-col class="addition-padding-top">

                    <div class="title-t3">HUE Shift</div>
                    <div class="box">
                      <div class="boxy-t3 trans">

                        <span>0<sup>°</sup></span>
                        <ion-range [ticks]="false" min="0" max="360" step="1" snaps="true" [value]="this.sharedData.elements[index].data.filterHue || 0" (ionChange)="changeHueRotate($event)">
                        </ion-range>
                        <span class="span-lg">360<sup>°</sup></span>

                      </div>
                      <div class="input-box">
                        {{ ( (this.sharedData.elements[index].data.filterHue || 0) * 1) | number: '1.0-0' }}
                      </div>
                    </div>

                  </ion-col>
                </ion-row>
              </ion-grid>

            </ng-container>
            <!-- HUE -->
            <!-- :: COLORIZE / HUENIZE - SETTINGS :: -->
          </ng-container>

        </div>

      </ng-container>
    </div>
  </div>

</ion-content>
