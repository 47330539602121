
import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { DataSourceService } from '@services/data-source.service';
import { AuthService } from '@services/auth.service';
// import { FormProcessorService } from '@services/form-processor.service';

@Component({
  selector: 'universal-upgrade-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class UniversalAccountUpgradeModalComponent implements OnInit {

  link;

  @Input() reason = 'premium-template'; // subscription, upgrade

  constructor(
    public modalCtrl: ModalController,
    public dataSource: DataSourceService,
    public auth: AuthService,
    // public formProcessor: FormProcessorService,

  ) { }

  ngOnInit() {
    // WAS - this.auth.data.partially_templates_upgrade_link = 'https://www.google.com';
    this.link =  this.auth.authAppendPathtroughParams(this.auth.data.banner_data.popup_action_link)
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      dismissed: true
    });
  }

  willUpgrade(ev) {
    (window as any).location.href = this.auth.data.banner_data.popup_action_link;
  }

  canUpgrade() {
    try{
      if (this.reason === 'premium-template') {
        if ( this.auth.data && this.auth.data.banner_data.popup_action_link ) {
          return true;
        }
      }
      return false;
    } catch (err) {
      return false;
    }
  }

}
