import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'gw-ai-assistant',
  templateUrl: './gw-ai-assistant.component.html',
  styleUrls: ['./gw-ai-assistant.component.scss'],
})
export class GwAiAssistantComponent implements OnInit {

  @Input() public tipColor = 1;

  @Input() public processing = false;

  @Input() direction: string = 'left';

  @Input() public quality: string = 'lq'; // 'hq, lq'

  @Input() public noBorder: boolean = false;

  private _processingText = 'Analysing your idea...';
    
  @Input() set processingText(value: string) {
    this._processingText = value;
    if ( this.tipColor === 4 ) {
      this.tipColor = 1;
    }
  }
  
  get processingText(): string {
    return this._processingText;
  }

  constructor() { }

  ngOnInit() {}

}
