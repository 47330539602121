import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-popup-share-link',
  templateUrl: './popup-share-link.component.html',
  styleUrls: ['./popup-share-link.component.scss'],
})
export class PopupShareLinkComponent implements OnInit {

  @Input() link;
  @Input() title;
  @Input() description;

  constructor() { }

  ngOnInit() {}

}
