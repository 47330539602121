import { Component, OnInit, Input } from '@angular/core';

import { ModalController, PopoverController, AlertController, AnimationController } from '@ionic/angular';

import { DataSourceService } from '@services/data-source.service';
import { FormProcessorService } from '@services/form-processor.service';

import { Storage } from '@ionic/storage';
import { ToastController } from '@ionic/angular';

import { ImageBrowserComponent } from '@components/image-browser/image-browser.component';


@Component({
  selector: 'app-canvas-image',
  templateUrl: './canvas-image.page.html',
  styleUrls: ['./canvas-image.page.scss', './../canvas-shared.scss'],
})
export class CanvasImagePage implements OnInit {

    imageShown = true;

    imageRepeat = false;

    layerList: any = [];
    assetsList: any = [];

    @Input() sharedData;
    @Input() key;
    @Input() i;
    @Input() index;
    @Input() mode;
    @Input() activeAsset;
    @Input() tabIndex;
    pullbackSyncIntervalHack;

    @Input() that;

    activeTab: any;

    focusData = {};

    enterAnimation;
    leaveAnimation;

    brandData: any = null;

    projectIdForFetch;
    searchResults: any = null;
    page: any = 0;
    projectAssetsList = [];
    projectAssetsListIndex = 0;

    debounce: any;
    unsplashSearch = '';
    unsplashSearchTags = [
      'space', 'technology', 'texture', 'wallpaper', 'abstract', 'animal', 'architecture', 'business', 'colorful', 'food', 'interior', 'minimal', 'art', 'nature', 'plant', 'portrait'
    ];

    constructor(
      public modalController: ModalController,
      public animationCtrl: AnimationController,
      public formProcessorService: FormProcessorService,
      public dataSource: DataSourceService,
      public alertController: AlertController,
      private storage: Storage,
      public toastController: ToastController,
    ) {

    }

    async ngOnInit() {
      this.dataSource.getBrandData().subscribe( (res) => {
        console.log('Brand Data: ', res);
        this.brandData = res;
      });

      // this.projectIdForFetch = await this.storage.get('lastActiveProjectId');
      // console.log('projectIdForFetch', this.projectIdForFetch);

      this.projectIdForFetch = this.dataSource.currentActiveProject.id;

      this.dataSource.getAssetsForPreview(this.projectIdForFetch).subscribe( (res) => {
        this.projectAssetsList = res;
      });

      if ( this.mode === 'layer' ) {
        this.activeTab = 0;
      } else if ( this.mode === 'logo' ) {
        this.activeTab = 2;
      } else if ( this.mode === 'picker' ) {
        this.activeTab = 2;
      } else {
        this.activeTab = 1;
      }
      /*
      this.focusData = eval( 'this.sharedData.' + this.key );
      this.pullbackSyncIntervalHack = setInterval( () => {
        console.log('pull');
        eval( 'this.sharedData.' + this.key + ' = ' + this.focusData + ';' )
      }, 10);
      */
      this.pullbackSyncIntervalHack = setInterval( () => {
        // console.log('pullbackSyncIntervalHack');
      }, 10);
    }

    async browseImage(index) {
      console.log('sharedData', this.sharedData, this.tabIndex, this.activeAsset.template.stages[this.tabIndex].id);
      let stageId = null;
      try{
        stageId = this.activeAsset.template.stages[this.tabIndex].id;
      } catch (err) {

      }
      const modal = await this.modalController.create({
        component: ImageBrowserComponent,
        cssClass: 'application-v2 modal-v2 solid-backdrop image-browser',
        showBackdrop: true,
        componentProps: {
          stageId,
          linkedAssetId: this.activeAsset.id,
        }
      });
      await modal.present();

      const { data } = await modal.onWillDismiss();
      console.log('data', data);
      if ( data && data.value ) {
        delete this.sharedData.elements[this.index].data.index;
        this.sharedData.elements[index].data.value = data.value;
        if ( data.source ) {
          this.sharedData.elements[index].data.source = data.source;
        }
      }
    }

    removeImage(index) {
      this.sharedData.elements[index].data.value = '';
    }

    toggleImageRepeat() {
      // this.imageRepeat = !this.imageRepeat;
      this.sharedData.elements[this.index].data.repeat = +!this.sharedData.elements[this.index].data.repeat;
    }

    toggleImageRound() {
      this.sharedData.elements[this.index].data.round = +!this.sharedData.elements[this.index].data.round; //
    }

    toggleImageShow() {
      this.sharedData.elements[this.index].data.show = +!this.sharedData.elements[this.index].data.show;
    }

    dismiss() {
      // using the injected ModalController this page
      // can "dismiss" itself and optionally pass back data
      // this.modalController.dismiss(null);
      this.submit();

    }

    submit() {
      // using the injected ModalController this page
      // can "dismiss" itself and optionally pass back data
      this.that.layoutSettingsModal.dismiss( this.sharedData );
      // this.modalController.dismiss(this.sharedData);
    }

    customize() {
      this.modalController.dismiss({ customize: true });
    }

    changeBackgroundTransparancy(ev) {
      console.log(ev);
      const targetColor = ev.srcElement.value;
      this.sharedData.elements[this.index].data.transparency = targetColor;
      /*
      color.advanced.background.uploader.transparency.value = targetColor;
      this.originalValues['background_transperancy'] = targetColor;
      */
      // this.passColor({hex: targetColor}, 'background_transperancy');
    }

    changeBackgroundPos(ev, pos) {
      console.log(ev);
      const targetColor = ev.srcElement.value;
      this.sharedData.elements[this.index].data['pos' + pos] = targetColor;
      /*
      color.advanced.background.uploader.transparency.value = targetColor;
      this.originalValues['background_transperancy'] = targetColor;
      */
      // this.passColor({hex: targetColor}, 'background_transperancy');
    }

    changeRotateAngle(ev) {
      console.log(ev);
      const targerWidth = ev.detail.value;
      this.sharedData.elements[this.index].data.rotateAngle = targerWidth;
    }

    changeHueRotate(ev) {
      console.log(ev);
      const targerWidth = ev.detail.value;
      this.sharedData.elements[this.index].data.filterHue = targerWidth;
    }

    changeBorderRadius(ev) {
      console.log(ev);
      const targerRound = ev.srcElement.value;
      this.sharedData.elements[this.index].data.round = targerRound;
    }

    changeBorderWidth(ev, scrollArea) {
      console.log(ev);
      const targerWidth = ev.srcElement.value;
      if ( !this.sharedData.elements[this.index].data.borderWidth || this.sharedData.elements[this.index].data.borderWidth === '0' ) {
        setTimeout( () => { scrollArea.scrollToBottom(250); }, 100);
        this.sharedData.elements[this.index].data.borderStyle = 'solid';
      }
      this.sharedData.elements[this.index].data.borderWidth = targerWidth;
    }

    changeShadowRadius(ev, scrollArea) {
      console.log(ev);
      const targerWidth = ev.srcElement.value;
      if ( !this.sharedData.elements[this.index].data.shadowRadius || this.sharedData.elements[this.index].data.shadowRadius === '0' ) {
        setTimeout( () => { scrollArea.scrollToBottom(250); }, 100);
        // this.sharedData.elements[this.index].data.borderStyle = 'solid';
      }
      this.sharedData.elements[this.index].data.shadowRadius = targerWidth;
    }

    changeShadowPos(ev, pos) {
      const targetColor = ev.srcElement.value;
      this.sharedData.elements[this.index].data['shadowPos' + pos] = targetColor;
    }

    changeShadowSpread(ev) {
      const targetColor = ev.srcElement.value;
      this.sharedData.elements[this.index].data['shadowSpread'] = targetColor;
    }

    tagPickerChangeCustomColor(item, ev, index, tag) {
      console.log(item, ev);
      const targetColor = ev.srcElement.value;
      // variable
      item = targetColor;
      this.sharedData.elements[index].data[tag] = item;
      // this.originalValues[] = subItem.hex;
      // this.passColor({hex: targetColor}, 'background_color');
    }

    pickerChangeCustomColor(item, ev, index) {
      console.log(item, ev);
      const targetColor = ev.srcElement.value;
      // variable
      item = targetColor;
      this.sharedData.elements[index].data.color = item;
      // this.originalValues[] = subItem.hex;
      // this.passColor({hex: targetColor}, 'background_color');
    }

    dropColor(index) {
      this.sharedData.elements[index].data.color = null;
      delete this.sharedData.elements[index].data.color;
    }

    getLayerIndexesArray() {
      if ( this.layerList.length ) {
        return this.layerList;
      }
      const ret = [{ index: 0, src: ''}];
      for (let i = 0; i < 99; i++ ) {
        if ( this.sharedData['raw' + i + 'layer'] ) {
          ret.push({ index: i, src: this.sharedData['raw' + i + 'layer']});
        }
      }
      this.layerList = ret;
      return ret;
    }

    getAssetsIndexesArray() {
      if ( this.assetsList.length ) {
        return this.assetsList;
      }
      const ret = [
      { src: 'https://bureau.ru/var/files/img1532613761' },
      { src: 'https://img.freepik.com/free-photo/pastel-tone-purple-pink-blue-gradient-defocused-abstract-photo-smooth-lines-color-background_49683-4149.jpg' }
     ];
      this.assetsList = ret;
      return ret;
    }

    setPresetIndex(item) {
      try {
        delete this.sharedData.elements[this.index].data.value;
      } catch (err) {
        // 0
      }
      this.sharedData.elements[this.index].data.index = item.index;

    }

    setActiveUnsplash(item) {
      /*
:
"https://images.unsplash.com/photo-1604076913837-52ab5629fba9?w=1200"
id
:
"tZCrFpSNiIQ"
stage_id
:
16480
thumbnail
:
"https://images.unsplash.com/photo-1604076913837-52ab5629fba9?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNzYzOTZ8MHwxfHNlYXJjaHw4fHxhYnN0cmFjdHxlbnwwfHx8fDE2NjczODU2OTg&ixlib=rb-4.0.3&q=80&w=200"
*/
      this.dataSource.postUnsplashDownloadData({ ...item }).subscribe( res => {

      });
      try {
        delete this.sharedData.elements[this.index].data.index;
      } catch (err) {
        // 0
      }
      this.sharedData.elements[this.index].data.value = item.full;
    }

    setAssetIndex(item) {
      delete this.sharedData.elements[this.index].data.index;
      this.sharedData.elements[this.index].data.value = item.picture;

    }

    uploadImage(elem, tag, source = 'logo', hook = null) {
      // this.changesSaved = false;
      console.log(elem);

      const file = elem.srcElement.files[0];
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        // convert image file to base64 string
        // preview.src = reader.result;

        // console.log( reader.result );

        fetch( (reader as any).result )
        .then(async (fetchRes) => {
          console.log( fetchRes );

          // Preview srcElement.parentElement.children[1].children
          const preview = elem.srcElement.parentElement.children[1].children[0];
          /*
          if ( preview.nodeName !== 'ION-ICON' ) {
            preview.style.backgroundImage = 'url(' + fetchRes.url + ')';
          }
          */

          const formData = new FormData();
          formData.append('image', await fetchRes.blob(), Math.random().toString(36).substr(2, 5) + '.png');
          formData.append('link_type', tag);
          // formData.append('is_main', subject.is_main);

          this.dataSource.api.setHeader('Content-Type', 'multipart/form-data; boundary=----WebKitFormBoundaryuL67FWkv1CA');

          let endpoint;
          if ( source === 'logo' ) {
            endpoint = this.dataSource.postBrandImageData(formData);
          } else {
            // media
            endpoint = this.dataSource.postMediaImageData(formData);
          }
          endpoint.subscribe(res => {
            // console.log(fetchRes, subject);
            // this.changesSaved = true;

            console.log('Image uploaded', res, this.sharedData.elements[this.index]);

            this.brandData[source].push(res);

            this.sharedData.elements[this.index].data.value = res.image;
            try {
              delete this.sharedData.elements[this.index].data.index;
            } catch (err) {
              // 0
            }

            // if ( hook ) {
              /*
              this.originalValues[hook] = res.image;
              console.log(this.originalValues[hook], 'hook');

              this.changeBackgroundTransparancy(this.settingsValues, { srcElement: { value: this.settingsValues.advanced.background.uploader.transparency.value } });
              */
            // }

          }, err => console.log(err));
          this.dataSource.api.unsetHeader('Content-Type');

        })
        .then(console.log);

      }, false);

      if (file) {
        reader.readAsDataURL(file);
      }
      return;
    }

    nameSeacrh(ev) {
      this.searchResults = [];
      this.page = 0;
      // console.log(hexToFilter.hexToFilter('#000'));

      clearTimeout( this.debounce );
      this.debounce = setTimeout( () => {

        console.log('nameSeacrh', ev);
        if ( this.unsplashSearch.length ) {

          this.loadMoreUnsplash();

        }

      }, 500);
    }

    loadMoreUnsplash() {
      this.page++;

      this.dataSource.getUnsplashForPreview(this.unsplashSearch, this.page, 20).subscribe( res => {

        if ( res && res.results ) {
          res = res.results;
        }

        res.forEach( (item) => {

          this.searchResults.push(item);

        });

      });
    }

    setActiveAssets(event) {
      console.log('setActiveAssets', event);
      this.projectAssetsListIndex = event.detail.value;
    }

    setColor(item, ev, index){
      if ( ev.srcElement.value.substring(1, 0) === '#' ) {
        this.pickerChangeCustomColor(item, ev, index);
      } else {
        ev.srcElement.value = 'Theme Color';
      }
    }

    setColorTag(item, ev, index, tag){
      if ( ev.srcElement.value.substring(1, 0) === '#' ) {
        this.tagPickerChangeCustomColor(item, ev, index, tag);
      } else {
        ev.srcElement.value = 'Black';
      }
    }

    applyAll(ev, index) {
      //
      console.log('applyAll()', this.sharedData.elements[index], this.activeAsset.id);
      // return;
      const data: any = {};

      data.key_data = (this.sharedData.elements[index]);

      this.dataSource.postApplyAllData(this.activeAsset.id, data).subscribe( async (res) => {
        const toast = await this.toastController.create({
          message: 'Style was successfully applied to all elements',
          color: 'success',
          duration: 2000
        });
        toast.present();
      });
    }

    async applyAllLayer(element, index) {

      console.log('applyAll', this.sharedData);

      const alert = await this.alertController.create({
        // cssClass: 'my-custom-class',
        header: 'Confirmation',
        message: 'Are you sure you want to apply these styles to all steps ?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            // id: 'cancel-button',
            handler: (blah) => {
              // console.log('Confirm Cancel: blah');
            }
          }, {
            text: 'Apply',
            // id: 'confirm-button',
            handler: () => {

              const data = { key_data: element };

              this.dataSource.postApplyAllData(this.activeAsset.id, data).subscribe( async (res) => {
                const toast = await this.toastController.create({
                  message: 'Settings was successfully applied to all steps',
                  color: 'success',
                  duration: 2000
                });
                await toast.present();
                return;
              });


            }
          }
        ]
      });

      await alert.present();

    }

}
