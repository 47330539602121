<br/>

<div class="ion-text-center ion-padding" *ngIf="!ready">
  <ion-spinner></ion-spinner>
</div>

<form class="checkout-form" *ngIf="ready && !statusData">

  <div class="stripe-card" ngxStripeCardGroup>

    <ion-grid class="ion-no-padding">
      <ion-row class="ion-padding-bottom">
        <ion-col>

          <b>Credit Card Number</b>

          <div class="input-holder">
            <ngx-stripe-card-number [options]="cardOptions"></ngx-stripe-card-number>
          </div>

        </ion-col>
      </ion-row>
      <ion-row class="ion-padding-bottom">
        <ion-col>

          <b>Expiry Date</b>

          <div class="input-holder">
            <ngx-stripe-card-expiry [options]="cardOptions"></ngx-stripe-card-expiry>
          </div>

        </ion-col>
        <ion-col class="ion-padding-start">

          <b>CVV</b>

          <div class="input-holder">
            <ngx-stripe-card-cvc [options]="cardOptions"></ngx-stripe-card-cvc>
          </div>

        </ion-col>
      </ion-row>
      <ion-row class="ion-padding-bottom ion-hide">
        <ion-col>

          <b>Cardholder Name</b>
          <ion-input class="form" placeholder="Full Name on Card"></ion-input>

        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>

          <ion-button color="primary" fill="solid" size="large" (click)="pay()">Submit Card</ion-button>

          <ion-button color="dark" fill="outline" size="large" (click)="cancel()">Cancel</ion-button>

        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col class="info-mark">

          <div>*After changing CC we will charge you for $20</div>

        </ion-col>
      </ion-row>
    </ion-grid>

  </div>

  
</form>

<!--
  {{ statusData | json }}

<div *ngIf="statusData">
CARD ADDED
</div>

-->