import { Component, OnInit } from '@angular/core';

import { ModalController } from '@ionic/angular';

@Component({
  selector: 'roll-over-bar',
  templateUrl: './roll-over-nav-bar.component.html',
  styleUrls: ['./roll-over-nav-bar.component.scss'],
})
export class RollOverNavBarComponent implements OnInit {

  constructor(
    public modalController: ModalController,
  ) { }

  ngOnInit() {}


}
