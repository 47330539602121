<div class="container">

  <ion-grid class="core">

    <ion-row class="header">

      <ion-col class="ion-no-padding">
        <!-- <b>Search in Library</b> -->

        <ion-header class="medium">
          <ion-toolbar>
            <ion-title class="large">Search in Library</ion-title>
            <ion-buttons class="buttons-large" slot="secondary">
              <ion-button (click)="dismiss()">
                <ion-icon slot="icon-only" name="close-outline"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
      </ion-col>

      <!-- <ion-col size="3" class="ion-text-right ion-align-self-center">
        <ion-button class="button" shape="round" (click)="dismiss()" color="light" fill="solid">
          <ion-icon slot="icon-only" name="close-outline"></ion-icon>
        </ion-button>
      </ion-col> -->

    </ion-row>

    <ion-row class="main">

      <ion-col size="2" class="menu">

        <div class="sub-menu">
          <!-- <div class="empty" [ngClass]="{ 'top-border' : tab === 0 }">&nbsp;</div> -->
          <ng-container *ngFor="let item of menus; let i = index;" >
            <div 
            
            class="item" 
            [ngClass]="{ 'active' : ( tab === i && !subTab ) || ( tab === i && subTab ), 'top-border' : tab === i + 1, 'bottom-border' : tab === i - 1 }" 
            (click)="setTab(i)">{{ ( item && item.title ) ? item.title : item }} <ion-icon *ngIf="( item && item.title )" [name]="(item && item.toggle) ? 'chevron-down-outline' : 'chevron-up-outline'"></ion-icon></div>

            <div *ngIf="item && item.menus && item.toggle">

              <div *ngFor="let sub of item.menus; let i = index;" class="sub-item" [ngClass]="{ 'active-sub' : subTab === sub.themes_list, 'ion-hide' : !sub.enabled }" (click)="setSubTab(sub.themes_list)">{{ sub.title }}</div>
            </div>
          </ng-container>

          <!-- <div class="empty" [ngClass]="{ 'bottom-border' : tab === menus.length - 1 }">&nbsp;</div> -->
        </div>

        <ion-button color="primary" size="large" (click)="this.uploadPhotos($event)">
          Upload Photos
          <ion-icon name="cloud-upload-outline"></ion-icon>
        </ion-button>

      </ion-col>

      <ion-col class="area ion-no-padding">

        <ion-grid *ngIf="tab === 0" class="items-set">

          <ion-row>

            <ion-col size="4" class="ion-no-padding">
              <!-- <ion-input [(ngModel)]="this.search" (ionChange)="changeRecent($event)" clearInput placeholder="Search">
                <ion-icon style="margin-left: 8px;" name="search-outline"></ion-icon>
                <ion-icon style="margin-left: 8px;" name="search"></ion-icon>
              </ion-input> -->
              <ion-searchbar placeholder="Search" [(ngModel)]="this.search" (ionChange)="changeRecent($event)"></ion-searchbar>
            </ion-col>

            <ion-col class="ion-text-left ion-align-self-center m-left">
              <div class="ion-padding-end" *ngIf="!loading && ( total && items && items.length )">
                <ion-text color="medium">{{ total }} results</ion-text>
              </div>
              <div class="ion-padding-end" *ngIf="loading">
                <ion-spinner name="dots"></ion-spinner>
              </div>
            </ion-col>

            <ion-col size="2" class="ion-align-self-center">
              <ion-button *ngIf="this.search" mode="ios" (click)="searchRecent($event)">Search</ion-button>
            </ion-col>

          </ion-row>

          <ion-row class="work-area" [ngClass]="{ 'ion-hide' : (items && items.length) }">

            <ion-col class="ion-no-padding">
              <div class="box">
                <div class="upload-drag-drop">
                  <input (change)="uploadImage($event)" accept="image/*" type="file" #uploader/>
                </div>
              </div>
            </ion-col>

          </ion-row>

          <ion-row class="work-area" *ngIf="items && items.length">

            <ion-col class="align">


              <ion-content class="main-items ion-no-padding">

                <ion-grid>
                  <ion-row>

                    <!-- old size="3" size="12" size-md="6" size-xl="3"-->
                    <ion-col *ngFor="let item of items" class="ion-padding" size="12" size-md="6" size-lg="4" size-xl="3">

                      <div class="image-box" [ngClass]="{ 'active' : active && ( item.id === active.id )}">
                        <div class="image">
                          <div (click)="destroyImage($event, item)" class="trash"><ion-icon name="trash-outline"></ion-icon></div>
                          <div (click)="selectImage(item)" class="content" [ngStyle]="{ 'background-image' : 'url(\'' + ( item.thumbnail_picture ? item.thumbnail_picture : item.image ) + '\')'}"></div>
                        </div>
                        <div class="text" *ngIf="item.name">{{ item.name }}</div>
                      </div>

                    </ion-col>

                  </ion-row>
                </ion-grid>

                <ion-infinite-scroll threshold="1px" (ionInfinite)="loadMore($event)" class="ion-margin-top">
                  <ion-infinite-scroll-content loadingSpinner="dots">
                  </ion-infinite-scroll-content>
                </ion-infinite-scroll>

              </ion-content>


            </ion-col>

          </ion-row>

          <ion-row class="action-footer">

            <ion-col class="ion-text-right">

              <ion-button mode="ios" (click)="selectActive($event)" [disabled]="!active">Choose</ion-button>

            </ion-col>

          </ion-row>

        </ion-grid>

        <ion-grid *ngIf="tab === 1" class="items-set">

          <ion-row>

            <ion-col size="4" class="ion-align-self-center ion-no-padding">
              <!-- <ion-input (ionChange)="changeUnsplash($event)" clearInput placeholder="Search" [(ngModel)]="unsplashSearch">
                <ion-icon style="margin-left: 8px;" name="search-outline"></ion-icon>
              </ion-input> -->
              <ion-searchbar placeholder="Search" (ionChange)="changeUnsplash($event)" [(ngModel)]="unsplashSearch"></ion-searchbar>
            </ion-col>

            <ion-col size="auto" class="ion-align-self-center">
              <ion-button size="large" *ngIf="unsplashSearch.length" mode="ios" (click)="doSearchUnsplash($event)">Search</ion-button>
            </ion-col>

            <ion-col class="ion-text-left ion-align-self-center m-left-mini">
              <div class="ion-padding-end" *ngIf="( unsplashTotal && unsplashItems && unsplashItems.length )">
                <ion-text color="medium">{{ unsplashTotal }} results</ion-text>
              </div>
            </ion-col>

          </ion-row>

          <ion-row class="work-area" [ngClass]="{ 'ion-hide' : (unsplashItems && unsplashItems.length ) }">

            <!-- Tags -->
            <ion-col class="unsplash-align">
              <ion-button (click)="unsplashSelectTag(tag)" fill="solid" color="white" *ngFor="let tag of unsplashTags;" class="unsplash-btn">
                {{ tag }}
              </ion-button>

              <div *ngIf="unsplashLoading && !(unsplashItems && unsplashItems.length)" class="ion-padding ion-text-center">
                <ion-spinner name="dots"></ion-spinner>
              </div>

            </ion-col>

          </ion-row>

          <ion-row class="work-area" *ngIf="unsplashItems && unsplashItems.length">

            <ion-col class="align-inside">


              <ion-content class="main-items ion-no-padding">

                <div class="ion-margin-bottom align-inside-btn">
                  <ion-button (click)="unsplashSelectTag(tag)" fill="solid" color="white" *ngFor="let tag of unsplashTags;" class="unsplash-btn">
                    {{ tag }}
                  </ion-button>
                </div>

                <ngx-masonry [options]="{ }">
                  <div ngxMasonryItem class="masonry-item" [ngClass]="{ 'active' : unsplashActive && ( item.id === unsplashActive.id )}" *ngFor="let item of unsplashItems">
                   <img (click)="selectImageUnsplash(item)" [src]="item.thumbnail"/>
                   <div class="author">
                     <a target="_blank" [href]="item.author_link">{{ item.author }}</a>
                   </div>
                 </div>
                </ngx-masonry>

                <ion-infinite-scroll threshold="100px" (ionInfinite)="loadMoreUnsplash($event)" class="ion-margin-top">
                  <ion-infinite-scroll-content loadingSpinner="dots">
                  </ion-infinite-scroll-content>
                </ion-infinite-scroll>

              </ion-content>


            </ion-col>

          </ion-row>

          <ion-row class="action-footer">

            <ion-col class="ion-text-right">

              <ion-button mode="ios" [disabled]="!unsplashActive" (click)="selectActiveUnsplash($event)">Choose</ion-button>

            </ion-col>

          </ion-row>

        </ion-grid>

        <!-- Legacy Ads -->
        <ion-grid *ngIf="tab === 999" class="items-set">

          <ion-row>

            <ion-col size="4" class="ion-align-self-center ion-no-padding">

              <ion-select [(ngModel)]="assetsItemsIndex" placeholder="Choose Asset" interface="alert" [interfaceOptions]="{ header: 'Choose Asset' }" (ionChange)="assetsSetActive($event)">
                <ion-select-option [value]="i" *ngFor="let item of assetsItems; let i = index;">{{ item.title }}</ion-select-option>
              </ion-select>

            </ion-col>

            <ion-col class="ion-text-left m-left ion-align-self-center">
              <div class="ion-padding-end" *ngIf="( assetsItems && assetsItems.length )">
                <ion-text color="medium">{{ assetsItems.length }} assets</ion-text>
              </div>
            </ion-col>

          </ion-row>

          <ion-row class="work-area" [ngClass]="{ 'ion-hide' : (assetsItems && assetsItems.length ) }">

            <!-- Tags -->
            <ion-col>

              <div *ngIf="assetsLoading && !(assetsItems && assetsItems.length)" class="ion-padding ion-text-center">
                <ion-spinner name="dots"></ion-spinner>
              </div>

            </ion-col>

          </ion-row>

          <ion-row class="work-area" *ngIf="assetsItems && assetsItems.length">

            <ion-col class="assets-align">

              <ion-content class="main-items ion-no-padding">

                <div *ngIf="!assetsItems[assetsItemsIndex].stages.length" class="ion-padding ion-text-center">
                  No records yet in this asset
                </div>

                <ion-grid>
                  <ion-row class="mini">

                    <ng-container *ngFor="let item of assetsItems[assetsItemsIndex].stages">
                      <ion-col class="ion-padding" size="12" size-md="6" size-lg="4" size-xl="3">

                        <div class="image-box" [ngClass]="{ 'active' : assetsActive && ( item.picture === assetsActive.picture )}">
                          <div class="image">
                            <div (click)="selectAssetImage(item)" class="content" [ngStyle]="{'background-image': 'url(' + item.thumbnail + ')'}"></div>
                          </div>
                        </div>

                      </ion-col>
                    </ng-container>

                  </ion-row>
                </ion-grid>

              </ion-content>


            </ion-col>

          </ion-row>

          <ion-row class="action-footer">

            <ion-col class="ion-text-right">

              <ion-button mode="ios" [disabled]="!assetsActive" (click)="selectActiveAsset($event)">Choose</ion-button>

            </ion-col>

          </ion-row>

        </ion-grid>


        <ion-grid *ngIf="tab === 2" class="items-set">

          <ion-row>

            <ion-col class="filters">
              <b>Campaign</b><br/>
              <ion-input
              [customDropdown]="{
                  multiple: false,
                  search: 'label',
      
                  options: this.assetData && this.assetData.filters && this.assetData.filters['campaign'] ? this.assetData.filters['campaign'] : []
              }" 
              [(ngModel)]="assetFilters.campaign"
              (ionChange)="changeAssetFilters($event, 'campaign')"
              class="form" placeholder="Choose Campaign"></ion-input>
            </ion-col>

            <ion-col class="filters">
              <b>Asset</b><br/>
              <ion-input
              [customDropdown]="{
                  multiple: false,
                  search: 'label',
      
                  options: this.assetData && this.assetData.filters && this.assetData.filters['asset'] ? this.assetData.filters['asset'] : []
              }" 
              [(ngModel)]="assetFilters.asset"
              (ionChange)="changeAssetFilters($event, 'asset')"
              class="form" placeholder="Choose Asset"></ion-input>
            </ion-col>

            <ion-col class="filters">
              <b>Pack</b><br/>
              <ion-input
              [ngClass]="{ 'disabled' : !(this.assetData && this.assetData.filters && this.assetData.filters['pack'] && this.assetData.filters['pack'].length) }"
              [customDropdown]="{
                  multiple: false,
                  search: 'label',
      
                  options: this.assetData && this.assetData.filters && this.assetData.filters['pack'] ? this.assetData.filters['pack'] : []
              }" 
              [(ngModel)]="assetFilters.pack"
              (ionChange)="changeAssetFilters($event, 'pack')"
              class="form" placeholder="Choose Asset Pack"></ion-input>
            </ion-col>

          </ion-row>

          <ion-row>

            <ion-col *ngIf="this.assetData && this.assetData.stages">

              <div *ngIf="this.assetData.stages.length === 0" class="ion-padding ion-text-center">
                No records yet in this asset
              </div>

              <div>

                <ion-row>

                  <ion-col *ngFor="let item of this.assetData.stages;" class="ion-padding" size="12" size-md="6" size-lg="4" size-xl="3">

                    <div class="image-box assets-box" [ngClass]="{ 'active' : assetsActive && ( item.picture === assetsActive.picture )}">
                      <div class="image">
                        <div (click)="selectAssetImage(item)" class="content" [ngStyle]="{'background-image': 'url(' + item.thumbnail + ')'}"></div>
                      </div>
                    </div>

                  </ion-col>

                </ion-row>
                
              </div>
              

            </ion-col>

          </ion-row>

          <ion-row class="disclaimer">
            <ion-col>
              <ion-icon name="information-circle-outline"></ion-icon> <ion-text color="medium">Any assets you have edited and saved will appear here.</ion-text>
            </ion-col>
          </ion-row>

        </ion-grid>

        <ion-grid *ngIf="tab === 3" class="items-set">

          <ion-row>

            <ion-col size="4" class="ion-align-self-center ion-no-padding">
              <!-- <ion-input (ionChange)="changeGraphics($event)" clearInput placeholder="Search" [(ngModel)]="graphicSearch">
                <ion-icon style="margin-left: 8px;" name="search-outline"></ion-icon>
              </ion-input> -->
              <ion-searchbar (ionChange)="changeGraphics($event)" clearInput placeholder="Search" [(ngModel)]="graphicSearch"></ion-searchbar>
            </ion-col>

            <ion-col class="ion-text-left ion-align-self-center m-left">
              <div class="ion-padding-end" *ngIf="( graphicsItems && graphicsItems.length )">
                <ion-text color="medium">{{ graphicsItems.length }} results</ion-text>
              </div>
            </ion-col>

          </ion-row>

          <ion-row class="work-area">

            <ion-col class="align">

              <ion-content class="main-items ion-no-padding">

                

                <ion-grid>
                  <ion-row>

                    <ng-container *ngFor="let item of searchFiltered(graphicsItems)">
                      <ion-col class="ion-padding" size="3">

                        <div [title]="item.name" class="image-box" [ngClass]="{ 'active' : graphicsActive && ( item.url === graphicsActive.url )}">
                          <div class="image">
                            <div (click)="selectGraphicsImage(item)" class="content" [ngStyle]="{'background-image': 'url(' + item.url + ')'}"></div>
                          </div>
                        </div>

                      </ion-col>
                    </ng-container>

                  </ion-row>
                </ion-grid>

              </ion-content>


            </ion-col>

          </ion-row>

          <ion-row class="action-footer">

            <ion-col class="ion-text-right">

              <ion-button mode="ios" [disabled]="!graphicsActive" (click)="selectActiveGraphic($event)">Choose</ion-button>

            </ion-col>

          </ion-row>

        </ion-grid>

      </ion-col>

    </ion-row>

  </ion-grid>

</div>
