import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { Router } from '@angular/router';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { AutoSizeInputModule } from "ngx-autosize-input";

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicStorageModule } from '@ionic/storage';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { EngineModule } from '@engine/engine.module';
import { EditorModule } from '@tinymce/tinymce-angular';
// Modals

// Components
// import { CommonFooterComponent } from '@components/common-footer/common-footer.component';
import { CustomComponentsModule } from '@engine/custom-components.module';
import { SafePipeModule } from 'safe-pipe';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { NgxStripeModule } from 'ngx-stripe';

@NgModule({
    declarations: [AppComponent],
    imports: [
        NgxStripeModule.forRoot(environment.stripePkKey),

        BrowserModule,
        BrowserAnimationsModule,
        IonicModule.forRoot({
            rippleEffect: false,
            mode: 'md'
        }),
        IonicStorageModule.forRoot({
            name: '__growth',
            driverOrder: ['localstorage']
        }),
        AppRoutingModule,
        EngineModule.forRoot(),
        HttpClientModule,
        CustomComponentsModule,
        SafePipeModule,
        FormsModule,
        ReactiveFormsModule,
        MomentModule,
        EditorModule,
        AutoSizeInputModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: true,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
    ],
    providers: [
        StatusBar,
        SplashScreen,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
