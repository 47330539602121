<div class="application-v2">
<div>

  <div>

    <div (click)="dismiss(1)" class="menu-item no-margin">
      <div class="label">
        <div class="icon">
          <ion-icon name="pencil-outline"></ion-icon>
        </div>
        <div class="title">Edit</div>
      </div>
    </div>

    <div (click)="dismiss(2)" class="menu-item no-margin">
      <div class="label">
        <div class="icon">
          <ion-icon name="copy-outline"></ion-icon>
        </div>
        <div class="title">Duplicate</div>
      </div>
    </div>

    <div (click)="dismiss(3)" class="menu-item no-margin ion-color-danger">
      <div class="label">
        <div class="icon">
          <ion-icon name="trash-outline"></ion-icon>
        </div>
        <div class="title">Delete</div>
      </div>
    </div>

  </div>

</div>
</div>
