<ion-progress-bar color="primary" [value]="(activePage/totalPages)"></ion-progress-bar>

<div class="actions" *ngIf="activePage !== 4">
  <ion-button (click)="move(0)" *ngIf="activePage > 1" color="dark" fill="outline">Prev <ion-icon name="chevron-back-outline" slot="start"></ion-icon></ion-button>
  <ion-button class="ion-float-right" *ngIf="activePage > 0" (click)="move(1)" color="primary" fill="solid">Next <ion-icon name="chevron-forward-outline" slot="end"></ion-icon></ion-button>
</div>

<ion-fab vertical="top" horizontal="end" slot="fixed">
  <ion-fab-button (click)="dismiss()">
    <ion-icon name="close"></ion-icon>
  </ion-fab-button>
</ion-fab>

<ion-content id="mainFrame" #mainframe>

  <div class="full-page-slide loading" [ngClass]="{'active-render': activePage === 0, 'prev-render': activePage === 1}" *ngIf="renderState(0)">
    <!-- Loading -->
    <div class="block">

      <div class="loading-box">
        <gw-ai-blob quality="lq"></gw-ai-blob>
        <div class="animated-text">{{ renderGreeting() }}</div>
      </div>

    </div>
  </div>

  <div class="full-page-slide select" [ngClass]="{'active-render': activePage === 1, 'prev-render': activePage === 2}"  *ngIf="renderState(1)">
    <!-- Select Funnel -->
    <div class="block">

      <div class="header">What type of Customer Engine are you building?</div>

      <div class="funnel-boxes">

        <ion-grid class="ion-no-padding">
          <ion-row>
            <ion-col size="4" *ngFor="let item of data; let i = index;">
              <div class="box" (click)="selectActiveItem(item)" [ngClass]="{'active-funnel': (data.selectedItem) && (data.selectedItem.slug === item.slug), 'coming-soon': item.is_coming}">
                <div class="label" *ngIf="item.is_recommended">Recomended</div>
                <div class="mark" *ngIf="(data.selectedItem) && (data.selectedItem.slug === item.slug)"></div>
                <div class="icon" [style.background-image]="'url(' + item.icon + ')'"></div>
                <div>{{ item.title }} <span *ngIf="item.is_coming">(Coming Soon)</span></div>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>

      </div>

      <div class="subtitle" *ngIf="this.data && this.data.selectedItem && this.data.selectedItem.description">{{ this.data.selectedItem.description }}</div>

    </div>
  </div>

  <div class="full-page-slide steps" [ngClass]="{'active-render': activePage === 2, 'prev-render': activePage === 3}"  *ngIf="renderState(2)">
    <div class="block">

      <div class="header">Hot Step For Your Customer Engine</div>
      <div class="info-title">Choose Step: <div tooltipComponent="large" [tooltipWidth]="202" [tooltipHeight]="200" [tooltip]="'This is the hot step you will first market for your program. Steps can be added and changed later.'" class="info-label"></div></div>
      <div class="steps-bar">


        <span class="funnel-step" *ngFor="let item of (this.data && this.data.selectedItem) ? this.data.selectedItem.roadmapsteps : [] ; let i = index">
          <button (click)="setActiveStep(item.order)" [ngClass]="{'selected-step': activeStep === item.order}">{{ item.title }}</button>
        </span>


      </div>

    </div>
  </div>

  <div class="full-page-slide themes" [ngClass]="{'active-render': activePage === 3, 'prev-render': activePage === 4}"  *ngIf="renderState(3)">
    <div class="block">

      <div class="header">Choose Your Theme</div>
      <ion-grid class="ion-no-padding main-grid">
        <ion-row>

          <ion-col size="auto">

            <div class="preset-box">

              <div class="scroll-box">
                <ng-container *ngFor="let item of data.selectedItem.themes_data; let i = index">

                  <div class="preset" 

                    [ngStyle]="{
                      'font-family': (item.theme_preview ? item.theme_preview.font.family : ''),
                      'font-weight': (item.theme_preview ? item.theme_preview.font.weight : ''),
                      'color': (item.theme_preview ? item.theme_preview.font.color : ''),
                      'background': (item.theme_preview ? item.theme_preview.background : '')
                    }"

                    (click)="data.selectedThemes = i;"
                    (mouseenter)="hoveredThemeDescription = item.title"
                    (mouseleave)="hoveredThemeDescription = ''"
                    [ngClass]="{'active-theme': i === data.selectedThemes}" 
                    >
                  
                    <div class="mark" *ngIf="i === data.selectedThemes"></div>
                    <div class="title">{{ item.title }}</div>
                    <div class="colors-box" *ngIf="item.theme_preview">
                      <div class="color" *ngFor="let color of item.theme_preview.color_stack" [style.background-color]="color"></div>
                    </div>
                    
                  </div>

                </ng-container>
              </div>

            </div>

          </ion-col>

          <ion-col size="auto" size-md="1"></ion-col>

          <ion-col>
            <!-- <div class="mockup" [style.background-image]="'url(/assets/funnels-create-mock/' + selectedTheme.path + '.png)'"></div> -->
            <div class="mockup" [style.background-image]="getPlaceholderUrl()"></div>
          </ion-col>

        </ion-row>
      </ion-grid>

    </div>
  </div>

  <div class="full-page-slide" [ngClass]="{'active-helper': ready, 'active-render': activePage === 4, 'prev-render': activePage === 5}"  *ngIf="renderState(4)">

    <gw-ai-assistant

    #assistantBubble 

    [tipColor]="loading" 
    
    direction="down" 
    quality="hq" 
    [processingText]="currentLoadingPhrase()" 
    [processing]="loading" 

    ></gw-ai-assistant>

  </div>

</ion-content>


