import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

console.log('GrowthWorks / V2 / PWA / GitHub');
console.log('window.location.hostname', window.location.hostname, environment);


if (
  environment.production &&
  window.location.hostname !== 'localhost' &&
  window.location.hostname !== '192.168.0.101' &&
  !/HeadlessChrome/.test(window.navigator.userAgent)
) {

  (window as any).console.log = () => {};

  enableProdMode();
} else {
  console.log(':: NON PROD MODE ::');
}

platformBrowserDynamic().bootstrapModule(AppModule).then(() => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('ngsw-worker.js');
  }
}).catch(err => console.log(err));


// Custom Console Tools
(window as any).gw= () => {
  console.clear();
  console.warn('GrowthWorks - Console Tools List');
  console.group('GrowthWorks Console Tools');
  // List All Console Tools Commands as list

  // User Manipulations - Group atributes and overrides for (table)
  {
    console.groupCollapsed('User Manipulations');

    // User Entity Override
    {
      console.warn('gwUserEntity() - Displays local key in auth.data (local user model)');

      console.warn('gwUserEntity(key, value) - Overrides local key in auth.data (local user model)');
      const attrs = [
        ["key", "value"],
        ["string", "any"],
        ["user key attribute in model", "provided override value"],
      ];
      console.table(attrs);
    }

    console.groupEnd();
  }
  
  // Tracking Tools - Group tracking tools for (table)
  {
    console.groupCollapsed('Tracking Tools');

    // Tracking Debug
    {
      console.warn('gwTrackingDebug(flag) - Display Debug Tracking Wireframes');
      console.table([
        ["flag"],
        ["bool"],
        ["true/false"],
      ]);

      console.warn('gwTrackingEnabled(flag) - Enable/Disable Tracking Data');
      console.table([
        ["flag"],
        ["bool"],
        ["true/false"],
      ]);
    }

    console.groupEnd();
  }

  console.groupEnd();


}

// Google Tag Manager

// Dynamicaly Inject Google Tag with appropriate gtag id
/*
  <!-- Google tag (gtag.js) -->
  <script async src="https://www.googletagmanager.com/gtag/js"></script>
  <script> 
    <!-- (see. Tracking Data Debugging in main.ts) -->
    const dataLayer = (window).dataLayer || []; 
    function gtag() {
      console.log('(G)oogle Tag Manager - gtag', dataLayer);
      dataLayer.push(arguments);
    } 
    (window).gtag = gtag;
  </script>
*/

(window as any).isTrackingEnabled = ((localStorage.getItem('tracking-enabled') || 'true') === 'true');
console.log('window.isTrackingEnabled', (window as any).isTrackingEnabled );

const scriptUrl = 'https://www.googletagmanager.com/gtag/js?id=' + environment.gTagManager;
const script = document.createElement('script');
script.src = scriptUrl;
script.async = true;
document.head.appendChild(script);

const dataLayer = (window as any).dataLayer || []; 
function gtag() {
  
  // console.log('window.isTrackingEnabled', (window as any).isTrackingEnabled );

  if ( (window as any).isTrackingEnabled  ) {
    console.log('(G)oogle Tag Manager - gtag', dataLayer);
    dataLayer.push(arguments);
  } else {
    console.warn('(G)oogle Tag Manager - Disabled!');
  }
  
} 
(window as any).gtag = gtag;
(window as any).dataLayer = dataLayer;

console.log('(G)oogle Tag Manager injected', scriptUrl, (window as any).gtag, (window as any).dataLayer);

if ( (window as any).isTrackingEnabled  ) {
  // environment.gTagManager
  (window as any).gtag('config', environment.gTagManager); 
  (window as any).gtag('js', new Date()); // Google Tag Manager
}

// Tracking Data Debugging
(window as any).gwTrackingDebug = (flag: boolean) => {
  (window as any).localStorage.setItem('tracking-debug', flag);
  console.warn('Display Tracking Data: ' + (flag ? 'Enabled' : 'Disabled'));
  window.location.reload();
  return (flag ? 'Enabled' : 'Disabled');
}

(window as any).gwTrackingEnabled = (flag: boolean) => {
  (window as any).localStorage.setItem('tracking-enabled', flag);
  (window as any).isTrackingEnabled = flag;
  console.warn('Global Event Tracking: ' + (flag ? 'Enabled' : 'Disabled'));
  return (flag ? 'Enabled' : 'Disabled');
}

/*
(window as any).gwDebugTracking = (flag: boolean) => {
  // toggle .gw-tracking-debug-wireframes on body
  (window as any).localStorage.setItem('tracking-debug', flag);
  console.warn('Event Tracking Debug: ' + (flag ? 'Enabled' : 'Disabled'));
  window.location.reload();
}
*/

// Custom Console Utilities  for Auth / Permissions debug
(window as any).gwUserEntity = (key?: string, value?: any) => {
  // (window as any).userDataOverride
  console.warn('User Model Change: ' + 'Override local auth.data', key, value);
  // Send Event To Notify Angular context (AuthService)
  // dispatch event
  (window as any).document.dispatchEvent(new CustomEvent('gwUserEntity', { detail: { key, value } }));
  return { key: value };
}