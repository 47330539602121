<ion-content>

  <!-- Back -->
  <ion-fab vertical="top" horizontal="start" slot="fixed">
    <ion-fab-button (click)="dissmis()">
      <ion-icon name="arrow-back-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>

  <div class="box">
    <div class="main">
      <gw-ai-blob [processing]="true" [quality]="'hq'"></gw-ai-blob>

      <div class="message color-{{ index % 3 }}">{{ message }}</div>
    </div>
  </div>

</ion-content>