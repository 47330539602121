<ion-fab vertical="top" horizontal="end" slot="fixed">
<ion-fab-button size="small" (click)="dismiss()">
  <ion-icon name="close"></ion-icon>
</ion-fab-button>
</ion-fab>

<ion-content class="ion-padding {{reason}}" color="white">

  <div class="wrapper" *ngIf="reason === 'premium-template'">
    <div class="bg-img"></div>
    <div class="info-block">
      <div class="label">
        <div>Upgrade to unlock EVERYTHING!</div>
        <img src="assets/v2/icons/premium.png" width="20">
      </div>
      <div class="title">Get Growthworks Core</div>
      <div class="content">Access The Complete Growthworks AI Platform, NEW CRM Plus Our Premium Courses and LIVE Support When You Upgrade Today!</div>
      <div>
        <div class="list">
          <img src="assets/v2/icons/premium-icon-one.png" width="24" height="24">
          <div>Unlock unlimited templates: Use 30+ editing tools and custom templates.</div>
        </div>
        <div class="list">
          <img src="assets/v2/icons/premium-icon-two.png" width="24" height="24">
          <div>Unlock Websites & Funnels: One click you websites and funnels </div>
        </div>
        <div class="list">
          <img src="assets/v2/icons/premium-icon-three.png" width="24" height="24">
          <div>Priority Support: Enjoy faster response.</div>
        </div>
      </div>
      <div class="btn-box">
        <ion-button href="{{ link }}" target="_blank" [disabled]="!canUpgrade()"  color="white" size="large">
          <img src="assets/v2/icons/premium.png" width="20">
          Upgrade to core
        </ion-button>
        <ion-button  href="https://growthworks.io/gw-ai-aa-page-np" target="_blank" color="white" fill="clear" size="large">
          Watch demo
          <ion-icon slot="end" name="caret-forward-outline"></ion-icon>
        </ion-button>
      </div>
    </div>
  </div>


</ion-content>

  