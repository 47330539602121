<div class="application-v2 widget">

  <div id="header">
    <div id="close" (click)="closeWidget()">
      <ion-icon name="close-outline"></ion-icon>
    </div>
  </div>

  <div id="main">

    <div id="content">

      <!--
      <span class="magic-action" (click)="presentResultOptionsPopover($event)"><ion-icon name="color-wand-outline"></ion-icon> Results Course Headlines</span>

      <brainstorming-no-results></brainstorming-no-results>

      <ion-select class="intext" value="brown" okText="Okay" cancelText="Dismiss">
        <ion-select-option value="brown">Brown</ion-select-option>
        <ion-select-option value="blonde">Blonde</ion-select-option>
        <ion-select-option value="black">Black</ion-select-option>
        <ion-select-option value="red">Red</ion-select-option>
      </ion-select>
      -->

      <ion-content class="debug" *ngIf="toggleDebug">
        <pre>
          {{ this.brainstorming._requestHistoryCache | json }}

          {{ this.brainstorming.contextPayload | json }}

          {{ this.brainstorming.ui | json }}
        </pre>
      </ion-content>



      <div class="avatar-wrapper" *ngIf="(brainstorming.isState(0) || brainstorming.isState(-1) || brainstorming.isState(1))">
        <brainstorming-avatar class="main-avatar" [noBorder]="true" [processing]="((brainstorming.isState(-1)) ? true : false)" [quality]="'hq'"></brainstorming-avatar>
      </div>

      <!-- <brainstorming-avatar class="main-avatar" [processing]="((brainstorming.isState(-1)) ? true : false)" [quality]="'lq'"></brainstorming-avatar> -->

      <div data-screen="mode" *ngIf="(brainstorming.isState(-1))">
        
        <div class="select-mode">

          <h3>Hello! ✋🏻 <br/> How can I help you today?</h3>


          
          <div class="mode-switch">

            <div class="mode" (click)="setMode('generate')">

              <div class="icon">
                <svg  xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.52448 1.04518C8.21868 1.29262 7.78152 1.29262 7.47571 1.04518C6.80733 0.511795 5.91275 0.356864 5.10391 0.634415C4.29508 0.911967 3.68411 1.58352 3.48405 2.41492C3.4133 2.7384 3.16003 2.99069 2.83628 3.06019C1.88452 3.25336 1.09124 3.90741 0.720177 4.80489C0.349109 5.70238 0.448889 6.72566 0.986344 7.53455C1.18001 7.81811 1.18001 8.1914 0.986344 8.47495C0.448889 9.28384 0.349109 10.3071 0.720177 11.2046C1.09124 12.1021 1.88452 12.7561 2.83628 12.9493C3.16003 13.0188 3.4133 13.2711 3.48405 13.5946C3.6852 14.4253 4.29661 15.0957 5.10528 15.3723C5.91395 15.649 6.8079 15.4935 7.47571 14.9601C7.78152 14.7127 8.21868 14.7127 8.52448 14.9601C9.19345 15.4935 10.0887 15.6479 10.8977 15.3695C11.7067 15.091 12.3172 14.4184 12.5161 13.5862C12.5869 13.2628 12.8402 13.0105 13.1639 12.941C14.1158 12.7477 14.9091 12.0934 15.28 11.1957C15.6509 10.298 15.5508 9.27459 15.013 8.46578C14.8201 8.1826 14.8201 7.81023 15.013 7.52705C15.5508 6.71823 15.6509 5.69481 15.28 4.79711C14.9091 3.89941 14.1158 3.24514 13.1639 3.05185C12.8402 2.98236 12.5869 2.73007 12.5161 2.40659C12.315 1.57591 11.7036 0.905466 10.8949 0.628816C10.0862 0.352165 9.19229 0.507617 8.52448 1.04102V1.04518Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M8.00009 1.22852L8.00009 14.77" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M10.5012 11.3353C10.5012 10.4145 11.2477 9.66797 12.1686 9.66797" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M5.49915 4.66602C5.49915 5.58687 4.75265 6.33338 3.83179 6.33338" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M5.49915 11.3353C5.49915 10.4145 4.75265 9.66797 3.83179 9.66797" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M10.5012 4.66602C10.5012 5.58687 11.2477 6.33338 12.1686 6.33338" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>

                <svg class="hover"  xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.52439 1.0446C8.21858 1.29204 7.78143 1.29204 7.47562 1.0446C6.80723 0.511215 5.91266 0.356284 5.10382 0.633835C4.29499 0.911387 3.68402 1.58294 3.48396 2.41434C3.41321 2.73782 3.15994 2.99011 2.83619 3.05961C1.88443 3.25278 1.09115 3.90683 0.720085 4.80431C0.349018 5.7018 0.448797 6.72508 0.986252 7.53397C1.17992 7.81753 1.17992 8.19082 0.986252 8.47437C0.448797 9.28326 0.349018 10.3065 0.720085 11.204C1.09115 12.1015 1.88443 12.7556 2.83619 12.9487C3.15994 13.0182 3.41321 13.2705 3.48396 13.594C3.68511 14.4247 4.29652 15.0951 5.10519 15.3718C5.91386 15.6484 6.80781 15.493 7.47562 14.9596C7.78143 14.7121 8.21858 14.7121 8.52439 14.9596C9.19336 15.493 10.0886 15.6474 10.8976 15.3689C11.7066 15.0905 12.3171 14.4178 12.5161 13.5857C12.5868 13.2622 12.8401 13.0099 13.1638 12.9404C14.1157 12.7471 14.909 12.0928 15.2799 11.1951C15.6509 10.2974 15.5508 9.27402 15.0129 8.4652C14.82 8.18202 14.82 7.80965 15.0129 7.52647C15.5508 6.71765 15.6509 5.69423 15.2799 4.79653C14.909 3.89883 14.1157 3.24456 13.1638 3.05127C12.8401 2.98178 12.5868 2.72949 12.5161 2.40601C12.3149 1.57533 11.7035 0.904887 10.8948 0.628236C10.0862 0.351585 9.1922 0.507037 8.52439 1.04044V1.0446Z" fill="white"/>
                  <path d="M8 1.22885L8 14.7703" stroke="#1A1A1A" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M10.501 11.3347C10.501 10.4139 11.2475 9.66736 12.1684 9.66736" stroke="#1A1A1A" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M5.49897 4.66528C5.49897 5.58614 4.75246 6.33264 3.8316 6.33264" stroke="#1A1A1A" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M5.49897 11.3347C5.49897 10.4139 4.75246 9.66736 3.8316 9.66736" stroke="#1A1A1A" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M10.501 4.66528C10.501 5.58614 11.2475 6.33264 12.1684 6.33264" stroke="#1A1A1A" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
              </div>

              <div class="text">
                Generate Content
              </div>

            </div>

            <div class="mode" (click)="setMode('edit')">

              <div class="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M1.41091 10.0157L10.0159 1.41074C10.3409 1.08574 10.8684 1.08574 11.1934 1.41074L12.5901 2.80741C12.9151 3.13241 12.9151 3.65991 12.5901 3.98491L3.98425 12.5891C3.82841 12.7457 3.61675 12.8332 3.39591 12.8332H1.16675L1.16675 10.6041C1.16675 10.3832 1.25425 10.1716 1.41091 10.0157Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M8.4585 2.9668L11.0335 5.5418" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>

                <svg class="hover" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M1.41091 10.0167L10.0159 1.41172C10.3409 1.08672 10.8684 1.08672 11.1934 1.41172L12.5901 2.80839C12.9151 3.13339 12.9151 3.66089 12.5901 3.98589L3.98425 12.5901C3.82841 12.7467 3.61675 12.8342 3.39591 12.8342H1.16675L1.16675 10.6051C1.16675 10.3842 1.25425 10.1726 1.41091 10.0167Z" fill="white" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.4585 2.9668L11.0335 5.5418Z" fill="white"/>
                  <path d="M8.4585 2.9668L11.0335 5.5418" stroke="#1A1A1A" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
              </div>

              <div class="text">
                Edit Content
              </div>

            </div>

          </div>

          <br/>


        </div>
      </div>

      
      <div data-screen="generate" *ngIf="(brainstorming.isState(0) )">

        <ng-container *ngIf="brainstorming.contextPayload.mode === 'edit'">

          <div class="ion-padding custom-skeleton">
            
            <ion-skeleton-text animated></ion-skeleton-text>
  
            <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
            <ion-skeleton-text animated style="width: 88%"></ion-skeleton-text>
            <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
            <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
            <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
            <ion-skeleton-text animated style="width: 20%"></ion-skeleton-text>
  
            <ion-skeleton-text animated></ion-skeleton-text>
  
          </div>

        </ng-container>
        
        <ng-container *ngIf="brainstorming.contextPayload.mode !== 'edit'">
          
          <div *ngIf="brainstorming && brainstorming.ui && brainstorming.ui.setup_array">
        
            <h3>
              <ng-container *ngFor="let item of brainstorming.ui.setup_array">
                <ng-container *ngIf="(item !== '[[cat]]' && item !== '[[step]]')">
                  <span>{{ item }}&nbsp;</span>
                </ng-container>
                <ng-container *ngIf="(item === '[[cat]]')">
                  <ion-input class="intext" (ionChange)="blurSetup('cat', $event)" [(ngModel)]="brainstorming.ui.selectedCategory" [customDropdown]="{
                    multiple: false,
                    options: brainstorming.ui.categoryOptions
                  }"></ion-input>
                </ng-container>
                <ng-container *ngIf="(item === '[[step]]')">
                  <ion-input class="intext" (ionChange)="blurSetup('step', $event)" [(ngModel)]="brainstorming.ui.selectedStep" [customDropdown]="{
                    multiple: false,
                    options: brainstorming.ui.stepOptions
                  }"></ion-input>
                </ng-container>
              </ng-container>
            </h3>
    
          </div>
  
          <!-- <br/> -->
  
          <div class="ion-padding custom-skeleton" *ngIf="!(brainstorming && brainstorming.ui && brainstorming.ui.categories)">
            
            <ion-skeleton-text animated></ion-skeleton-text>
  
            <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
            <ion-skeleton-text animated style="width: 88%"></ion-skeleton-text>
            <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
            <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
            <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
            <ion-skeleton-text animated style="width: 20%"></ion-skeleton-text>
  
            <ion-skeleton-text animated></ion-skeleton-text>
  
          </div>
  
          <div class="quick-actions" *ngIf="(brainstorming && brainstorming.ui && brainstorming.ui.categories)">
            <small>{{ brainstorming.ui.setup_state || '...' }}</small>
  
            <!-- <br/><br/> -->
  
            <div class="btns-wrapper">
  
              <ng-container *ngFor="let text of brainstorming.ui.categories;">
                <ion-button (click)="brainstorming.selectPromptCategory(text.slug)" fill="outline" color="light">
                  {{ text.desc }}
                </ion-button>
              </ng-container>
  
              <!-- <ion-button (click)="generateMode = true;" fill="outline" color="light">
                Generate 
                <ion-icon name="chevron-forward-outline" slot="end"></ion-icon>
              </ion-button> -->
              
  
            </div>
          </div>

        </ng-container>



      </div>


      <div data-screen="home" *ngIf="(brainstorming.isState(1))">

        <div *ngIf="brainstorming && brainstorming.ui && brainstorming.ui.setup_array">

          <h3 [ngClass]="{ 'setup-lock' : (brainstorming.contextPayload.mode === 'generate')  }">

            <ng-container *ngFor="let item of brainstorming.ui.setup_array">
              <ng-container *ngIf="(item !== '[[cat]]' && item !== '[[step]]')">{{ item }}&nbsp;</ng-container>
              <ng-container *ngIf="(item === '[[cat]]')">

                <ng-container *ngIf="brainstorming.contextPayload.mode === 'edit'">
                  Edit Content&nbsp;
                </ng-container>

                

                <ng-container *ngIf="brainstorming.contextPayload.mode !== 'edit'">
                  <ion-input class="intext"  (ionChange)="blurSetup('cat', $event)" [(ngModel)]="brainstorming.ui.selectedCategory"  [customDropdown]="{
                    multiple: false,
                    options: brainstorming.ui.categoryOptions
                  }"></ion-input>
                </ng-container>

              </ng-container>
              <ng-container *ngIf="(item === '[[step]]')">
                <ion-input class="intext"  (ionChange)="blurSetup('step', $event)" [(ngModel)]="brainstorming.ui.selectedStep"  [customDropdown]="{
                  multiple: false,
                  options: brainstorming.ui.stepOptions
                }"></ion-input>
              </ng-container>
            </ng-container>
          
          </h3>
  
        </div>

        <!-- <br/> -->

        <div class="quick-actions">
          <small>{{ brainstorming.ui.launch_state || '...' }}</small>

          <!-- <br/><br/> -->

          <div class="btns-wrapper">

            <ng-container *ngIf="brainstorming.contextPayload.mode !== 'edit'">
              <ion-button (click)="brainstorming.setState(0)" fill="outline" color="light">
                <ion-icon name="chevron-back-outline" slot="icon-only"></ion-icon>
              </ion-button>
            </ng-container>

            <ng-container *ngIf="this.brainstorming.ui.ai_permissions">

              <ng-container *ngFor="let item of this.brainstorming.ui.prompts">

                <ng-container *ngIf="brainstorming.contextPayload.mode === 'edit'">
                  <ion-button [disabled]="( !textareaValue)" (click)="processQuery({ 'prompt_slug' : item.slug, 'custom': textareaValue  })" fill="outline" color="light">
                    {{ item.title }}
                  </ion-button>
                </ng-container>

                <ng-container *ngIf="brainstorming.contextPayload.mode === 'generate'">
                  <ion-button (click)="processQuery({ 'prompt_slug' : item.slug })" fill="outline" color="light">
                    {{ item.title }}
                  </ion-button>
                </ng-container>


              </ng-container>
            </ng-container>

            <!-- <ion-button (click)="generateMode = true;" fill="outline" color="light">
              Generate 
              <ion-icon name="chevron-forward-outline" slot="end"></ion-icon>
            </ion-button> -->

            <!--
            <ion-button (click)="brainstorming.setState(0)" fill="outline" color="light">
              Generate 
              <ion-icon name="chevron-forward-outline" slot="end"></ion-icon>
            </ion-button>
            -->
            

          </div>
        </div>

      </div>


      <div data-screen="prompts" style="padding-bottom: 48px;" *ngIf="(brainstorming.isState(2))">

        <div class="results">

          <div class="results-box" *ngIf="!brainstorming.generateProcessing && (brainstorming.ui.results)">
            <span class="magic-action" (click)="presentResultOptionsPopover($event)"><ion-icon name="color-wand-outline"></ion-icon> Results</span>



            <div class="switch-navigation">

              <ion-button fill="clear" [disabled]="!brainstorming.isCanMoveResultPointer(-1)"
              (click)="brainstorming.moveResultPointer(-1)"
              >
                <ion-icon name="chevron-back-outline"></ion-icon>
              </ion-button>

              <ion-button fill="clear" [disabled]="!brainstorming.isCanMoveResultPointer(+1)"
              (click)="brainstorming.moveResultPointer(+1)"
              >
                <ion-icon name="chevron-forward-outline"></ion-icon>
              </ion-button>

            </div>

            <div class="breadcrumbs" *ngIf="brainstorming.contextPayload.breadcrumbs">
              <!--
              <span [(click)="brainstorming.setState(1)">{{ brainstorming.contextPayload.selectedCategory }}</span> /
              <span (click)="brainstorming.setState(1)">Step {{ brainstorming.contextPayload.selectedStep }}</span> /
              <span (click)="brainstorming.setState(2)">{{ brainstorming.contextPayload.selectedPromptCategory }}</span> /
              <b>prompt</b>
              -->
              <!-- Course / Profile Polisher Step / Hooks & Headlines / Actions -->
              <span [innerHtml]="brainstorming.contextPayload.breadcrumbs"></span>

            </div>
          </div>



          <!-- <br/><br/> -->

          <div data-screen="loading" *ngIf="brainstorming.generateProcessing">
        
            <!-- <p>processing...</p> -->
            <!-- <br/><br/> -->
            <div class="ai-bar">
              <div class="icon"><ion-icon color="secondary" name="color-wand-outline"></ion-icon></div>
              <div class="ai-interact-bar-text">
                <ion-text color="secondary">AI is generating...</ion-text>
                <gw-bubbles-spinner></gw-bubbles-spinner>
              </div>
              <div class="send">
                <ion-button color="medium" size="small" fill="clear"
                (click)="stopProcessing()"
                >
                  Stop Generating
                  <ion-icon slot="end" name="close-outline"></ion-icon>
                </ion-button>
              </div>
            </div>
  
          </div>

          
          <ng-container *ngIf="!brainstorming.generateProcessing && (!brainstorming.selectedResult() || (brainstorming.selectedResult() && !brainstorming.selectedResult().result.length))">


            <br/><br/><br/><br/><br/>

            <brainstorming-no-results></brainstorming-no-results>

            <br/>

            <div class="prompt-btns" style="text-align: center;" *ngIf="(!brainstorming.ui.results.length)">
      
              <ng-container *ngIf="this.brainstorming.ui.ai_permissions">
                <ion-button [disabled]="brainstorming.generateProcessing" (click)="brainstorming.setState(1)" fill="outline" color="light">
                  <ion-icon name="chevron-back-outline" slot="icon-only"></ion-icon>
                </ion-button>
                <ng-container *ngFor="let item of this.brainstorming.ui.prompts">
                  <ion-button (click)="processQuery({ 'prompt_slug' : item.slug })" fill="outline" color="light">
                    {{ item.title }}
                  </ion-button>
                </ng-container>
              </ng-container>
              
            </div>

            <br/>

          </ng-container>

          <div class="scrollable-content" *ngIf="(brainstorming.isState(2) && !brainstorming.generateProcessing)">

            <ng-container *ngIf="brainstorming.selectedResult() && brainstorming.selectedResult().result">
              <ng-container *ngFor="let text of brainstorming.selectedResult(true); let i = index">

                <div class="res-wrapper" (click)="copyResult(text)">
                  <!-- (click)="brainstorming.setState(0);" -->
                  <span>{{i + 1}}</span>
                  <!-- <div> -->
                    <!-- <ion-button fill="clear"><ion-icon name="bookmark-outline"></ion-icon></ion-button> -->
                    <img src="assets/v2/icons/bookmark.png">
                    <div class="results">{{ text }}</div>
                  <!-- </div> -->
                </div>
  
              </ng-container>
            </ng-container>

            

          </div>

        </div>

      </div>

    </div>

    <div id="prompt">

      <div class="prompt-btns"  *ngIf="brainstorming.isState(2) && (brainstorming.ui && brainstorming.ui.results && brainstorming.ui.results.length)">
        
        <ion-button [disabled]="brainstorming.generateProcessing" (click)="backClear()" fill="outline" color="light">
          <ion-icon name="chevron-back-outline" slot="icon-only"></ion-icon>
        </ion-button>


          <ng-container *ngFor="let item of this.brainstorming.ui.prompts">
            <ion-button [disabled]="brainstorming.generateProcessing" (click)="processQuery({ 'prompt_slug' : item.slug })" fill="outline" color="light">
              {{ item.title }}
            </ion-button>
          </ng-container>

        
      </div>

      <div class="prompt-area" [ngClass]="{ 'non-interactive' : brainstorming.generateProcessing }">
        <ion-textarea 
          auto-grow="true" 
          [placeholder]=" 
          
          (brainstorming.isState(-1)) ? '|or start with custom prompt...' : 
          (brainstorming.isState(1) || brainstorming.isState(0)) ? 
            ((brainstorming.contextPayload && brainstorming.contextPayload.mode === 'edit') ? 
            '|add copy to use our prompts or type custom prompt...' : '|add context to improve results or type custom prompt...') :
          '|or type your custom prompt...'

          " 
          rows="1" 
          spellcheck="true" 
          [(ngModel)]="textareaValue"
        >
        </ion-textarea>
        <ion-button class="magic-button" [disabled]="!textareaValue" (click)="processQuery({ 'custom' : textareaValue }, true)" size="medium"><ion-icon name="color-wand-outline"></ion-icon></ion-button>
      </div>

      <!--
      
      State (0) - Setup -  |or start with custom prompt...

      State (1) - Edit - |add copy to use our prompts or type custom prompt...
      State (1) - Generate - |add context to improve results or type custom prompt...

      State (2) - Results - |or type your custom prompt...
      -->

      <!--
      <div class="switch-mode">
        <ion-button (click)="setMode('generate')" color="light" *ngIf="this.brainstorming.contextPayload.mode === 'edit'">
          <ion-icon name="chevron-back-outline"></ion-icon>
          Generate
        </ion-button>

        <ion-button  (click)="setMode('edit')" color="light" *ngIf="this.brainstorming.contextPayload.mode === 'generate'">
          <ion-icon name="chevron-back-outline"></ion-icon>
          Edit
        </ion-button>
      </div>
      -->

    </div>

    <div class="switch-mode" *ngIf="!brainstorming.isState(-1) && !brainstorming.isState(2)" [ngClass]="{ 'non-interactive' : brainstorming.generateProcessing }">
      <div class="mode" (click)="setMode('generate')" *ngIf="this.brainstorming.contextPayload.mode === 'edit'">

        <div class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.52448 1.04518C8.21868 1.29262 7.78152 1.29262 7.47571 1.04518C6.80733 0.511795 5.91275 0.356864 5.10391 0.634415C4.29508 0.911967 3.68411 1.58352 3.48405 2.41492C3.4133 2.7384 3.16003 2.99069 2.83628 3.06019C1.88452 3.25336 1.09124 3.90741 0.720177 4.80489C0.349109 5.70238 0.448889 6.72566 0.986344 7.53455C1.18001 7.81811 1.18001 8.1914 0.986344 8.47495C0.448889 9.28384 0.349109 10.3071 0.720177 11.2046C1.09124 12.1021 1.88452 12.7561 2.83628 12.9493C3.16003 13.0188 3.4133 13.2711 3.48405 13.5946C3.6852 14.4253 4.29661 15.0957 5.10528 15.3723C5.91395 15.649 6.8079 15.4935 7.47571 14.9601C7.78152 14.7127 8.21868 14.7127 8.52448 14.9601C9.19345 15.4935 10.0887 15.6479 10.8977 15.3695C11.7067 15.091 12.3172 14.4184 12.5161 13.5862C12.5869 13.2628 12.8402 13.0105 13.1639 12.941C14.1158 12.7477 14.9091 12.0934 15.28 11.1957C15.6509 10.298 15.5508 9.27459 15.013 8.46578C14.8201 8.1826 14.8201 7.81023 15.013 7.52705C15.5508 6.71823 15.6509 5.69481 15.28 4.79711C14.9091 3.89941 14.1158 3.24514 13.1639 3.05185C12.8402 2.98236 12.5869 2.73007 12.5161 2.40659C12.315 1.57591 11.7036 0.905466 10.8949 0.628816C10.0862 0.352165 9.19229 0.507617 8.52448 1.04102V1.04518Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.00009 1.22852L8.00009 14.77" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.5012 11.3353C10.5012 10.4145 11.2477 9.66797 12.1686 9.66797" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5.49915 4.66602C5.49915 5.58687 4.75265 6.33338 3.83179 6.33338" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5.49915 11.3353C5.49915 10.4145 4.75265 9.66797 3.83179 9.66797" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.5012 4.66602C10.5012 5.58687 11.2477 6.33338 12.1686 6.33338" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

          <svg class="hover"  xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.52439 1.0446C8.21858 1.29204 7.78143 1.29204 7.47562 1.0446C6.80723 0.511215 5.91266 0.356284 5.10382 0.633835C4.29499 0.911387 3.68402 1.58294 3.48396 2.41434C3.41321 2.73782 3.15994 2.99011 2.83619 3.05961C1.88443 3.25278 1.09115 3.90683 0.720085 4.80431C0.349018 5.7018 0.448797 6.72508 0.986252 7.53397C1.17992 7.81753 1.17992 8.19082 0.986252 8.47437C0.448797 9.28326 0.349018 10.3065 0.720085 11.204C1.09115 12.1015 1.88443 12.7556 2.83619 12.9487C3.15994 13.0182 3.41321 13.2705 3.48396 13.594C3.68511 14.4247 4.29652 15.0951 5.10519 15.3718C5.91386 15.6484 6.80781 15.493 7.47562 14.9596C7.78143 14.7121 8.21858 14.7121 8.52439 14.9596C9.19336 15.493 10.0886 15.6474 10.8976 15.3689C11.7066 15.0905 12.3171 14.4178 12.5161 13.5857C12.5868 13.2622 12.8401 13.0099 13.1638 12.9404C14.1157 12.7471 14.909 12.0928 15.2799 11.1951C15.6509 10.2974 15.5508 9.27402 15.0129 8.4652C14.82 8.18202 14.82 7.80965 15.0129 7.52647C15.5508 6.71765 15.6509 5.69423 15.2799 4.79653C14.909 3.89883 14.1157 3.24456 13.1638 3.05127C12.8401 2.98178 12.5868 2.72949 12.5161 2.40601C12.3149 1.57533 11.7035 0.904887 10.8948 0.628236C10.0862 0.351585 9.1922 0.507037 8.52439 1.04044V1.0446Z" fill="white"/>
            <path d="M8 1.22885L8 14.7703" stroke="#1A1A1A" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.501 11.3347C10.501 10.4139 11.2475 9.66736 12.1684 9.66736" stroke="#1A1A1A" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5.49897 4.66528C5.49897 5.58614 4.75246 6.33264 3.8316 6.33264" stroke="#1A1A1A" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5.49897 11.3347C5.49897 10.4139 4.75246 9.66736 3.8316 9.66736" stroke="#1A1A1A" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.501 4.66528C10.501 5.58614 11.2475 6.33264 12.1684 6.33264" stroke="#1A1A1A" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>

        <div class="text">
          Generate Content
        </div>



      </div>

      <div class="mode" (click)="setMode('edit')" *ngIf="this.brainstorming.contextPayload.mode === 'generate'">

        <div class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.41091 10.0157L10.0159 1.41074C10.3409 1.08574 10.8684 1.08574 11.1934 1.41074L12.5901 2.80741C12.9151 3.13241 12.9151 3.65991 12.5901 3.98491L3.98425 12.5891C3.82841 12.7457 3.61675 12.8332 3.39591 12.8332H1.16675L1.16675 10.6041C1.16675 10.3832 1.25425 10.1716 1.41091 10.0157Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.4585 2.9668L11.0335 5.5418" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

          <svg class="hover" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.41091 10.0167L10.0159 1.41172C10.3409 1.08672 10.8684 1.08672 11.1934 1.41172L12.5901 2.80839C12.9151 3.13339 12.9151 3.66089 12.5901 3.98589L3.98425 12.5901C3.82841 12.7467 3.61675 12.8342 3.39591 12.8342H1.16675L1.16675 10.6051C1.16675 10.3842 1.25425 10.1726 1.41091 10.0167Z" fill="white" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.4585 2.9668L11.0335 5.5418Z" fill="white"/>
            <path d="M8.4585 2.9668L11.0335 5.5418" stroke="#1A1A1A" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>

        <div class="text">
          Edit Content
        </div>



      </div>
    </div>

    <div id="footer">

      <div class="l-box">
        <ion-icon (click)="(this.auth && this.auth.data && this.auth.data.is_superstaff) ? (toggleDebug = !toggleDebug) : null" name="alert-circle-outline"></ion-icon> 
        AI responses can be inaccurate or misleading.&nbsp;

      </div>

      <div tooltipComponent="large" [tooltipWidth]="200" [tooltip]="'The system uses credits to measure fair usage. A full campaign strategy and product roadmap including all of your assets is ~15,000 credits.'" class="r-box credit-counter" *ngIf="brainstorming.ui  && brainstorming.ui.ai_permissions && brainstorming.ui.ai_permissions.ai_available_limit">
        <span>Credits Available:&nbsp;</span>
        <app-animated-counter [count]="brainstorming.ui.ai_permissions.ai_available_limit"></app-animated-counter>
        <ion-icon name="information-circle-outline"></ion-icon>
      </div>

    </div>

  </div>

</div>