<ion-fab vertical="top" horizontal="end" slot="fixed">
  <ion-fab-button size="small" (click)="dismiss()">
   <ion-icon name="close"></ion-icon>
  </ion-fab-button>
</ion-fab>

<ion-content class="ion-padding" color="white">

  <div class="box">

    <div class="ion-text-start ion-float-left information">
      <img src="assets/v2/icons/information.png">
      <b>
        AI CREDITS LIMIT REACHED!
      </b>
      <div class="text">
        <!-- <div class="ion-margin-bottom">Hey there, we noticed you have not completed your planning phase yet.</div> -->
        <div>As part of the beta program, you were given an allotment of credits to test the tool and provide feedback. Those credits have been used. To continue using the AI generation engine you need to top up your credit balance.</div>
      </div>
    </div>
    <!--
    <div class="alert">
      <div class="box">
        <img src="assets/v2/icons/alert.png">
        <p>
          If you would like to continue testing the AI tool and providing feedback please contact support@growthworks.ai subject line “I want more credits”
        </p>
      </div>
    </div>
    -->
  </div>

</ion-content>

<ion-footer>

  <ion-button
  
  tracking="ai_buy_credits_popup_btn_limit_reached"
  [trackingData]="{ 
    event_category: 'Generate AI', 
    event_label: 'Location: Location: AI Credits Limit Reached Popup'
  }"
  
  size="large" color="primary" (click)="ok()">
    Buy More Credits Now
    <!-- <ion-icon slot="end" name="chevron-forward-outline"></ion-icon> -->
  </ion-button>

  <ion-button size="large" color="dark" fill="outline" (click)="dismiss()">Cancel</ion-button>

</ion-footer>
