import { Component, OnInit, ViewChild, ElementRef, HostListener, Input, AfterViewInit } from '@angular/core';

@Component({
  selector: 'gw-ai-blob',
  templateUrl: './ai-blob.component.html',
  styleUrls: ['./ai-blob.component.scss'],
})
export class AiBlobComponent implements OnInit, AfterViewInit {

  @ViewChild('video1', { static: false }) video1: ElementRef<HTMLVideoElement>;
  @ViewChild('video2', { static: false }) video2: ElementRef<HTMLVideoElement>;

  animationFrameRef: any;

  retryAutoplay: any = null;

  // @Input() public quality = 'hq'; // 'lq'

  @Input() public processing: boolean;

  @Input() public quality: string;

  @Input() public noBorder: boolean = false;

  constructor(
    private elRef:ElementRef
  ) { }

  ngOnInit() {
    // Ensure Video is playing

  }

  ngAfterViewInit() {
    this.video1.nativeElement.play().then(_ => {
      this.video2.nativeElement.playbackRate = 3.0;
      this.video2.nativeElement.play();
      // Autoplay started!a
      if ( this.retryAutoplay ) {
        clearTimeout(this.retryAutoplay);
      }
    }).catch(error => {
      // Autoplay was prevented.
      // Show a "Play" button so that user can start playback.
      this.retryAutoplay = setTimeout( () => {
        this.ngAfterViewInit();
      }, 1000);
    });
  }

}
