<ion-fab class="navigation-back-wizard">
  <ion-fab-button color="light" (click)="dismiss()">
    <ion-icon *ngIf="!this.modalRef.loading" name="arrow-back-outline"></ion-icon>
    <ion-icon *ngIf="!!this.modalRef.loading" name="close-outline"></ion-icon>
  </ion-fab-button>
</ion-fab>

<ion-content [ngClass]="{ 'animate' : this.modalRef.loading }">

  <div class="content-backdrop"></div>

  <div class="content-wrapper">

  <div class="content-column">

    <div class="content">
      <ng-content></ng-content>
    </div>

  </div>

  <div class="blob-column">


    <gw-ai-assistant *ngIf="modalRef"

    #assistantBubble 

    [tipColor]="modalRef.loading" 
    
    direction="down" 
    quality="lq" 
    [processingText]="modalRef.currentLoadingPhrase()" 
    [processing]="modalRef.loading" 

    ></gw-ai-assistant>


  </div>

  </div>

</ion-content>
