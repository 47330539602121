import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthPage } from './auth.page';

const routes: Routes = [
  {
    path: '',
    component: AuthPage,
    children: [
      {
        path: 'login',
        loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
      },
      {
        path: 'forgot',
        loadChildren: () => import('./forgot/forgot.module').then( m => m.ForgotPageModule)
      },
      {
        path: 'forgot/sent',
        loadChildren: () => import('./forgot-sent/forgot-sent.module').then( m => m.ForgotSentPageModule)
      },
      {
        path: 'forgot/reset/:token/:uid',
        loadChildren: () => import('./forgot-reset/forgot-reset.module').then( m => m.ForgotResetPageModule)
      },
      {
        path: 'forgot-password/:token/:uid',
        loadChildren: () => import('./forgot-reset/forgot-reset.module').then( m => m.ForgotResetPageModule)
      },
      {
        path: 'register',
        loadChildren: () => import('./register/register.module').then( m => m.RegisterPageModule)
      },
      {
        path: 'register/sent',
        loadChildren: () => import('./register-sent/register-sent.module').then( m => m.RegisterSentPageModule)
      },
      {
        path: 'logout',
        loadChildren: () => import('./logout/logout.module').then( m => m.LogoutPageModule)
      },
      {
        path: 'tos',
        loadChildren: () => import('./tos/tos.module').then( m => m.TosPageModule)
      },
      {
        path: 'privacy',
        loadChildren: () => import('./privacy/privacy.module').then( m => m.PrivacyPageModule)
      },

      // After Login Screens
      {
        path: 'suspended',
        loadChildren: () => import('./suspended/suspended.module').then( m => m.SuspendedPageModule)
      },
      {
        path: 'locked', // restrict access related to billing issues
        loadChildren: () => import('./suspended-billing/suspended.module').then( m => m.SuspendedPageModule)
      },
      {
        path: 'wander',
        loadChildren: () => import('./wander/wander.module').then( m => m.WanderPageModule)
      },

      // Integrations universal callback
      {
        path: 'callback',
        loadChildren: () => import('./callback/universal-callback.module').then( m => m.CallbackModule)
      },
    ]
  },
  {
    path: 'upgrade',
    loadChildren: () => import('./upgrade/upgrade.module').then( m => m.UpgradePageModule)
  },
  {
    path: 'onboard',
    loadChildren: () => import('./onboard/onboard.module').then( m => m.OnboardPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule],
})
export class AuthPageRoutingModule {}
