<div class="btn" (click)="toggle()" *ngIf="!isToggled">
  <ion-img src="/assets/v2/blocks/chat-bar-block.png"></ion-img>
</div>


<div class="working-frame" *ngIf="hydrated" [ngClass]="{'toggle': isToggled}">


  <div class="header">

    <!--
    {{ this.auth.data.is_pro }}
    {{ this.dataSource.isCoaching }}
    {{ coachId }}
    {{ clientId }}
    -->

<!--{{ 'this.auth.data.is_pro' }} {{ this.auth.data.is_pro }} {{ 'this.dataSource.isCoaching' }} {{ this.dataSource.isCoaching }}-->

    <ion-row class="ion-justify-content-center ion-padding-top" *ngIf="coachId">
      <ion-col size="2" class="ion-text-center ion-align-self-center chat-icon" (click)="coachId=null">
        <ion-icon name="arrow-back-outline"></ion-icon>
      </ion-col>
      <ion-col>

        <img [src]="this.formProcessor.renderAvatar(coach.avatar)">

        <h3>{{ coach.first_name }} {{ coach.last_name }}</h3>

      </ion-col>
      <ion-col size="2" class="ion-text-center ion-align-self-center" (click)="toggle()"><ion-icon [name]="(isToggled)?'chevron-down-outline':'chevron-up-outline'"></ion-icon></ion-col>
    </ion-row>

    <ion-row class="ion-justify-content-center ion-padding-top" *ngIf="!coachId" (click)="toggle()">
      <ion-col size="2" class="ion-text-center ion-align-self-center chat-icon">
        <ion-icon name="chatbox-ellipses-outline"></ion-icon>
      </ion-col>
      <ion-col><h3>Coaching <span *ngIf="totalUnreads" class="unreads-top">{{ totalUnreads }}</span></h3></ion-col>
      <ion-col size="2" class="ion-text-center ion-align-self-center"><ion-icon [name]="(isToggled)?'chevron-down-outline':'chevron-up-outline'"></ion-icon></ion-col>
    </ion-row>

  </div>

  <div class="content" *ngIf="isToggled && (!coachId && !clientId)">

    <div class="coach-box">

      <div class="list-item">
        <ion-list lines="none" *ngFor="let coach of coachList;">

          <ion-item>
            <ion-avatar class="cdx" slot="start">
              <img  [src]="this.formProcessor.renderAvatar(coach.avatar)">
            </ion-avatar>
            <ion-label>
              <b>{{ coach.first_name }} {{ coach.last_name }}</b><br/>
              <p class="cvx" *ngIf="coach.bio">{{ coach.bio }}</p>
              <ion-button class="str-c" mode="ios" (click)="setCoach(coach)">Start a conversation</ion-button>
            </ion-label>
          </ion-item>

          <small><ion-text color="medium">Campaigns</ion-text></small>
          <br/><br/>

          <div class="project-trigger" *ngFor="let cmp of filterCampaings(coach.campaigns);" (click)="openProject(cmp, coach)">
            <div class="round" *ngIf="cmp.unread"> {{ cmp.unread }}</div> &nbsp; &nbsp; {{ cmp.name }}
          </div>

          <div *ngIf="!filterCampaings(coach.campaigns).length">No new message</div>

        </ion-list>
      </div>


      <br/><br/><br/><br/><br/><br/>

    </div>

  </div>

  <div class="content" *ngIf="isToggled && (coachId || clientId)">

    <!--
    <div class="campaing-selector">
      <ion-select [interfaceOptions]="{ header: 'Select Campaing' }" okText="Select" cancelText="Dismiss" (ionChange)="changeProject($event)" [value]="dataSource.coachingData.projectId">
        <ion-select-option *ngFor="let item of campaingList; let i = index;" [value]="''+item.id">Campaign: {{ item.title }}</ion-select-option>
      </ion-select>
    </div>
    -->

    <div class="campaing-selector-v2" (click)="presentPopover($event)">
      <div class="iinner" *ngIf="dataSource.coachingData && dataSource.coachingData.projectId && campaingList">
        <ion-row>
          <ion-col size="10"><b>Campaign:</b> {{ activeCampaing() }}</ion-col>
          <ion-col size="2" class="ion-text-right"><ion-icon name="chevron-down-outline"></ion-icon></ion-col>
        </ion-row>
      </div>
    </div>

    <ion-content class="ch" #chat [scrollEvents]="true"
      (ionScroll)="logScrollingChat($event)">
      <div class="chat-box">

        <ng-container *ngFor="let item of messages; let i = index;">

          <div class="date-stamp" *ngIf="i % 3 === 0">
            {{ item.created_on | amDateFormat:'LL' }}
          </div>

          <div class="chat-instance" [ngClass]="{'isMy': !item.is_my_message}">
            <div class="avatar" [ngStyle]="{'background-image': 'url(' + this.formProcessor.renderAvatar(item.author.avatar) + ')'}"></div>
            <div class="message-container">
              <div class="action-tag" *ngIf="item.tag">
                {{ item.tag.frontend_tag }}
              </div>
              <div class="message-box">
                <b *ngIf="!item.is_my_message && item.author">{{ item.author.name }}<br/></b>
                <div class="date">
                  {{ item.created_on | amDateFormat:'h:mm A' }}
                </div>
                <div class="message" [innerHtml]="item.text">

                </div>
              </div>
            </div>
          </div>

        </ng-container>

      </div>
    </ion-content>

    <!--{{ this.dataSource.coachingData | json }}-->

    <div class="current-tag" *ngIf="dataSource.coachingData && dataSource.coachingData.tag">
      <div class="action-tag">
        {{ dataSource.coachingData.tag }}
      </div>
    </div>

    <editor class="tce"
      *ngIf="tinyMce"
      [(ngModel)]="message"
      #chatEditor
      [inline]="false"
      [init]="{
        base_url: '/tinymce',
        suffix: '.min',
        skin: 'oxide',
        height: 140,
        placeholder: 'Start typing here',
        menubar: false,
        extended_valid_elements : 'tip[data-tip],span,highlight,b,i,u,span[style],p',
        custom_elements : 'tip,highlight',
        plugins: [
          'autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount emoticons'
        ],
        toolbar:
          'bold italic numlist bullist blockquote emoticons '
      }"
    ></editor>

    <ion-button class="send snd-c" size="small" color="primary" *ngIf="tinyMce" mode="ios" (click)="sendMessage()">Send</ion-button>

    <div class="ion-padding ion-text-center">
      <ion-spinner name="dots"></ion-spinner>
    </div>

  </div>

</div>
