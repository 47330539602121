import { Component, OnInit, Input } from '@angular/core';
import { PopoverController, LoadingController } from '@ionic/angular';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

import { DataSourceService } from '@services/data-source.service';
import { FormProcessorService } from '@services/form-processor.service';

@Component({
  selector: 'app-asset-card-popover',
  templateUrl: './asset-card.popover.html',
  styleUrls: ['./asset-card.component.scss'],
})
export class AssetCardComponentPopover {

  @Input() item;;

  constructor(
    public popoverController: PopoverController,
    public router: Router,
    public route: ActivatedRoute,
    // public formProcessor: FormProcessorService,
  ) {}

  public dismiss(index) {
    return this.popoverController.dismiss({ index });
  }
}

@Component({
  selector: 'app-asset-card',
  templateUrl: './asset-card.component.html',
  styleUrls: ['./asset-card.component.scss'],
})
export class AssetCardComponent implements OnInit {



  @Input() item;;

  constructor(
    public popoverController: PopoverController,
    public router: Router,
    public route: ActivatedRoute,
    public dataSource: DataSourceService,
    public formProcessor: FormProcessorService,
    public loadingController: LoadingController,
  ) {

  }

  async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: AssetCardComponentPopover,
      cssClass: 'application-v2 popover-v2 assets-popover',
      event: ev,
      showBackdrop: false,
      componentProps: {
        item: this.item
      }
      // translucent: true,
    });
    await popover.present();

    const { data } = await popover.onDidDismiss();
    // console.log('onDidDismiss resolved with role', role);
    if ( data && data.index ) {
      console.log( data );

      if ( data.index === 1 ) {
        this.formProcessor.mediateOpenScript(this.item);
      }

      if ( data.index === 2 ) {
        this.formProcessor.mediatePresentCloneConfirm(this.item.id, this.item);
      }

      if ( data.index === 3 ) {
        this.formProcessor.mediatePresentDeleteConfirm(this.item.id);
      }

      if ( data.index === 4 ) {
        this.formProcessor.mediatePresentRecreateAsset(this.item.id, this.item);
      }

      if ( data.index === 5 ) {
        this.formProcessor.mediatePublishUnpublish(this.item.id, !this.item.is_published, this.item);
        this.item.is_published = !this.item.is_published;
      }

      if ( data.index === 6 ) {
        this.formProcessor.mediateRenameAsset(this.item.id, this.item);
        // this.item.is_published = !this.item.is_published;
      }


      /*
      <ion-button size="small" color="dark" fill="clear" expand="block" (click)="presentCloneConfirm(script.id, script)">Clone</ion-button>
      <ion-button size="small" color="dark" fill="clear" expand="block" (click)="openScript(script)">Edit</ion-button>
      <ion-button size="small" color="dark" fill="clear" expand="block" (click)="presentDeleteConfirm(script.id)">Delete</ion-button>

      */
    }
  }

  async openAsset() {
    console.log('openAsset', this.item);

    this.formProcessor.mediateOpenScript(this.item);

  }

  ngOnInit() {}

}
