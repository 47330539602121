<ion-header>
  <ion-toolbar>
    <ion-title>GrowthWorks Design Map Showcase</ion-title>
    <ion-buttons slot="primary">
      <ion-button color="dark" (click)="modalController.dismiss()">
        <ion-icon slot="icon-only" name="happy-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="application-v2">

  <ion-card class="ion-padding">

    <ion-button (click)="presentLoadingModal()">Present Loading Modal</ion-button>
    <ion-button [routerLink]="['/app/wow']">Goto WOW Pages Showcase</ion-button>
    <ion-button (click)="testUpdateStack()">Present App Update</ion-button>

    <ion-select [(ngModel)]="prewiewComplexDropdown">
      
    </ion-select>

    <ion-row>

      <ion-col *ngFor="let interface of ['alert', 'popover', 'action-sheet']">
        <h5>{{ interface }}</h5>
        <ion-item>
          <ion-label>Single</ion-label>
          <ion-select class="form" [interface]="interface" multiple="false" [value]="'xcheese'" cancelText="Nah" okText="Okay!">
            <ion-select-option value="bacon">Bacon</ion-select-option>
            <ion-select-option value="olives">Black Olives</ion-select-option>
            <ion-select-option value="xcheese">Extra Cheese</ion-select-option>
            <ion-select-option value="peppers">Green Peppers</ion-select-option>
            <ion-select-option value="mushrooms">Mushrooms</ion-select-option>
            <ion-select-option value="onions">Onions</ion-select-option>
            <ion-select-option value="pepperoni">Pepperoni</ion-select-option>
            <ion-select-option value="pineapple">Pineapple</ion-select-option>
            <ion-select-option value="sausage">Sausage</ion-select-option>
            <ion-select-option value="Spinach">Spinach</ion-select-option>
          </ion-select>
        </ion-item>
      
        <ion-item>
          <ion-label>Multiple</ion-label>
          <ion-select class="form" [interface]="interface" multiple="true" [value]="['bird', 'dog']">
            <ion-select-option value="bird">Bird</ion-select-option>
            <ion-select-option value="cat">Cat</ion-select-option>
            <ion-select-option value="dog">Dog</ion-select-option>
            <ion-select-option value="honeybadger">Honey Badger</ion-select-option>
          </ion-select>
        </ion-item>
      </ion-col>

      <ion-col>
        <h5>Custom</h5>


        
          <ion-label>Single ({{ prewiewComplexDropdown | json }})</ion-label>
          <ion-input class="form"
          placeholder="Search..."
          [customDropdown]="{
            multiple: false,
            search: true,
            searchPlaceholder: 'Search',
            cssClass: 'hello-world',
            popoverClass: 'helloworld-popover second-class',

            options: [
              { value: 'bird', label: 'Bird' },
              { value: 'cat', label: 'Cat' },
              { value: 'dog', label: 'Dog', badge: '11' },
              { value: 'honeybadger', label: 'Honey Badger' }
            ]
          }" 
          [(ngModel)]="prewiewComplexDropdown"
          
          cancelText="Nah" okText="Okay!">

          </ion-input>

          <ion-input class="form"
          placeholder="lookup..."
          [customDropdown]="{
            multiple: false,
            search: true,
            cssClass: 'hello-world',
            popoverClass: 'helloworld-popover second-class',
            displayValue: 'Hello',

            options: [
              { value: 'bird', label: 'Bird' },
              { value: 'cat', label: 'Cat' },
              { value: 'dog', label: 'Dog', badge: '11' },
              { value: 'honeybadger', label: 'Honey Badger' }
            ]
          }" 
          [(ngModel)]="prewiewComplexDropdown1"
          
          cancelText="Nah" okText="Okay!">

          </ion-input>

      

          <ion-label>Multiple ({{ prewiewComplexMultiDropdown | json }})</ion-label>
          <ion-input class="form"
          [customDropdown]="{
            multiple: true,
            search: true,

            options: [
              {
                group: {
                  label: 'Recommended',
                  icon: 'star',
                  options: [
                    { icon: 'earth', value: 'bird', label: 'Bird', badge: '11' },
                    { value: 'cat', label: 'Cat', badge: '12' },
                    { value: 'dog', label: 'Dog', badge: '99' },
                    { value: 'honeybadger', label: 'Honey Badger' }
                  ]
                }
              },
              {
                group: {
                  label: 'Not Recommended',
                  options: [
                    { value: 'bird', label: 'Bird', badge: '11' },
                    { value: 'cat', label: 'Cat', badge: '12' },
                    { value: 'dog', label: 'Dog', badge: '99' },
                    { value: 'honeybadger', label: 'Honey Badger' }
                  ]
                }
              }
            ]
          }" 
          [(ngModel)]="prewiewComplexMultiDropdown">

          </ion-input>

      </ion-col>

      
    </ion-row>


  </ion-card>

  <ion-card>

    <ion-card-header (click)="menuToggle[0] = !menuToggle[0]">
      <!--<ion-card-subtitle>Card Subtitle</ion-card-subtitle>-->
      <ion-card-title>Colors <ion-icon class="ion-float-right" name="color-palette-outline"></ion-icon></ion-card-title>
    </ion-card-header>
    <!-- Colors -->
    <ion-card-content *ngIf="menuToggle[0]">

      <ion-list>
        <ion-item *ngFor="let item of this.colors">
          <ion-label>
            <b class="ion-text-capitalize">{{ item.key }}</b>
            <div *ngFor="let variation of this.colorsVariations">
              --ion-color-{{ item.key + variation }}:
              <b>{{ fetchDocumentValue(this.colors, '--ion-color-' + item.key + variation) }}</b>
            </div>
          </ion-label>
          <ion-label style="width: 60%;" slot="end">
            <b class="ion-text-capitalize">Pallet</b>
            <div style="background-color: var({{ '--ion-color-' + item.key + variation }}); color: var({{ '--ion-color-' + item.key + '-contrast' }});" *ngFor="let variation of this.colorsVariations">
              This text is colored in `<b>{{ '--ion-color-' + item.key + '-contrast' }} : {{ fetchDocumentValue(this.colors, '--ion-color-' + item.key + '-contrast') }}</b>` color
            </div>
          </ion-label>
        </ion-item>
      </ion-list>

      <ion-list>
        <ion-item *ngFor="let item of [50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650, 700, 750, 800, 850, 900, 950]">
          <ion-label>
            <b class="ion-text-capitalize">{{ item }}</b>
            <div>
              --ion-color-step-{{ item }}:
              <b>{{ fetchDocumentValue(this.colors, '--ion-color-step-' + item) }}</b>
            </div>
          </ion-label>
          <ion-label style="width: 60%;" slot="end">
            <b class="ion-text-capitalize">Pallet</b>
            <div style="background-color: var({{ '--ion-color-step-' + item }});">
              {{ fetchDocumentValue(this.colors, '--ion-color-step-' + item) }}
            </div>
          </ion-label>
        </ion-item>
      </ion-list>

    </ion-card-content>
  </ion-card>

  <ion-card>

    <ion-card-header (click)="menuToggle[1] = !menuToggle[1]">
      <!--<ion-card-subtitle>Card Subtitle</ion-card-subtitle>-->
      <ion-card-title>Typography <ion-icon class="ion-float-right" name="text-outline"></ion-icon></ion-card-title>
    </ion-card-header>
    <!-- Typography -->
    <ion-card-content *ngIf="menuToggle[1]">

      <ion-list>
        <ion-item *ngFor="let item of this.typography">
          <ion-label>
            <div>{{ item.key }}: <b>{{ fetchDocumentValue(this.typography, item.key) }}</b></div>
            <div><ion-text color="medium">{{ item.desc }}</ion-text></div>
          </ion-label>
          <ion-label style="font-family: var({{ item.key }}); width: 350px;" slot="end">
            <div style="font-weight: 100;">Lorem Ipsum / font-weight: 100;</div>
            <div style="font-weight: 300;">dolor sit amet / font-weight: 300;</div>
            <div style="font-weight: 400;">consectetur adipiscing elit / font-weight: 400;</div>
            <div style="font-weight: 700;">sed do eiusmod tempor / font-weight: 700;</div>
          </ion-label>
        </ion-item>
      </ion-list>

    </ion-card-content>
  </ion-card>

  <ion-card>
    <ion-card-header (click)="menuToggle[2] = !menuToggle[2]">
      <!--<ion-card-subtitle>Card Subtitle</ion-card-subtitle>-->
      <ion-card-title>Buttons <ion-icon class="ion-float-right" name="albums-outline"></ion-icon></ion-card-title>
    </ion-card-header>
    <!-- Buttons -->
    <ion-card-content *ngIf="menuToggle[2]">

      <ion-list *ngFor="let i of [0, 1]">
        <ion-item [ngClass]="{ 'inverse' : i }" *ngFor="let item of this.colors">
          <ion-label>
            <b class="ion-text-capitalize">{{ item.key }}</b>
            <!--
            <div *ngFor="let variation of this.colorsVariations">

              --ion-color-{{ item.key + variation }}:
              <b>{{ fetchDocumentValue(this.colors, '--ion-color-' + item.key + variation) }}</b>
            </div>
            -->
            <div class="ion-text-center" style="background: {{ i ? 'linear-gradient(103deg, rgba(44,42,108,1) 0%, rgba(98,0,105,1) 100%)' : '#fff' }}; padding-top: 5px; padding-bottom: 5px;">

              <ion-button  class="application-v2" size="large" [color]="item.key">Size: Large</ion-button>
              <ion-button  class="application-v2" size="default" [color]="item.key">Size: Default</ion-button>
              <ion-button  class="application-v2" size="small" [color]="item.key">Size: Small</ion-button>

              <ion-button  class="application-v2" size="default" fill="clear" [color]="item.key">Fill: Clear</ion-button>
              <ion-button  class="application-v2" size="default" fill="outline" [color]="item.key">Fill: Outline</ion-button>

              <ion-button  class="application-v2" size="default" [strong]="true" [color]="item.key">Strong: True</ion-button>

              <ion-button  class="application-v2" size="default" [color]="item.key"><ion-icon class="mini" name="star" slot="icon-only"></ion-icon></ion-button>
              <ion-button  class="application-v2" size="default" fill="clear" [color]="item.key"><ion-icon class="medium" name="star" slot="icon-only"></ion-icon></ion-button>
              <ion-button  class="application-v2" size="default" fill="outline" [color]="item.key"><ion-icon class="maxi" name="star" slot="icon-only"></ion-icon></ion-button>
              <br/>

              <ion-button  class="application-v2" shape="round" size="large" [color]="item.key">Size: Large</ion-button>
              <ion-button  class="application-v2" shape="round" size="default" [color]="item.key">Size: Default</ion-button>
              <ion-button  class="application-v2" shape="round" size="small" [color]="item.key">Size: Small</ion-button>

              <ion-button  class="application-v2" shape="round" size="default" fill="clear" [color]="item.key">Fill: Clear</ion-button>
              <ion-button  class="application-v2" shape="round" size="default" fill="outline" [color]="item.key">Fill: Outline</ion-button>

              <ion-button  class="application-v2" shape="round" size="default" [strong]="true" [color]="item.key">Strong: True</ion-button>

              <ion-button  class="application-v2" shape="round" size="default" [color]="item.key"><ion-icon class="mini" name="star" slot="icon-only"></ion-icon></ion-button>
              <ion-button  class="application-v2" shape="round" size="default" fill="clear" [color]="item.key"><ion-icon class="medium" name="star" slot="icon-only"></ion-icon></ion-button>
              <ion-button  class="application-v2" shape="round" size="default" fill="outline" [color]="item.key"><ion-icon class="maxi" name="star" slot="icon-only"></ion-icon></ion-button>
            </div>
          </ion-label>

        </ion-item>
      </ion-list>

    </ion-card-content>
  </ion-card>

  <ion-card>
    <ion-card-header (click)="menuToggle[3] = !menuToggle[3]">
      <!--<ion-card-subtitle>Card Subtitle</ion-card-subtitle>-->
      <ion-card-title>Badges & Tooltips <ion-icon class="ion-float-right" name="albums-outline"></ion-icon></ion-card-title>
    </ion-card-header>
    <!-- Badges -->
    <ion-card-content *ngIf="menuToggle[3]">

      <div class="ion-text-center">

        <ion-badge [tooltip]="item.key" *ngFor="let item of this.colors" [color]="item.key" class="ion-text-capitalize">{{ item.key }}</ion-badge>

      </div>

      <div class="ion-text-center">
        <b>Class: Tag</b><br/>
        <ion-badge [tooltip]="item.key" *ngFor="let item of this.colors" [color]="item.key" class="ion-text-capitalize tag">{{ item.key }}</ion-badge>

      </div>

    </ion-card-content>
  </ion-card>

  <ion-card>

    <ion-card-header (click)="menuToggle[4] = !menuToggle[4]">
      <!--<ion-card-subtitle>Card Subtitle</ion-card-subtitle>-->
      <ion-card-title>
        Inputs
        <div class="ion-float-right">
          <ion-icon class="ion-float-right" name="text-outline"></ion-icon>
        </div>
      </ion-card-title>
    </ion-card-header>
    <!-- Inputs -->
    <ion-card-content *ngIf="menuToggle[4]">

      <div class="ion-text-center ion-padding-bottom">

        <ion-button size="small" (click)="inputsColor = null"><span class="ion-text-capitalize">No Color</span></ion-button>

        <ng-container *ngFor="let item of this.colors">

          <ion-button (click)="inputsColor = item.key" size="small" [color]="item.key"><span class="ion-text-capitalize">{{ item.key }}</span></ion-button>

        </ng-container>

      </div>

      <div>

        <ion-grid>
          <ion-row *ngFor="let class of ['liner', 'form', 'intext']">

            <ion-col size="4">
              <b class="ion-text-capitalize">{{ 'Input' }}</b>
              <br/>
              <ion-input [color]="inputsColor" class="{{class}}" value="Class: {{class}}" placeholder="Class: Form"></ion-input>
              <br/>
              <ion-input [color]="inputsColor" class="{{class}}" value="Disabled" disabled></ion-input>
              <ion-input [color]="inputsColor" class="{{class}}" value="Readonly" readonly></ion-input>
              <ion-input [color]="inputsColor" class="{{class}}" placeholder="Placeholder"></ion-input>
              <ion-input [color]="inputsColor" class="{{class}}" value="Value"></ion-input>
              <hr/>
              <ion-input [color]="inputsColor" class="{{class}} small" value="Class: Compact"></ion-input>
              <ion-input [color]="inputsColor" class="{{class}} large" value="Class: Large"></ion-input>
            </ion-col>

            <ion-col size="4">
              <b class="ion-text-capitalize">{{ 'Textarea' }}</b>
              <br/>
              <ion-textarea [color]="inputsColor" class="{{class}}" value="Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit..." placeholder="Class: Form"></ion-textarea>
              <br/>
              <ion-textarea [color]="inputsColor" class="{{class}}" value="Disabled" disabled></ion-textarea>
              <ion-textarea [color]="inputsColor" class="{{class}}" value="Readonly" readonly></ion-textarea>
              <ion-textarea [color]="inputsColor" class="{{class}}" placeholder="Placeholder"></ion-textarea>
              <ion-textarea [color]="inputsColor" class="{{class}}" value="Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit..."></ion-textarea>
            </ion-col>

            <ion-col size="4">
              <b class="ion-text-capitalize">{{ 'Select' }}</b>
              <br/>
              <ion-select class="{{class}} ion-color-{{ inputsColor }}" placeholder="Class: Liner">
                <ion-select-option value="apples">Apples</ion-select-option>
                <ion-select-option value="oranges">Oranges</ion-select-option>
                <ion-select-option value="bananas">Bananas</ion-select-option>
              </ion-select>
              <br/>
              <ion-select class="{{class}} ion-color-{{ inputsColor }}" placeholder="Class: Form">
                <ion-select-option value="apples">Apples</ion-select-option>
                <ion-select-option value="oranges">Oranges</ion-select-option>
                <ion-select-option value="bananas">Bananas</ion-select-option>
              </ion-select>
              <br/>
              <ion-select disabled class="{{class}} ion-color-{{ inputsColor }}" value="disabled">
                <ion-select-option value="disabled">Disabled</ion-select-option>
              </ion-select>
              <ion-select readonly class="{{class}} ion-color-{{ inputsColor }}" value="readonly">
                <ion-select-option value="readonly">Readonly</ion-select-option>
              </ion-select>
              <ion-select class="{{class}} ion-color-{{ inputsColor }}" placeholder="Placeholder">
              </ion-select>
              <ion-select class="{{class}} ion-color-{{ inputsColor }}" value="apples">
                <ion-select-option value="apples">Apples</ion-select-option>
                <ion-select-option value="oranges">Oranges</ion-select-option>
                <ion-select-option value="bananas">Bananas</ion-select-option>
              </ion-select>
            </ion-col>



          </ion-row>
        </ion-grid>

      </div>

    </ion-card-content>
  </ion-card>

  <ion-card>
    <ion-card-header (click)="menuToggle[3] = !menuToggle[3]">
      <!--<ion-card-subtitle>Card Subtitle</ion-card-subtitle>-->
      <ion-card-title>Toggle & Radio & Checkbox <ion-icon class="ion-float-right" name="albums-outline"></ion-icon></ion-card-title>
    </ion-card-header>
    <!-- Badges -->
    <ion-card-content *ngIf="menuToggle[3]">

      <ion-row>
        <ion-col>
          <div class="ion-text-center">

            <div>
              <ion-toggle *ngFor="let item of this.colors" [checked]="true" [color]="item.key"></ion-toggle>
            </div>

            <div *ngFor="let item of this.colors">
              <ion-item>
                <ion-label>In Item - {{ item.key }}</ion-label>
                <ion-toggle slot="start" [color]="item.key" [checked]="true"></ion-toggle>
              </ion-item>
              <ion-item>
                <ion-label>In Item - {{ item.key }}</ion-label>
                <ion-toggle slot="end" [color]="item.key" [checked]="false"></ion-toggle>
              </ion-item>
            </div>

          </div>
        </ion-col>
        <ion-col>
          <div class="ion-text-center">

            <div>
              <ion-radio-group value="warning" *ngFor="let item of this.colors">
                <ion-radio [color]="item.key" value="warning"></ion-radio>
              </ion-radio-group>
            </div>

            <ion-radio-group value="warning" *ngFor="let item of this.colors">
              <ion-item>
                <ion-label>In Item - {{ item.key }}</ion-label>
                <ion-radio slot="start" [color]="item.key" value="warning"></ion-radio>
              </ion-item>
              <ion-item>
                <ion-label>In Item - {{ item.key }}</ion-label>
                <ion-radio slot="end" [color]="item.key" value="warning2"></ion-radio>
              </ion-item>
            </ion-radio-group>

          </div>
        </ion-col>
        <ion-col>
          <div class="ion-text-center">

            <div>
              <ion-checkbox *ngFor="let item of this.colors" [checked]="true" [color]="item.key"></ion-checkbox>
            </div>

            <div *ngFor="let item of this.colors">
              <ion-item>
                <ion-label>In Item - {{ item.key }}</ion-label>
                <ion-checkbox slot="start" [color]="item.key" [checked]="true"></ion-checkbox>
              </ion-item>
              <ion-item>
                <ion-label>In Item - {{ item.key }}</ion-label>
                <ion-checkbox slot="end" [color]="item.key" [checked]="false"></ion-checkbox>
              </ion-item>
            </div>

          </div>
        </ion-col>
      </ion-row>

    </ion-card-content>
  </ion-card>

  <ion-card>

    <ion-card-header (click)="menuToggle[5] = !menuToggle[5]">
      <!--<ion-card-subtitle>Card Subtitle</ion-card-subtitle>-->
      <ion-card-title>
        Segments
        <div class="ion-float-right">
          <ion-icon class="ion-float-right" name="toggle-outline"></ion-icon>
        </div>
      </ion-card-title>
    </ion-card-header>
    <!-- Inputs -->
    <ion-card-content *ngIf="menuToggle[5]">

      <div>

        <ion-grid>
          <ion-row>

            <ion-col>

              <div *ngFor="let i of [0, 1]">

                <div [ngClass]="{ 'inverse' : i }" style="padding: 5px; background-color: {{ i ? '#000' : '#fff' }};">
                  <!-- Default Segment -->
                  <ion-segment mode="ios" (ionChange)="segmentChanged($event)">
                    <ion-segment-button value="1">
                      <ion-label>Friends</ion-label>
                    </ion-segment-button>
                    <ion-segment-button value="2">
                      <ion-label>Enemies</ion-label>
                    </ion-segment-button>
                    <ion-segment-button value="3">
                      <ion-label>Neutrals</ion-label>
                    </ion-segment-button>
                  </ion-segment>

                  <hr/>

                  <!-- Scrollable Segment -->
                  <ion-segment mode="ios" scrollable value="heart">
                    <ion-segment-button value="home">
                      <ion-icon name="home"></ion-icon>
                    </ion-segment-button>
                    <ion-segment-button value="heart">
                      <ion-icon name="heart"></ion-icon>
                    </ion-segment-button>
                    <ion-segment-button value="pin">
                      <ion-icon name="pin"></ion-icon>
                    </ion-segment-button>
                    <ion-segment-button value="star">
                      <ion-icon name="star"></ion-icon>
                    </ion-segment-button>
                    <ion-segment-button value="call">
                      <ion-icon name="call"></ion-icon>
                    </ion-segment-button>
                    <ion-segment-button value="globe">
                      <ion-icon name="globe"></ion-icon>
                    </ion-segment-button>
                    <ion-segment-button value="basket">
                      <ion-icon name="basket"></ion-icon>
                    </ion-segment-button>
                  </ion-segment>

                  <hr/>

                </div>
              </div>

            </ion-col>

          </ion-row>
        </ion-grid>

      </div>

    </ion-card-content>
  </ion-card>

  <ion-card>

    <ion-card-header (click)="menuToggle[6] = !menuToggle[6]">
      <!--<ion-card-subtitle>Card Subtitle</ion-card-subtitle>-->
      <ion-card-title>
        Searchbar
        <div class="ion-float-right">
          <ion-icon class="ion-float-right" name="toggle-outline"></ion-icon>
        </div>
      </ion-card-title>
    </ion-card-header>
    <!-- Inputs -->
    <ion-card-content *ngIf="menuToggle[6]">

      <div>

        <ion-grid>
          <ion-row *ngFor="let item of this.colors">

            <ion-col>

              <ion-searchbar [color]="item.key" placeholder="{{ item.key }}"></ion-searchbar>

            </ion-col>

          </ion-row>
        </ion-grid>

      </div>

    </ion-card-content>
  </ion-card>

  <!--
  <ion-card>

    <div class="ion-text-center ion-padding">
      
      <gw-ai-blob [processing]="true"></gw-ai-blob>

    </div>
    
  </ion-card>
  -->

</ion-content>
