import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { DataSourceService } from '@services/data-source.service';

@Component({
  selector: 'app-profile-upgrade-modal',
  templateUrl: './profile-upgrade-modal.component.html',
  styleUrls: ['./profile-upgrade-modal.component.scss'],
})
export class ProfileUpgradeModalComponent implements OnInit {

  @Input() link: any = '';
  @Input() mode = 'upgrade'; // subscription, upgrade

  constructor(
    public modalCtrl: ModalController,
    public dataSource: DataSourceService
  ) { }

  ngOnInit() {
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      dismissed: true
    });
  }

  ok() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      forward: true
    });
  }

}
