<ion-fab vertical="top" horizontal="end" slot="fixed">
  <ion-fab-button size="small" (click)="dismiss()">
   <ion-icon name="close"></ion-icon>
  </ion-fab-button>
</ion-fab>

<ion-content class="ion-padding" color="white">

  <div class="box">

    <div class="ion-text-start ion-float-left information">
      <img src="assets/v2/icons/denied.png">
      <b>
        You’ve Reached Your Limit!
      </b>
      <div class="text">
        <!-- <div class="ion-margin-bottom">Hey there, we noticed you have not completed your planning phase yet.</div> -->
        <p>Upgrade your plan to increase your limit. Or you can delete one of your current sites to create a new one.</p>
        <div>If you have any questions please contact support</div>
      </div>
    </div>
    <!--
    <div class="alert">
      <div class="box">
        <img src="assets/v2/icons/alert.png">
        <p>
          If you would like to continue testing the AI tool and providing feedback please contact support@growthworks.ai subject line “I want more credits”
        </p>
      </div>
    </div>
    -->
  </div>

</ion-content>

<ion-footer>

  <!--
  <ion-button size="large" color="primary" (click)="ok()">
    Buy More Credits Now
    <ion-icon slot="end" name="chevron-forward-outline"></ion-icon>
  </ion-button>
  -->
  <ion-button size="large" color="dark" fill="outline" (click)="dismiss()">Got It!</ion-button>

</ion-footer>
