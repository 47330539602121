<div>

  <ion-button fill="clear" color="dark" expand="block" (click)="profile()">Manage My Profile</ion-button>

  <ion-button fill="clear" color="dark" expand="block" *ngIf="this.auth.data.is_staff" (click)="manage()">Manage Templates</ion-button>


  <ng-container *ngIf="this.auth.data.is_coach">

    <ion-button fill="clear" color="dark" expand="block" (click)="coach('contacts')">Coach Center</ion-button>

  </ng-container>


  <ion-button fill="clear" color="dark" expand="block" (click)="brand()" *ngIf="this.auth.data.is_staff">Brand Center</ion-button>


  <ion-button fill="clear" color="dark" expand="block" (click)="support()" *ngIf="this.auth.data.is_supported">Contact Support</ion-button>

<!-- brand-center -->

  <ion-button fill="clear" color="dark" expand="block" (click)="logout()">Sign Out</ion-button>

</div>
