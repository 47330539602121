<ion-header>
  <ion-toolbar>
    <ion-title><b>{{ data.title }}</b></ion-title>
    <ion-buttons slot="end">
      <ion-button shape="round" color="dark" (click)="dismiss()">
         <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="item" class="ion-padding" color="light">

  <div>

    <div class="ion-margin-bottom" *ngIf="item.video_url">
      <iframe [src]=" '//fast.wistia.net/embed/iframe/' + item.video_url.split('/medias')[1] | safe: 'resourceUrl' " allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" allowfullscreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen width="100%" height="350"></iframe>
    </div>

    <div class="ion-padding-top">
      {{ item.short_desc }}
    </div>

    <ion-list *ngIf="!item.is_coming">

      <ion-item *ngFor="let stage of item.stages">
        <ion-label class="ion-text-wrap">
          <ion-text color="dark">
            <h5><b>{{ stage.title }}</b> {{ stage.sub_title }}</h5>
          </ion-text>
          <ion-text color="medium">
            <p class="ion-padding-top" *ngIf="stage.description">{{ stage.description | striphtml | truncate: 200 }}</p>
          </ion-text>
        </ion-label>
      </ion-item>

    </ion-list>

  </div>

</ion-content>

<ion-footer class="ion-padding ion-no-border" *ngIf="item">

  <ion-button *ngIf="!item.is_coming" class="ion-float-right" mode="ios" color="primary" (click)="selectTemplate(data)">Use This Template</ion-button>

</ion-footer>
