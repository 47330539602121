<ion-header>
  <ion-toolbar>
    <ion-title><b>&nbsp;&nbsp; A QUICK HEADS UP FOR YOU!</b></ion-title>
    <ion-buttons slot="end">
      <ion-button shape="round" color="dark" (click)="dismiss()">
         <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding" color="light">

  <div class="ion-padding">

    Hey there, we noticed you have not completed your planning phase yet.<br/><br/>Be aware that we use the information in the planning phase to create your assets.<br/><br/>Creating assets before your plan is completed will leave you with more information to fill in or require you to delete and recreate the asset once your plan is done.

  </div>

</ion-content>

<ion-footer class="ion-padding ion-no-border">

  <ion-button class="ion-float-left" mode="ios" color="medium" fill="clear" (click)="dismiss()">Cancel</ion-button>
  <ion-button class="ion-float-right" mode="ios" color="primary" (click)="ok()">Continue</ion-button>

</ion-footer>
