import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { DataSourceService } from '@services/data-source.service';

@Component({
  selector: 'app-detail-variation',
  templateUrl: './detail-variation.page.html',
  styleUrls: ['./detail-variation.page.scss'],
})
export class DetailVariationPage implements OnInit {

  @Input() data;
  item = null;

  sets = {};

  // @Input() mode = 'create';

  constructor(
    public modalCtrl: ModalController,
    public dataSource: DataSourceService
  ) { }

  ngOnInit() {
    console.log('data', this.data);
    // getTemplateSettingsById
    try {
      this.data.custom_preview.forEach( item => {
        console.log('item', item);
        this.dataSource.getTemplateSettingsById(this.data.id, item.content_type).subscribe( (res) => {

          // console.log(res);
          if (!this.sets[item.content_type]) {
            this.sets[item.content_type] = [];
          }

          console.log('push', res);

          res.presets = res.presets.presets;

          res.presets.forEach( (preset) => {
            preset.combined = {...item, ...JSON.parse(JSON.stringify(preset.variables))} ;
            preset.combined.preset = preset.title;
          });

          this.sets[item.content_type].push(res);

          if ( this.data.custom_preview.length === 1 && res.presets.length === 1 ) {
            // console.log(this.sets[item.content_type][0].presets[0].combined);
            // return;
            setTimeout( () => {
              // alert('this.select');
              this.select(this.sets[item.content_type][0].presets[0].combined);
            }, 500);
          } else {
            // hydra
            // find element with class .inner-animatio and remove it
            window.document.querySelectorAll('.inner-animation').forEach( (el) => {
              el.classList.remove('inner-animation');
            });
            setTimeout( () => {
              const query = (window as any).document.querySelectorAll('.detail-variation-template');
              if ( query && query.length ) {
                const last = query[query.length - 1];
                if ( last ) {
                  last.classList.add('hydra');
                }
              }
            }, 100);
          }

        });
      });

      /*
      if ( totalPersetCount ) {
        console.log('totalPersetCount', totalPersetCount);
      }
      */

    } catch (err) {
      // -
    }
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      dismissed: true
    });
  }

  select(item) {
    this.modalCtrl.dismiss({
      selected: item,
    });
  }

}
