import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.scss'],
})
export class DashboardCardComponent implements OnInit {

  @Input() progress = 25;

  @Input() title;

  @Input() image;

  @Input() stepbtn;

  @Input() subtitle;


  constructor() { }

  ngOnInit() {}

  public fill() {
    this.progress = 10;
  }

}
