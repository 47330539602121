import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { StripeCardNumberComponent, StripeCardExpiryComponent, StripeCardCvcComponent, StripeService } from 'ngx-stripe';
import {
  StripeCardElementOptions,
  StripeElementsOptions,
  PaymentIntent,
} from '@stripe/stripe-js';
import { environment as env } from '@env/environment';
import { DataSource } from '@angular/cdk/collections';
import { DataSourceService } from '@app/-core/services/data-source.service';
import { AuthService } from '@app/-core/services/auth.service';
import { AlertController, LoadingController } from '@ionic/angular';

@Component({
  selector: 'app-payment-add-card',
  templateUrl: './payment-interface.component.html',
  styleUrls: ['./payment-interface.component.scss'],
})
export class PaymentInterfaceComponent implements OnInit {

  @ViewChild(StripeCardNumberComponent) card: StripeCardNumberComponent;
  @ViewChild(StripeCardExpiryComponent) exp: StripeCardExpiryComponent;
  @ViewChild(StripeCardCvcComponent) cvc: StripeCardCvcComponent;

  name = '';

  @Output() close = new EventEmitter();
  @Output() success = new EventEmitter();

  ready: any = false;
  setupData: any = null;
  statusData: any = null;

  public cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        fontWeight: 400,
        fontFamily: '"Google Sans", Arial',
        fontSize: '14px',
        iconColor: '#666',
        color: '#000',
        backgroundColor: '#f0f0f0',
        padding: '13px 12px',
        '::placeholder': {
          color: '#999',
        },
      },
    },
  };

  public elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private stripeService: StripeService,
    private dataSource: DataSourceService,
    public auth: AuthService,
    public alertController: AlertController,
    public loadingController: LoadingController,
  ) {}

  ngOnInit() {
    this.ready = false;
    this.dataSource.getStripeAddCardIntent().subscribe((result) => {
      console.log('getStripeAddCardIntent', result);
      this.ready = true;
      this.setupData = result;
    }, err => {
      this.ready = false;
      setTimeout(() => {
        this.ngOnInit();
      }, 5000);
    });
  }

  cancel() {
    this.close.emit(true);

  }

  async presentCardAdded() {
    const alert = await this.alertController.create({
      header: 'Your Credit Card Was Successfully Added!',
      cssClass: 'application-v2 alert-v2 credit-card-added-alert success-icon',
      message: 'Your card has been added successfully.',
      buttons: ['Got It']
    });
    await alert.present();
  }

  async pay() {

    const loading = await this.loadingController.create({
      message: 'Adding Card...',
      duration: 5000,
    });
    await loading.present();


    const clientSecret = this.setupData.clientSecret;

    if ( !this.setupData.card_name ) {
      this.setupData.card_name = this.setupData.card_email;
    }

    this.stripeService.confirmCardSetup(clientSecret, {
      payment_method: {
        card: this.card.element,
        billing_details: {
          email: this.setupData.card_email,
          name: this.setupData.card_name,
        },
      },
    }).subscribe( async (result) => {

      await loading.dismiss();

      console.log('confirmCardSetup', result);

      this.dataSource.getStripeAddCardStatus().subscribe((sresult) => {
        console.log('getStripeAddCardStatus', sresult);
        // this.statusData = sresult;
        if ( sresult.status === 'succeeded' ) {
          // this.close.emit(true);
          this.success.emit(true);

          this.presentCardAdded();
          // this.ngOnInit();
        }
      }, err => {
        // this.ngOnInit();
      });

    },async  err => {
      await loading.dismiss();
    });

  }

}