import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gw-bubbles-spinner',
  templateUrl: './bubbles-spinner.component.html',
  styleUrls: ['./bubbles-spinner.component.scss'],
})
export class BubblesSpinnerComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
