import { Tracking } from '@tracking';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, throwError, from } from 'rxjs';
import { catchError, switchMap, tap, map, delay, finalize } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { NabuApiService } from '@services/api/nabu-api.service';
import { DataSourceService } from '@services/data-source.service';

import { ModalController } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { FormProcessorService } from './form-processor.service';
import { AuthService } from './auth.service';

import { AiHitLimitComponent } from '@app/-core/components-v2/ai-hit-limit/ai-hit-limit.component';

import { AiLoadingScreenComponent } from '../components-v2/ai-loading-screen/ai-loading-screen.component';

@Injectable({
  providedIn: 'root'
})
export class AiService {

    public generatePlansStatusChange: Subject<any> = new Subject<any>();
    public generatePlansStatus:any = null;

    public env = environment;

    public loadingScreenModal = null;

    constructor(public api: NabuApiService,
        public dataSource: DataSourceService,
        public storage: Storage,
        public formProcessor: FormProcessorService,
        public auth: AuthService,

        public tracking: Tracking,

        private modalController: ModalController,
        private toastController: ToastController,
        public alertController: AlertController,
        private router: Router) {

            this.generatePlansStatusChange.subscribe((value) => {
                console.log('generatePlansStatus is set', value);
                this.generatePlansStatus = value;
            });

            this.generatePlansStatusChange.next("unknown");

            // this.showAiHitLimitModal();

    }

    public hasPermission() {
        // return false;
        return ( this.auth && this.auth.data && this.auth.data.ai_permissions && this.auth.data.ai_permissions.ai_plans && this.auth.data.ai_permissions.ai_plans === true );
    }

    public updatePlansGenerationStatus() {
        if ( !this.hasPermission() ) return;

        const projectId = this.dataSource.currentActiveProject.id;
        this.generatePlansStatusChange.next("unknown");
        this.dataSource.getAiGeneratePlansStatus(projectId).subscribe((res) => {
            console.log('generatePlansStatus getAiPlansWizardData', res);
            this.generatePlansStatusChange.next("uncreated");
            if (res.status == 1 ) {
                this.generatePlansStatusChange.next("created");
            }
        }, err => {
            this.generatePlansStatusChange.next("uncreated");
        });
    }

    public presentAiOfflineAlert() {
        const options = {
            cssClass: 'information-icon',
            header: 'YO!!! Sorry to have to say this...',
            message: 'AI is a new technology and we are currently experiencing a situation where it is offline or overloaded. We are working to bring back the magic as soon as possible.\n\nKeep an eye on the Growthworks Core group for more updates and to be notified when we are back up and running.',
            buttons: [
                {
                    text: 'Ok',
                    // role: 'cancel',
                    handler: () => {

                    }
                }
            ]
        };
    
        this.formProcessor.showComplexMessage(options);
        
    }

    async showAiHitLimitModal() {
        const modal = await this.modalController.create({
          component: AiHitLimitComponent,
          cssClass: 'application-v2 modal-v2 ai-hit-limit',
        });
        await modal.present();

        const { data } = await modal.onWillDismiss();
        if ( data && data.forward ) {
            this.redirectToBuyCredits();
        }

        return;
    }

    public redirectToBuyCredits() {
        const link = 'https://secure.growthworks.io/ai-credit-packs/?passthrough[customer_email]='+(this.auth.data.email || '');

        window.open(link, '_blank');
    }

    public proceedAiErrorResponse(err) {
        if ( err.status === 403 ) {
            if ( err.status === 403 && err.error && err.error.message && err.error.message === 'AI service is not available' ) {
                this.presentAiOfflineAlert();
            } else {
                this.showAiHitLimitModal();
            }
        } else {
            this.formProcessor.showNotification('Unexpected server error, please try again later', 'danger', 4000);
        }
    }

    public startPlansGeneration(verify = true) {
        if ( !this.hasPermission() ) return;

        const projectId = this.dataSource.currentActiveProject.id;

        const runGeneration = () => {
            this.dataSource.getStartAiGeneratePlans(projectId).subscribe((res) => {
                this.generatePlansStatusChange.next("created");
    
                setTimeout( () => {
                    this.router.navigate(['/app/view/variables/ai/' + projectId + '/' + 0]);
                }, 350);
                
            }, (err) => {
    
                
                if ( err.status === 403 && err.error && err.error.message && err.error.message === 'AI service is not available' ) {
                    this.presentAiOfflineAlert();
                }
    
            });
        }

        if ( verify ) {

            // Analyzi progress of planner/roadmap
            const projcetId = this.dataSource.currentActiveProject.id;
            this.dataSource.viewCampaing(projcetId, null, true).subscribe( (res) => {
                // this.item = res;
                const integrity = (res.plan[0].progtess + res.plan[1].progtess);
                console.log('Item plan integrity', res.plan, integrity);
                // return;
                if ( integrity > 0 ) {

                    const options = {
                        cssClass: 'ai-icon',
                        header: 'This will overwrite your current plan data. If this is not your intent you should clone your campaign before proceeding!',
                        // message: 'Are you sure you want to start content generation ?',
                        buttons: [
                            {
                                text: 'Cancel',
                                role: 'cancel',
                                handler: () => {
                                    console.log('Confirm Cancel');
                                }
                            }, {
                                text: 'Continue',
                                // cssClass: 'danger',
                                handler: (res) => {
                                    console.log('Confirm Ok');

                                    this.tracking.event('regenerate_ai_btn_campaign_popup', {
                                        event_category: 'Generate AI',
                                        event_label: 'Location: Regenerate Popup on Dashboard and Campaign Planner',
                                    });

                                    runGeneration();
                                }
                            }
                        ]
                    };

                    this.formProcessor.showComplexMessage(options);
                } else {
                    setTimeout( () => {
                        runGeneration();
                    }, 350);

                }
            });
            return;
        } else {
            runGeneration();
        }

    }

    public stopGenerationProcess(projectId) {
        if ( !this.hasPermission() ) return;

        // http://127.0.0.1:8000/api/v2/content-generation/51/stop/

        const options = {
            // cssClass: 'information-icon',
            header: 'Confirm',
            message: 'Are you sure you want to stop content generation ?',
            buttons: [
              {
                text: 'Cancel',
                role: 'cancel',
                handler: () => {
                  console.log('Confirm Cancel');

                }
              }, {
                text: 'Stop Generation',
                cssClass: 'danger',
                handler: (res) => {
                  console.log('Confirm Ok');
                  this.dataSource.postAiStopGeneration(projectId).subscribe((res) => {
                    
                    (window as any).location.reload();

                  });
                }
              }
            ]
        };
      
        this.formProcessor.showComplexMessage(options);


    }

    // public fakeAiTypewriting
    public fakeNodeAiTypewriting(node, text, mode = 'html', delay = 1) {
        if ( !this.hasPermission() ) return;

        if ( !node.ai_typewriting ) {

            node.ai_typewriting = true;
            node.ai_typewriting_mode = 'human';
            
            node.ai_typewriting_delay = 250;
            
            node.ai_typewriting_position = 0;
            node.ai_typewriting_text = text;
            node.ai_typewriting_text_size = node.ai_typewriting_text.length;

            if ( mode === 'html' ) {
                node.innerHTML = '';
            } if ( mode === 'text' ) {
                node.innerText = '';
            } if ( mode === 'node' ) {
                node.nodeValue = '';
            } 

            setTimeout( () => {

                node.ai_typewriting_interval = setInterval(
                    () => {
        
                        setTimeout( () => {
        
        
                            if ( node.ai_typewriting_position < node.ai_typewriting_text_size ) {
                                const currentLetter = node.ai_typewriting_text[node.ai_typewriting_position];
                                // node.innerHTML = node.innerHTML + currentLetter;
    
                                if ( mode === 'html' ) {
                                    node.innerHTML = node.innerHTML + currentLetter;
                                } if ( mode === 'text' ) {
                                    node.innerText = node.innerText + currentLetter;
                                } if ( mode === 'node' ) {
                                    node.nodeValue = node.nodeValue + currentLetter;
                                } 
    
                                node.ai_typewriting_position++;
                            } else {
                                node.ai_typewriting = false;
                                clearInterval(node.ai_typewriting_interval);
                            }
        
        
                        }, 
                        ( node.ai_typewriting_mode === 'human' ) ? Math.random() * node.ai_typewriting_delay : 1
                        );
        
                    }
                , node.ai_typewriting_delay);

            }, delay);

        }

    }

    fakeNodeAiTypewritingInnerNodes(node, target = null) {
        if ( !this.hasPermission() ) return;

        if ( !node.ai_typewriting_nodes ) {

            node.ai_typewriting_nodes = true;
            /*
            node.ai_typewriting_mode = 'human';
            
            node.ai_typewriting_delay = 100;
            
            node.ai_typewriting_position = 0;
            node.ai_typewriting_text = null;
            node.ai_typewriting_text_size = 0;
            */

            // node.innerHTML = '';

            // Analize Nodes 
            console.log('fakeNodeAiTypewritingInnerNodes', {node});

            const allDescendants = (node) => {
                console.log('fakeNodeAiTypewritingInnerNodes - node', {node});
                for (var i = 0; i < node.childNodes.length; i++) {
                  var child = node.childNodes[i];

                  if ( child && child.nodeType === Node.TEXT_NODE ) {
                    if ( target && child.parentNode.className.split(' ').indexOf(target) === -1 ) {
                        return;
                    }
                    console.log('fakeNodeAiTypewritingInnerNodes - text', child);
                    // child.nodeValue = 'xxx';
                    this.fakeNodeAiTypewriting(child, child.nodeValue, 'node');

                  } else {
                    setTimeout(function(child) { return function() { 
                        allDescendants(child);
                    }; }(child), 50);
                  }
                }
            }
            
            allDescendants(node);

        }

    }


    // Brainstorm AI Section

    public postAiBrainstormStopGeneration(projectId): Observable<any> {
        const observable = this.api.get('api/v2/content-generation/' + projectId + '/stop/', {}, {}, {preloader: true, backendErrors: true});
        return observable;
    }

    presentLearnMoreAiMissleads(ev) {
        // Display message via formProcessor showComplexMessage
        this.formProcessor.showComplexMessage({
          header: 'Learn More Misslead',
          message: 'Learn More Message',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
                console.log('Confirm Okay');
                this.storage.set('learnMoreAiMissleads', true);
              }
            }
          ]
        });
        
    }

    getLoadingModalComponent() {
        return AiLoadingScreenComponent;
    }

    async presentAiLoadingScreen(subscription = null) {
        if ( !this.loadingScreenModal ) {
            this.loadingScreenModal = await this.modalController.create({
                component: AiLoadingScreenComponent,
                componentProps: {
                    subscription
                },
                cssClass: 'application-v2 modal-v2 ai-loading-screen-modal',
                backdropDismiss: false,
            });
        } else {
            this.loadingScreenModal.componentProps.subscription = subscription;
        }
        this.loadingScreenModal.isOpen = false;
        // console.log('this.loadingScreenModal', {modal: this.loadingScreenModal});
        await this.loadingScreenModal.present();
        this.loadingScreenModal.isOpen = true;

        // const { data } = await modal.onWillDismiss();
        this.loadingScreenModal.subscription = subscription
        return this.loadingScreenModal;
    }

    async dismissAiLoadingScreen() {
        if ( this.loadingScreenModal ) {
            await this.loadingScreenModal.dismiss({
                'dismissed': true
            });
            this.loadingScreenModal = null;
            // this.loadingScreenModal.isOpen = true;
        }
    }


}
