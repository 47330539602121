<div class="wrapper" *ngIf="isToggled">


  <div class="container" [ngClass]="{'full-mode' : !toggleWidth}"
    *ngIf="isToggled && (!coachId && !clientId)">

    <div class="box">
      <div class="icon"></div>
      <div class="text">Messages</div>

      <ion-button class="full-width" color="dark" shape="round" (click)="toggleWidth = !toggleWidth">
        <!-- <ion-icon name="scan" slot="icon-only" color="white"></ion-icon> -->
        <ion-icon [name]="toggleWidth ? 'expand' : 'contract-outline'" slot="icon-only" color="white"></ion-icon>
      </ion-button>

      <!-- <ion-button class="full-width" color="white" shape="round" (click)="toggleWidth = !toggleWidth">
        <ion-icon [ngClass]="{'active' : toggleWidth}" class="second" name="contract-outline" slot="icon-only"></ion-icon>
      </ion-button> -->

      <ion-button class="close-btn" color="dark" shape="round" (click)="toggle()">
        <ion-icon name="close-outline" slot="icon-only"></ion-icon>
      </ion-button>
    </div>

    <ion-content>

      <ion-card *ngFor="let coach of coachList;">
        <ion-card-content>

          <div class="head">
            <div class="img">
              <ion-img [src]="this.formProcessor.renderAvatar(coach.avatar)"></ion-img>
            </div>

            <div class="desc">
              <div class="title">{{ coach.first_name }} {{ coach.last_name }}</div>
              <div class="subtitle" *ngIf="coach.bio">{{ coach.bio }}</div>
              <ion-button class="start-btn" (click)="setCoach(coach)">
                Start Chatting
                <ion-icon slot="end" name="chevron-forward-outline"></ion-icon>
              </ion-button>
            </div>
          </div>

          <div class="sub">

            <div class="line ion-margin-bottom"></div>
            <div class="campaigns">Campaigns</div>

            <div class="label" *ngFor="let cmp of filterCampaings(coach.campaigns);" (click)="openProject(cmp, coach)">
              <div class="name">{{ cmp.name }}</div>
              <div class="round" *ngIf="cmp.unread">{{ cmp.unread }}</div>
            </div>

            <div class="ion-padding-top" *ngIf="!filterCampaings(coach.campaigns).length">No new message</div>

          </div>

        </ion-card-content>
      </ion-card>

    </ion-content>
  </div>

  <!-- 
    [ngStyle]="{
      'width': toggleWidth ? '462px' : 'calc(50vw - 20px)',
      'height': toggleWidth ? 'calc(100vh - 91px)' : 'calc(100vh - 6px)',
      'background-size': toggleWidth ? 'auto 144px' : 'auto 144px',
      'bottom': toggleWidth ? '0px' : '-14px'
    }"  -->
  <div class="dialog" [ngClass]="{'full-mode-dialog' : !toggleWidth}"
    *ngIf="isToggled && (coachId || clientId)">

    <div class="lines">
      <div class="box">
        <div class="back-btn" (click)="coachId=null" *ngIf="this.coachId">
          <ion-icon name="arrow-back-outline"></ion-icon>
        </div>
        <div class="avatar">
          <ion-img [src]="this.formProcessor.renderAvatar(coach.avatar)"></ion-img>
        </div>
        <div class="text">{{ coach.first_name }} {{ coach.last_name }}</div>
        <ion-button class="full-width" color="white" shape="round" (click)="toggleWidth = !toggleWidth">
          <!-- <ion-icon name="scan" slot="icon-only" color="white"></ion-icon> -->
          <!-- <ion-icon [ngStyle]="{'name': toggleWidth ? 'expand' : 'contract-outline'}" slot="icon-only" color="white"></ion-icon> -->

          <ion-icon [name]="toggleWidth ? 'expand' : 'contract-outline'" slot="icon-only" color="white"></ion-icon>
        </ion-button>
        <ion-button class="close-btn" color="dark" shape="round" (click)="toggle()">
          <ion-icon name="close-outline" slot="icon-only"></ion-icon>
        </ion-button>
      </div>

      <div class="select" *ngIf="dataSource.coachingData && dataSource.coachingData.projectId && campaingList">
        <div class="select-name">Campaigns</div>
        <div class="select-area" (click)="presentPopover($event)">
          <span>{{ activeCampaing().title }}</span>
          <ion-icon class="ion-float-right" name="chevron-down-outline"></ion-icon>
        </div>
      </div>

      <ion-content
        class="messages"
        #chat
        [scrollEvents]="true"
        (ionScroll)="logScrollingChat($event)"
      >

      <ng-container *ngFor="let item of messages; let i = index;">

        <div class="date-stamp" *ngIf="i % 3 === 0">
          {{ item.created_on | amDateFormat:'LL' }}
        </div>
        <!--
        <div class="chat-instance" [ngClass]="{'isMy': !item.is_my_message}">
          <div class="avatar" [ngStyle]="{'background-image': 'url(' + this.formProcessor.renderAvatar(item.author.avatar) + ')'}"></div>
          <div class="message-container">
            <div class="action-tag" *ngIf="item.tag">
              {{ item.tag.frontend_tag }}
            </div>
            <div class="message-box">
              <b *ngIf="!item.is_my_message && item.author">{{ item.author.name }}<br/></b>
              <div class="date">
                {{ item.created_on | amDateFormat:'h:mm A' }}
              </div>
              <div class="message" [innerHtml]="item.text">

              </div>
            </div>
          </div>
        </div>
        -->

        <div *ngIf="!!item.is_my_message" class="self">

          <div class="messages-self" *ngIf="item.tag" (click)="recoverTag(item.tag)">
            <div class="messages-self-text">
              {{ item.tag.frontend_tag }}
            </div>
            <ion-icon name="chevron-forward-outline" class="ion-float-right"></ion-icon>
          </div>

          <div class="p" [innerHtml]="item.text">

          </div>

          <div class="time-self">
            {{ item.created_on | amDateFormat:'h:mm A' }}
          </div>

        </div>

        <div *ngIf="!item.is_my_message" class="other">

          <div class="avatar-other">
            <ion-img [src]="this.formProcessor.renderAvatar(item.author.avatar)"></ion-img>
          </div>

          <div class="answer">
            <div class="answer-other" *ngIf="item.tag" (click)="recoverTag(item.tag)">
              <div class="answer-other-text">
                {{ item.tag.frontend_tag }}
              </div>
              <ion-icon name="chevron-forward-outline" class="ion-float-right"></ion-icon>
            </div>
            <div class="p" [innerHtml]="item.text">

            </div>
          </div>

          <div class="time-other">
            {{ item.created_on | amDateFormat:'h:mm A' }}
          </div>

        </div>

      </ng-container>

        <!--
        <div class="self">
          <div class="messages-self">
            <div class="messages-self-text">
              #Product Roadmap > Step 2 - Product Roadmap Actions Checklist
            </div>
            <ion-icon name="chevron-forward-outline" class="ion-float-right"></ion-icon>
          </div>
          <div class="p">
            You can read the support article below on how to share your Growthworks URL on your social media profiles.
          </div>
          <div class="time-self">
            9:06 PM
          </div>
        </div>

        <div class="other">
          <div class="avatar-other">
            <ion-img src="assets/v2/icons/coach-chat-second.png"></ion-img>
          </div>
          <div class="answer">
            Hi Vlad, can you tell us a little bit more about what you need help with?
          </div>
          <div class="time-other">
            9:06 PM
          </div>
        </div>

        <div class="self">
          <div class="messages-self">
            <div class="messages-self-text">
              #Product Roadmap > Step 2 - Product Roadmap Actions Checklist
            </div>
            <ion-icon name="chevron-forward-outline" class="ion-float-right"></ion-icon>
          </div>
          <div class="p">
            You can read the support article below on how to share your Growthworks URL on your social media profiles.
          </div>
          <div class="time-self">
            9:06 PM
          </div>
        </div>
        -->

      </ion-content>

    </div>

    <div class="input" [ngClass]="{'full-bar': !toggleWidth}">
      <!--
      <ion-input placeholder="Start typing..."></ion-input>
      <ion-icon name="happy-outline"></ion-icon>
      <ion-icon name="chevron-forward-outline" class="forward"></ion-icon>
      -->
      <div class="current-tag" *ngIf="dataSource.coachingData && dataSource.coachingData.tag">
        <div class="action-tag">
          {{ dataSource.coachingData.tag }}
        </div>
      </div>

      <div class="input-bar" [ngClass]="{ 'non-interactive': !coach.is_active }">

        <div class="editor-block">

          <div class="nonint-text">This coach cannot receive messages at this moment.</div>

          <editor class="tce" [ngClass]="{ 'ion-hide' : !this.toggleWidth }"
            [(ngModel)]="message"
            #chatEditor
            [inline]="false"
            [init]="initTinyMceMain"
          ></editor>

          <editor class="tce" [ngClass]="{ 'ion-hide' : !!this.toggleWidth }"
            [(ngModel)]="message"
            #chatEditor
            [inline]="false"
            [init]="initTinyMceMainLarge"
          ></editor>

        </div>

        <div class="actions">

          <ion-button shape="round" fill="clear" color="medium" (click)="triggerEmoticons()">
            <ion-icon slot="icon-only" name="happy-outline"></ion-icon>
          </ion-button>

          <ion-button [disabled]="!message.length || !tinyMce" shape="round" fill="clear" color="medium" (click)="sendMessage()">
            <ion-icon slot="icon-only" name="send-outline"></ion-icon>
          </ion-button>

        </div>

      </div>


      <div class="ion-padding ion-text-center"><ion-spinner name="dots"></ion-spinner></div>

      <!--
      <ion-button class="send snd-c" size="small" color="primary" *ngIf="tinyMce" mode="ios" (click)="sendMessage()">Send</ion-button>
      -->

    </div>

  </div>



  <!-- <div class="btn" (click)="toggle()">
    <ion-icon name="chatbox-ellipses-outline"></ion-icon>
  </div> -->
</div>

<div class="wrapper-two" *ngIf="!isToggled" (click)="toggle()">
  <div class="chat-bar-mini">
    <div class="image">
      <div class="box-two">
        <div class="icon-two"></div>
        <div class="text-two">Coaching <span class="unreads-top" *ngIf="totalUnreads"><ion-icon name="ellipse-sharp"></ion-icon></span></div>
        <ion-icon name="chevron-up-outline"></ion-icon>
      </div>
    </div>
  </div>
</div>
