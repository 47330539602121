<!--
<ion-header>
  <ion-toolbar class="no-border">
    <ion-buttons slot="end">
      <ion-button shape="round" color="dark" (click)="dismiss()">
         <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
-->

<ion-fab vertical="top" horizontal="end" slot="fixed">
 <ion-fab-button size="small" (click)="dismiss()">
  <ion-icon name="close"></ion-icon>
 </ion-fab-button>
</ion-fab>

<ion-content class="ion-padding" color="white">

  <!-- <div class="ion-padding">

    <div class="ion-text-center">
    <img src="/assets/alert-new-canvas.png"/>
    </div>
    <br/>

    Hey there, we noticed you have not published your product design asset yet.<br/>
    Please complete Product design asset and publish the asset.<br/>
    <br/>
    Creating lead magnet before your product design asset is completed & published will leave you with more information to fill in or require you to delete and recreate the asset.
  </div> -->

  <div class="box">

    <div class="ion-text-start ion-float-left information">
      <!-- <img src="assets/v2/icons/attention.png"> -->
      <img src="assets/v2/icons/information.png">
      <b>
        A quick heads up for you!
      </b>
      <div class="text">
        <div class="ion-margin-bottom">Hey there, we noticed you have not completed your planning phase yet.</div>
        <div>Be aware that we use the information in the planning phase to create your assets.</div>
      </div>
    </div>
    <div class="alert">
      <div class="box">
        <img src="assets/v2/icons/alert.png">
        <p>
          Creating assets before your plan is completed will leave you with more information to fill in or require you to delete and recreate the asset once your plan is done.
        </p>
      </div>
    </div>
  </div>

</ion-content>

<ion-footer>

  <ion-button size="large" color="primary" (click)="ok()">
    Continue
    <ion-icon slot="end" name="chevron-forward-outline"></ion-icon>
  </ion-button>
  <ion-button size="large" color="dark" fill="outline" (click)="dismiss()">Cancel</ion-button>

</ion-footer>
