<ion-content>

<ion-router-outlet></ion-router-outlet>

</ion-content>

<!-- <ion-footer class="ion-no-border">
  <ion-toolbar class="copy">
    <div class="ion-text-center">
      2023 &#8226; <span class="active-item" [routerLink]="['/auth/privacy']">Privacy</span> & <span class="active-item" [routerLink]="['/auth/tos']">Terms</span>
    </div>
  </ion-toolbar>
</ion-footer> -->

<div class="copy">
  2023 &#8226; <span class="active-item" [routerLink]="['/auth/privacy']">Privacy</span> & <span class="active-item" [routerLink]="['/auth/tos']">Terms</span>
</div>
