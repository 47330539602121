import { environment } from '@env/environment';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root',
  // usageExample: this.tracking.event('generate_ai_btn_campaign_create_popup', { event_category: 'Generate AI', event_label: 'Location: Campaign Create Onboarding Popup' });
})
export class Tracking {

  constructor() { }

  announce(method = 'unnamed', payload: any = {}) {
    const loging = localStorage.getItem('tracking-debug') === 'true';
    if ( loging ) {

      // Advanced Logging Info

      console.groupCollapsed('%c 👨‍💻 Tracking Service 👁‍🗨 ' + (payload.tag ? payload.tag : 'unnamed') + ' 🚀 ', 'background: #111; color: lime; width: 100%; padding: 22px; display: block; margin: 0px 0; border: 2px solid lime;');

      console.info((payload.tag ? payload.tag : 'unnamed'));

      console.table(payload.payload);

      if ( payload.context ) {
        // if context type of PointerEvent
        if (payload.context instanceof PointerEvent) {
          console.groupCollapsed('%c 💡 Event Context: PointerEvent(' + payload.context.pointerType + '-' + payload.context.type + ')', 'border: 2px solid #111; border-radius: 6px; padding: 4px; background: #333; color: yellow; font-size: 13px; ' );

          console.info({ element: payload.context.srcElement });

          console.info( payload.context.srcElement );

          console.groupEnd();
        }
      }

      console.trace("%c 🚧 Event Trigger Trace Details" , 'border: 2px solid #111; border-radius: 6px; padding: 4px; background: #333; color: orange; font-size: 13px; ');

      console.info('%c 🔚 Tracking Service 🌎 ' + environment.gTagManager + ' 👁‍🗨 ' + method + ' ⏱️ ' + new Date(), 'background: #000; width: 100%; padding: 16px; display: block; margin: 0px 0;');


      console.groupEnd();

      // Legacy
      // console.info('%c 👨‍💻 Tracking Service 👁‍🗨 ' + method + ' ⚡️ ' + JSON.stringify(payload), 'background: #000; color: lime; font-size: 20px; ');
    }
  }

  public event(tag = 'unnamed', payload: any = {}, context: any = null) {
    this.announce('event()', { tag, payload, context });

    try{ 
      (window as any).gtag('event', tag, payload);
    } catch (err) {
      console.info('%c 👨‍💻 Tracking Service 👁‍🗨 Failed To Capture Event', 'background: #000; color: red; font-size: 20px; ');
    }

  }

  // Helpers

  // convert - create a helper function to convert any text value to lowercase and replace spaces with underscores
  public convert(anyTextValue: any) {
    // convert capital and spaces to lowercase and underscores
    try{
      if ( !anyTextValue ) return '';
      anyTextValue = anyTextValue.toString();
      return anyTextValue.toLowerCase().replace(/ /g, '_');
    } catch (err) {
      return '';
    }

  }

  // compose - create a helper function to compose a tracking tag with replacement of wildcards and tokenized convertions of multiple values array
  public compose(tag: string, replacements: any = []) {
    // replace <*> in string tag with values from replacements array one by one
    let result = tag;
    for (let i = 0; i < replacements.length; i++) {
      result = result.replace('*', this.convert(replacements[i]));
    }

    return result;
  }

  // slugify - combines variables into slug string
  public slugify(...args: any) {
    // convert each arg with this.convert and join them with underscores
    return args.map((arg: any) => this.convert(arg)).join('_');
  }

}
