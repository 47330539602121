import { Component, Input, OnInit } from '@angular/core';

import { DataSourceService } from '@app/-core/services/data-source.service';
import { ModalController } from '@ionic/angular';

import { Router } from '@angular/router';
import { FunnelsCoreService } from '@app/app-v2/funnels/-core/services/funnels-core.service';
import { FormProcessorService } from '@app/-core/services/form-processor.service';
import { AiService } from '@app/-core/services/ai.service';

@Component({
  selector: 'app-funnels-create-modal',
  templateUrl: './funnels-create-modal.component.html',
  styleUrls: ['./funnels-create-modal.component.scss'],
})
export class FunnelsCreateModalComponent implements OnInit {

  @Input() projectId;

  retryInterval;

  data;
  ready = false;

  dataFetchSubscribe: any;

  hoveredThemeDescription: string = '';
  activePage = 0;
  totalPages = 4;

  activeIndex = 1;
  hotStepFunnels = [
    {stepBtn: 'Keto Core'},
    {stepBtn: 'Meal Architect'},
    {stepBtn: 'Strength Crafter'},
    {stepBtn: 'Phase 1: Goal Mapper'},
    {stepBtn: 'Phase 2: Progress Tracker'},
    {stepBtn: 'Phase 3: Recovery Master'},
    {stepBtn: 'Pitfall Buster'},
    {stepBtn: 'Habit Builder'},
    {stepBtn: 'Milestone Celebrator'}
  ];

  activeStep = 0;
  themeMockup = [
    { path: 'themes-mockup', title: 'Balanced', colorOne: '#160DE8', colorTwo: '#F3B1FA', colorThree: '#F7D94B', colorFour: '#E95C32', font: 'Syne', description: 'Best if your offer pricing is $3000-$20K+ - and you have appointment based sales.' },
    { path: 'themes-mockup-two', title: 'Cool', colorOne: '#C0281F', colorTwo: '#F3B1C6', colorThree: '#754833', colorFour: '#BFD6E3', font: 'Montserrat', description: 'Looking for B2B sales solutions with pricing from $3,000 to $20,000+? We offer appointment-based sales that can help you reach your target market.' },
    { path: 'themes-mockup-three', title: 'Playful', colorOne: '#24566F', colorTwo: '#E6FC5C', colorThree: '#EAA4AA', colorFour: '#943265', font: 'Play', description: 'We provide B2B sales solutions with pricing from $3,000 to $20,000+. Our appointment-based sales approach helps you connect with qualified leads and close deals more effectively.' },
    { path: 'themes-mockup-four', title: 'Energetic', colorOne: '#E1C8E9', colorTwo: '#EC6B40', colorThree: '#EEC85D', colorFour: '#80CCBB', colorBg: '#000', colorText: '#fff', font: 'Passion One', description: 'Test text' }
  ];
  selectedTheme = this.themeMockup[0];

  activeTheme = 0;

  loading: any = false;
  loadingInterval: any = null;
  loadingPhraseSet = [
    '...',

    'Launching your funnel now',
    'This thing is going to be amazing',
    'Preparing for world domination',
    'Did you know that the human head weighs 8 pounds?',
    'Fastest land animal?',
    'Cheetah... (You should know that)',
    'One sec',
    'Ok I am back',
    'Needed a quick AI nap',
    'Even AI has to rest, you know…',
    'Putting final touches on your funnel as we speak',
    'Wait for it',
    'Wait for it',
    'Wait for it',
    'Wait for it',
    'Wait for it',
    'Wait for it',
    'Wait for it',
    'Wait for it',
    'Wait for it',
    'Wait for it',
    'Wait for it',
    'Wait for it',
    'Wait for it',
  ];

  welcomeText = '_';

  constructor(
    // public dataSource: DataSourceService,
    public modalController: ModalController,
    public router: Router,
    public funnelsService: FunnelsCoreService,
    // public formProcessor: FormProcessorService,
  ) {

  }

  renderGreeting() {
    return this.welcomeText;
  }

  ngOnInit() {
    this.activePage = 1;
    this.ready = false;
    // this.selectedDescription = this.themeMockup[0].description;
    console.log('projectId', this.projectId);
    this.funnelsService.dataSource.getFunnelOriginList(this.projectId).subscribe( res => {
      this.data = res;

      // filter out a 'website' slug item
      this.data = this.data.filter( item => item.slug !== 'website');
      this.selectActiveItem(res[1]);
      this.data.selectedThemes = 0;

      /*
      const string = ('NICE! Let\'s get started building your funnel!' as string);
      string.split('').forEach((char, index) => {
        setTimeout(() => {
          // return carret back one char
          this.welcomeText = this.welcomeText.slice(0, -1);
          this.welcomeText += char + (index % 2 === 0 ? ' ' : '_');
        }, index * 70);
      });
      setTimeout(() => {
        this.move(1);
      }, (string.length * 70) + 1500 );
      */

      /*
      setTimeout(() => {
        this.activePage = 1;
      }, 3000);
      */
      console.log('Wizard Wrapper Abstraction');
      // this.endLoadingAnimation();
      
      this.ready = true; 

      // this.setActivePage(4);
      
    }, err => {
      this.closeWizard();
    });
  }

  selectActiveItem(item) {
    this.data.selectedItem = item;

    console.log('this.data.selectedItem.roadmapsteps', this.data.selectedItem.roadmapsteps);
    try {
      // this.data.selectedItem.roadmapsteps
      this.setActiveStep(this.data.selectedItem.roadmapsteps[0].order);
    } catch (err) {
    }

    this.data.selectedThemes = 0;
  }
  
  getPlaceholderUrl(): string {
    const selectedImgTheme = this.data.selectedItem.themes_data[this.data.selectedThemes];
    return selectedImgTheme ? 'url(' + selectedImgTheme.placeholder + ')' : '';
  }

  move(dir) {
    if ( !!dir ) {
      this.activePage ++;
    } else {
      this.activePage --;
    }
    this.setActivePage(this.activePage, dir);
  }

  setActivePage(index, direction) {
    
    this.ready = false;

    this.activePage = index;
    if ( this.activePage === 0 ) {
      this.closeWizard();
    }
    if ( this.activePage === 2 ) {
      console.log('this.data.slug', this.data);
      // Select Hotstep, ignored step if slug not hight ticket so we will slide this step over
      // check if dataslug contains 'high_ticket'
      if ( this.data.selectedItem.skip_hotsptep ) {
        setTimeout(() => {
          this.move(direction);
        }, 200);
        // this.move(direction);
      }
    }
    if ( this.activePage === 3 ) {
      // Load Dynamic Font Data
      // based on selected theme [theme_preview]
      // this.data.selectedItem.themes_data[this.data.selectedThemes].theme_preview
      /*
      {
          "font": {
              "color": "#FFF",
              "weight": "400",
              "family:": "Roboto"
          },
          "background": "#000",
          "color_stack": [
              "#FFF",
              "#AAA",
              "#777"
          ]
      }
      */
      console.log('this.data.selectedItem.themes_data', this.data.selectedItem.themes_data);
      this.data.selectedItem.themes_data.forEach( (item, index) => {
        console.log('item', item);
        if ( item && item.theme_preview && item.theme_preview.font && item.theme_preview.font.family ) {
          const font = item.theme_preview.font.family;
          const fontName = font; // Your font name
          const fontUrl = 'https://fonts.googleapis.com/css2?family=' + fontName + ':wght@300;400;500;600;700;800;900&display=swap'; // The fully qualified url of your font
          const styleDom = document.createElement('style');
          styleDom.innerText = '.font-' + fontName.replace(/\s/g, '') + ' { font-family: "' + fontName + '", serif; }';
          document.head.appendChild(styleDom);
          const linkDom = document.createElement('link');
          linkDom.setAttribute('rel', 'stylesheet');
          linkDom.setAttribute('crossorigin', 'anonymous');
          linkDom.setAttribute('href', fontUrl);
          console.log('Injecting Dynamic Font: ', linkDom);
          document.head.appendChild(linkDom);
        }
      });
  
      // this.formProcessor.loadAndSetDynamicFontSet(fontStack);

    }
    if ( this.activePage === 4 ) {
      setTimeout(() => {
        this.ready = true;

        this.startLoadingAnimation();

        // find first is_active: true in .variations
        const activeColor = this.data.selectedItem.themes_data[this.data.selectedThemes].variations.find( item => item.is_active === 1 );

        // console.log('Prepare Data for Funnel Creation', this.projectId, this.data.selectedItem, this.data.selectedThemes, activeColor, this.data.selectedItem.themes_data[this.data.selectedThemes], this.data);

        this.funnelsService.dataSource.createFunnel(
          this.projectId, 
          this.data.selectedItem.slug, 
          this.data.selectedItem.themes_data[this.data.selectedThemes].slug, 
          activeColor.slug,
          this.activeStep,
        ).subscribe( (res) => {
          console.log('this.dataSource.createFunnel', res);

          this.checkLandingBackendBuilt(res.id);
        });

        return;
        /*
        this.dataSource.createFunnel(this.id, resultData.data.funnelSlug, resultData.data.themeSlug, resultData.data.colorSlug).subscribe( (res) => {
          console.log('this.dataSource.createFunnel', res);
          setTimeout( () => {
            this.router.navigate(['/app/funnel/' + this.id + '/view/' + res.id + '/map']);
          }, 50);
        });
        */

      }, 500);
    }

  }

  checkLandingBackendBuilt(funnelId) {
    clearTimeout(this.retryInterval);

    const projectId = this.projectId; //
    
    this.dataFetchSubscribe = this.funnelsService.dataSource.getFunnelDetails(projectId, funnelId).subscribe( res => {

      if ( res && res.map_status === 'READY' ) {
        setTimeout( () => {
          this.closeWizard();
          this.router.navigate(['/app/funnel/' + projectId + '/view/' + res.id + '/map'], { queryParams: { generated_at: + new Date() } }).then(()=>{
            // do whatever you need after navigation succeeds
            setTimeout( () => {
              (window as any).location.reload();
            });
          });
        }, 2000);
      } else {
        this.retryInterval = setTimeout( () => {
          this.checkLandingBackendBuilt(funnelId);
        }, 5000);
      }

      // console.log('FUNNELS :: setActiveProject', this.activeFunnelData);
    }, err => {

      console.log('FUNNELS :: Error / Wait');
      this.retryInterval = setTimeout( () => {
        this.checkLandingBackendBuilt(funnelId);
      }, 5000);

    });
  }

  renderState(index) { // render center +- block renderer
    return index === this.activePage || index + 1 === this.activePage || index - 1 === this.activePage;
  } 

  dismiss() {
    this.closeWizard();
  }

  setActiveFunnel(index: number) {
    this.activeIndex = index;
    // console.log(index);
  }

  setActiveStep(index: number) {
    this.activeStep = index;
  }

  selectTheme(index: number) {
    this.selectedTheme = this.themeMockup[index];
    // this.selectedDescription = this.themeMockup[index].description;
    this.activeTheme = index;
  }



  closeWizard() {
    this.endLoadingAnimation();
    this.modalController.dismiss();
    if ( this.dataFetchSubscribe ) {
      this.dataFetchSubscribe.unsubscribe();
    }
    
  }

  currentLoadingPhrase() {
    if ( this.loading ) {
        return this.loadingPhraseSet[this.loading];
    } else {
        return this.loadingPhraseSet[0];
    }
  }

  startLoadingAnimation() {
    this.endLoadingAnimation();
    this.loading = 1;
    clearInterval(this.loadingInterval);
    this.loadingInterval = setInterval( () => {
        this.loading++;
        if (this.loading >= this.loadingPhraseSet.length) {
          this.loading = 1;
        }
    }, 2500);
  }

  endLoadingAnimation() {
    clearInterval(this.loadingInterval);
    this.loading = false;
  }

}

/*
"theme_preview": {
    "font": {
        "color": "#FFF",
        "weight": "400",
        "family:": "Roboto"
    },
    "background": "#000",
    "color_stack": [
        "#FFF",
        "#AAA",
        "#777"
    ]
}
*/