<div class="content">

  <div class="box-loadingbox">

    <div *ngIf="aiAnimation" class="ai-loading">
      <gw-ai-blob [quality]="'hq'" [processing]="true"></gw-ai-blob>
    </div>

    <div *ngIf="!aiAnimation" class="lines">

      <!-- Lines -->

      <div class="line active-loadingbox">WELL DONE!!</div>

      <ng-container *ngFor="let i of [1,2,3,4,5]">
        <div class="line">Launching your asset build process</div>
        <div class="line">Waking up the designer</div>
        <div class="line">Getting your designer some coffee</div>
        <div class="line">Checking out your plan and strategy</div>
        <div class="line">Hey - not bad</div>
        <div class="line">Checking the flux capacitor</div>
        <div class="line">Ok just kidding…</div>
        <div class="line">But let’s be real here</div>
        <div class="line">Where else can you get 310 slides done in under 30 seconds!</div>
        <div class="line">Wait for it…</div>
        <div class="line">Wait for it…</div>
        <div class="line">Wait for it…</div>
        <div class="line">HOLD!</div>
        <div class="line">HOLD!</div>
        <div class="line">HOLD!</div>
        <div class="line">HOLD!</div>
        <div class="line">HOLD!</div>
        <div class="line">Keep Holding!</div>
        <div class="line">Ok this feels like a really long time...</div>
        <div class="line">BUT...</div>
        <div class="line">Depending on the asset you are creating...</div>
        <div class="line">We are generating over 1000 slides...</div>
        <div class="line">That you don’t have to do yourself...</div>
        <div class="line">SERIOUSLY!</div>
        <div class="line">It can take us up to three minutes to do this which we acknowledge...</div>
        <div class="line">Can feel like an eternity when staring at the screen...</div>
        <div class="line">So let me do this..</div>
        <div class="line">I am going to go back and make sure the designer is on this for you...</div>
        <div class="line">Hang in there another 120 seconds...</div>
        <div class="line">And as they use to say in the old days...</div>
        <div class="line">Have a sandwich while you wait (Shout out to the old folks)...</div>


      </ng-container>

    </div>

  </div>

</div>
