<div id="aiBlobFrame" [ngClass]="{ 'no-border' : noBorder }">

    <div class="ai-blob-playback" [ngClass]="{ 'ai-processing-animation' : processing }">

      <div class="container ai-blob-backdrop">
        <div class="inner calm-state"><video #video1 autoplay muted loop preload="metadata"><source src="/assets/ai-blob/state-calm-{{ quality }}.webm" type="video/webm"></video></div>
        <div class="inner active-state"><video #video2 autoplay muted loop preload="metadata"><source src="/assets/ai-blob/state-calm-{{ quality }}.webm" type="video/webm"></video></div>
      </div>

    </div>

</div>