<div cdkDropListGroup class="sam-drag-and-drop-grid">
  <div cdkDropList #placeholder [cdkDropListEnterPredicate]="enter" (cdkDropListDropped)="drop()"
       [ngStyle]="{'max-width': 100/columnNumber + '%'}">
  </div>
  <div cdkDropList *ngFor="let item of items" class="sam-drop-list-item" [ngClass]="{'move' : !disabled}"
       [ngStyle]="{'max-width': 100/columnNumber + '%'}"
       [cdkDropListEnterPredicate]="enter" (cdkDropListDropped)="drop()">
    <div [cdkDragDisabled]="disabled" cdkDrag (cdkDragMoved)="dragMoved($event)" class="sam-drag-container">
      <ng-container samContentOutlet></ng-container>
    </div>
  </div>
</div>
