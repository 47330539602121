import { Component, OnInit, DoCheck, ViewChild } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { DataSourceService } from '@services/data-source.service';
import { AuthService } from '@services/auth.service';

import { FormProcessorService } from '@services/form-processor.service';

import { KeyValueChanges, KeyValueDiffer, KeyValueDiffers } from '@angular/core';
import { IonContent } from '@ionic/angular';

import { Observable } from 'rxjs';

import { NabuApiService } from '@services/api/nabu-api.service';

import { environment } from '@env/environment';
import { PopoverController, ModalController } from '@ionic/angular';

import { ChatBarPopoverComponent } from './legacy/popover/popover.component';

@Component({
  selector: 'app-chat-bar',
  templateUrl: './chat-bar.component.html',
  styleUrls: ['./chat-bar.component.scss'],
})
export class ChatBarComponent implements OnInit {

  initTinyMceMain = {
    base_url: '/tinymce',
    suffix: '.min',
    skin: 'oxide',
    height: 196,
    content_style: 'body { padding-top: 0px; line-height: 20px; } body p { margin: 0px; } a { font-weight: bold; color: #3E3BFF; background-color: #FFFFFF!important; text-decoration: none; }',
    placeholder: 'Start typing...',
    menubar: false,
    extended_valid_elements: 'tip[data-tip],span,highlight,b,i,u,span[style],p',
    custom_elements: 'tip,highlight',
    paste_preprocess: function(plugin, args) {
      var urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
      args.content = args.content.replace(urlRegex, function(url) {
          return '<a target="_blank" href="' + url + '">' + url + '</a>';
      });
      args.content += ' ';
    },
    default_link_target: '_blank',
    link_default_protocol: 'https',
    paste_as_text: true,
    smart_paste: true,
    plugins: [
      'autolink link paste lists image charmap print preview anchor',
      'searchreplace visualblocks fullscreen',
      'insertdatetime media table code help wordcount emoticons'
    ],
    toolbar:
      'bold italic numlist bullist blockquote emoticons '
  };
  initTinyMceMainLarge;

  hydrated = false;

  isToggled = false;

  toggleWidth = true;
  // public projcetId = null;
  public campaingList;
  public coachList = [];
  public tinyMce = false;

  private customerDiffer: KeyValueDiffer<string, any>;
  public messages = [];
  public message = '';
  public chatId = null;

  public coachId = null;
  public coach: any = {};
  public clientId = null;

  env = environment;

  limit = 10;

  totalUnreads = 0;

  @ViewChild(IonContent) chat: IonContent;

  constructor(
    public dataSource: DataSourceService,
    private router: Router,
    private route: ActivatedRoute,
    public auth: AuthService,
    private differs: KeyValueDiffers,
    public api: NabuApiService,
    public popoverController: PopoverController,
    public modalController: ModalController,
    public formProcessor: FormProcessorService,
  ) { 
    this.initTinyMceMainLarge = JSON.parse(JSON.stringify(this.initTinyMceMain));
    this.initTinyMceMainLarge.height = 258;
  }

  async presentPopover(ev: any) {

    ev.preventDefault();

    const popover = await this.modalController.create({
      component: ChatBarPopoverComponent,
      cssClass: 'application-v2 modal-v2 quick-heads-up-modal',
      // event: ev,
      // translucent: true,
      // showBackdrop: false,
      componentProps: {
        cmps: this.campaingList
      }
    });
    await popover.present();

    const res = await popover.onDidDismiss();
    if ( res.data && res.data.item ) {
      console.log('onDidDismiss resolved with role', res.data.item);
      this.dataSource.setCurrentActiveProject(res.data.item, true);
      // this.router.navigate(['/app/project/' + res.data.item.id], { replaceUrl: true });
    }


  }

  activeCampaing() {
    return this.campaingList.find( (x) => x.id === parseInt(this.dataSource.coachingData.projectId, 10) ) || { id: 0, title: '' };
  }

  activeCampaingId() {
    return this.campaingList.find( (x) => x.id === parseInt(this.dataSource.coachingData.projectId, 10) ).id || '';
  }

  ngOnInit() {
    this.customerDiffer = this.differs.find(this.dataSource.coachingData).create();

    this.listCampaing();

    setInterval( () => {
      console.log('Chatbar check unreads');

      this.loadUnreadsTotal();


      this.getCoachesList().subscribe( (res) => {
        this.coachList = res;

        this.coachList.forEach( (x) => {
          // console.log('x', x, x.id, this.coachId);
          if ( x.id === this.coachId ) {
            // console.log('Found Index', x.campaigns, this.campaingList);

            x.campaigns.forEach( (v, index) => {
              x.campaigns[index].title = v.name;
            });

            console.log('Found Index', x.campaigns, this.campaingList);

            this.campaingList = JSON.parse(JSON.stringify(x.campaigns));
            // console.log(this.campaingList);
          }
        });
      });


    }, 60 * 1000);

    this.loadUnreadsTotal();

  }

  public filterCampaings(list) {
    return list.filter( (x) => x.unread > 0 );
  }

  public loadUnreadsTotal() {
    // -
    // this.totalUnreads = 32;
    // GET /api/v1/coachcenter/chats/coach/unread-messages/
    // GET /api/v1/coachcenter/chats/client/unread-messages/

    let observable;

    if ( !this.dataSource.isCoaching ) {
      observable = this.api.get('api/v1/coachcenter/chats/client/unread-messages/', {}, {}, {preloader: false});
    } else {
      observable = this.api.get('api/v1/coachcenter/chats/coach/unread-messages/', {}, {}, {preloader: false});
    }

    observable.subscribe( (res) => {

      if ( ( res.unread !== this.totalUnreads ) && this.isToggled ) {
        this.loadMessages(false);
      } // -

      if ( ( res.unread !== this.totalUnreads ) && !this.isToggled ) {
        this.toggle();
      }

      this.totalUnreads = res.unread;

    });
  }

  public setCoach(coach) {
    this.coach = coach;
    this.coachId = this.coach.id;
    this.loadMessages();
  }

  public openProject(cmp, coach) {

    // this.router.navigate(['/app/project/' + cmp.id], { replaceUrl: true });
    this.dataSource.setCurrentActiveProject(cmp, true);
    setTimeout( () => {
      this.setCoach(coach);
    });

  }

  // Http Requests For Panel -
  public postConversationsStartChat(target, campaign): Observable<any> { // API
    let observable;

    if ( this.coachId ) {
      observable = this.api.get('api/v1/coachcenter/client/start-chat/' + target + '/' + campaign + '/', {}, {}, {preloader: false});
    } else {
      observable = this.api.get('api/v1/coachcenter/start-chat/' + target + '/' + campaign + '/', {}, {}, {preloader: false});
    }

    return observable;
  }

  public getMessagesHybrid(target, campaign, limit): Observable<any> { // API
    let observable;

    if ( this.coachId ) {
      observable = this.api.get('api/v1/coachcenter/client/chats/' + target + '/' + campaign + '/', {limit}, {}, {preloader: false});
    } else {
      observable = this.api.get('api/v1/coachcenter/client/' + target + '/campaign-chat/' + campaign + '/', {limit}, {}, {preloader: false});
    }

    return observable;
  }


  public getCoachesList(): Observable<any> {
    const observable = this.api.get('api/v1/coachcenter/client/coaches/list/', {}, {}, {preloader: false});
    return observable;
  }

  public getDiscussionData(id): Observable<any> {
    const observable = this.api.get('api/v1/coachcenter/client/chats/' + id + '/details/', {limit: 10, page: 1}, {}, {preloader: false});
    return observable;
  }
  // api/v1/coachcenter/client/chats/2/create-message/
  public postDiscussionMessageData(id, data): Observable<any> { // API
    let observable;

    if ( this.coachId ) {
      observable = this.api.post('api/v1/coachcenter/client/chats/' + id + '/create-message/', data, {}, {preloader: false});
    } else {
      observable = this.api.post('api/v1/coachcenter/chats/' + id + '/create-message/', data, {}, {preloader: false});
    }

    return observable;
  }

  public getDiscussionDataLimit(id, data): Observable<any> { // API
    const observable = this.api.get('api/v1/coachcenter/client/chats/' + id + '/details/', data, {}, {preloader: false});
    return observable;
  }

  customerChanged(changes: KeyValueChanges<string, any>) {
    console.log('changes');
    /* If you want to see details then use
      changes.forEachRemovedItem((record) => ...);
      changes.forEachAddedItem((record) => ...);
      changes.forEachChangedItem((record) => ...);
    */
    changes.forEachChangedItem((record) => {
      console.log('record', record);
      if ( record.key === 'projectId' ) {
        this.loadMessages();
      }
    });

    setTimeout( () => {
      this.tinyMce = true;
    }, 1000);
  }

  ngDoCheck(): void {
    const changes = this.customerDiffer.diff(this.dataSource.coachingData);
    if (changes) {
      this.customerChanged(changes);
      this.tinyMce = false;
    }
  }

  public toggle() {
    if ( this.isToggled ) {
      this.isToggled = !this.isToggled;

      this.coachId = null;
      this.clientId = null;

    } else {
      // this.ngOnInit();

      // Decide whats Going On
      if ( this.dataSource.isCoaching ) {
        this.clientId = this.dataSource.isCoaching;
        // Iam Coach
        // coach

        this.listCampaing();
      } else {
        // Iam Client
        this.coachId = null;
        this.getCoachesList().subscribe( (res) => {
          this.coachList = res;
        });
      }

      console.log('clientId', this.clientId);
      console.log('coachId', this.coachId);

      this.listCampaing();

      this.limit = 10;

      setTimeout( () => {
        this.isToggled = !this.isToggled;

        try {
          this.chat.scrollToBottom(300);
        } catch (err) {
          // -
        }

      }, 200);

      this.loadMessages();

      /*
      this.listCampaing();
      this.loadMessages();
      */
    }
  }

  listCampaing() {
    this.dataSource.listCampaing().subscribe( (res) => {

      this.campaingList = res;

      this.hydrated = true;

      // this.isToggled = !this.isToggled;

      try {
        // this.projcetId = this.route.snapshot.params.id;
      } catch (err) {
        // -
      }

    }, (err) => {
      setTimeout( () => {

        this.listCampaing();

      }, 5000);
    });
  }

  loadMessages(wipe = true, scroll = true) {
    if ( !this.dataSource.coachingData.projectId ) {
      setTimeout( () => {
        this.loadMessages(wipe, scroll);
      }, 500); // Wait Untill Project ID is exists
      return;
    }
    if ( !this.clientId && !this.coachId ) {
      return false;
    }
    if ( wipe === true ) {
      this.messages = [];
    }
    let targetId;
    if ( this.clientId ) {
      targetId = this.clientId;
    } else {
      targetId = this.coachId;
    }


    try{
      this.coach.is_active = true;
    }catch(err) {

    }

    this.postConversationsStartChat(targetId, this.dataSource.coachingData.projectId).subscribe( (s) => {
      this.chatId = s.id;
      this.getMessagesHybrid(targetId, this.dataSource.coachingData.projectId, this.limit).subscribe( (res) => {
        console.log('isRes', res);
        this.messages = res.messages.reverse();
        // this.chatId = res.id;
        if ( this.clientId ) {
          this.coach = {
            first_name: res.client.full_name,
            avatar: res.client.avatar,
            is_active: true,
          }
        } else {
          // console.log('xxx', this.coach);
          this.coach.is_active = res.coach.is_active;
          console.log('this.coach', this.coach);
        }

        if ( res.campaigns_list && res.campaigns_list.length ) {
          this.campaingList = res.campaigns_list;
        }

        setTimeout( () => {
          try {
            if ( scroll ) {
              this.chat.scrollToBottom(300);
            }
          } catch (err) {
            // -
          }
        }, 50);
      });
    });
  }

  // putMessagesCoachPreview

  changeProject(ev) {

    console.log(ev, ev.detail.value);
    // this.router.navigate(['/app/dashboard/' + ev.detail.value], { replaceUrl: true });
    // this.dataSource.setCurrentActiveProject(ev.detail.value, true);
  }

  sendMessage() {
    /*
    {
      "plan": int,
      "plan_step": int,
      "asset": int,
      "asset_step": int
      text
    }
    */
    const data = {
      text: this.message,

      plan: '',
      plan_step: '',
      asset: '',
      asset_step: '',
      link: '',

      frontend_tag: this.dataSource.coachingData.tag || ''
    };

    // legacy script navigation
    if ( this.dataSource.coachingData.assetId ) {
      data.asset = this.dataSource.coachingData.assetId;
      data.asset_step = this.dataSource.coachingData.assetStep;
    } else {
      if ( this.dataSource.coachingData.assetLink ) {
        data.link = this.dataSource.coachingData.assetLink;
        data.asset = '0';
        // data.asset_step = this.dataSource.coachingData.assetStep;
      }
    }



    this.postDiscussionMessageData(this.chatId, data).subscribe( (res) => {
      this.loadMessages(false);
    });

    this.message = '';

  }

  // availi

  isTagV2(item) {
    if (item && item.asset && item.asset[0] === '/') {
      return true;
    } else {
      return false;
    }
  }

  recoverTag(item) {

    console.log('recoverTag', item);

    if ( item.link ) {

      this.router.navigate([ item.link ], { queryParams: { }, replaceUrl: true });

    } else {

      if ( item.asset ) {
        this.router.navigate(['/app/script/' + item.asset ], { queryParams: { index: ( parseInt(item.asset_step, 10) + 1 ) }, replaceUrl: true });
      } else {
        // this.router.navigate(['/app/plan/' + item.plan ], { queryParams: { template: item.plan_template, step: item.plan_step }, replaceUrl: true });
      }

    }
  }

  logScrollingChat(ev) {
    if ( ev.detail.currentY === 0 && ev.detail.velocityY < 0 ) {
      console.log('ev', ev);
      this.limit += 3;
      this.loadMessages(false, false);
      setTimeout( () => {
        this.chat.scrollByPoint(0, 10, 100);
      }, 200);
    }
  }

  triggerEmoticons() {
    // title="Emoticons"
    try{
      const elem = (window as any).document.querySelector('[title="Emoticons"]');
      elem.click();
      console.log(elem);
    } catch (err) {

    }
  }


}
