<div class="no-result-banner">
<svg xmlns="http://www.w3.org/2000/svg" width="52" height="74" viewBox="0 0 52 74" fill="none">
	<g clip-path="url(#clip0_11143_33175)">
	<path d="M41.5536 6.71951L12.824 0.761719L0.767578 10.2015L19.3208 47.9762L17.3819 73.2349L29.4383 63.7952L51.232 27.7851L37.6812 24.8299L41.5536 6.71951Z" fill="white"/>
	<path d="M17.3822 73.4994C17.339 73.4997 17.2965 73.4888 17.259 73.4678C17.2067 73.4446 17.1633 73.4054 17.1353 73.3561C17.1074 73.3068 17.0963 73.2499 17.1037 73.1939L19.0372 48.009L0.526783 10.3132C0.496293 10.2583 0.487088 10.1944 0.500879 10.1334C0.51467 10.0724 0.55052 10.0183 0.601767 9.98132L12.6582 0.557377C12.6888 0.533692 12.7243 0.516737 12.7622 0.507651C12.8 0.498565 12.8395 0.497559 12.8778 0.5047L41.6128 6.46249C41.6471 6.46934 41.6797 6.48289 41.7086 6.50233C41.7376 6.52177 41.7622 6.54671 41.7812 6.57567C41.8002 6.60464 41.813 6.63705 41.8191 6.67098C41.8251 6.70492 41.8242 6.7397 41.8163 6.77328L37.9921 24.6308L51.2858 27.5281C51.3276 27.5359 51.3667 27.554 51.3995 27.5807C51.4323 27.6074 51.4577 27.6419 51.4732 27.6808C51.4917 27.7177 51.5012 27.7583 51.5012 27.7994C51.5012 27.8405 51.4917 27.881 51.4732 27.9179L29.6689 63.9333C29.6536 63.9594 29.6337 63.9826 29.61 64.0017L17.5482 73.4415C17.5009 73.4783 17.4425 73.4987 17.3822 73.4994ZM1.09988 10.2868L19.5621 47.8615C19.5752 47.9062 19.5752 47.9537 19.5621 47.9984L17.6982 72.6671L29.235 63.633L50.8144 27.9653L37.6226 25.0891C37.5539 25.074 37.4941 25.0329 37.456 24.9748C37.418 24.9166 37.4047 24.846 37.419 24.7783L41.2379 6.92078L12.8938 1.0262L1.09988 10.2868Z" fill="black"/>
	<path d="M25.6196 34.2689L37.6814 24.8292L41.5538 6.71875L29.4974 16.1585L25.6196 34.2689Z" fill="white"/>
	<path d="M25.6196 34.5354C25.5701 34.5343 25.5219 34.5197 25.4803 34.4932C25.4335 34.4644 25.3966 34.4223 25.3746 34.3725C25.3526 34.3226 25.3465 34.2674 25.3571 34.2141L29.2349 16.1037C29.251 16.0445 29.2845 15.9914 29.3313 15.9509L41.3877 6.51641C41.4333 6.48537 41.4875 6.46875 41.543 6.46875C41.5985 6.46875 41.6527 6.48537 41.6983 6.51641C41.7459 6.54426 41.7831 6.58647 41.8043 6.63678C41.8256 6.68709 41.8297 6.74279 41.8162 6.7956L37.9384 24.8849C37.9287 24.9453 37.8962 24.9999 37.8473 25.0377L25.7856 34.4774C25.7383 34.5143 25.6799 34.5346 25.6196 34.5354ZM29.7383 16.3038L26.0373 33.6083L37.4564 24.6848L41.1627 7.38032L29.7383 16.3038Z" fill="black"/>
	<path d="M51.2321 27.7872L37.6814 24.832L25.6196 34.2718L39.1757 37.227L51.2321 27.7872Z" fill="white"/>
	<path d="M39.1759 37.4835H39.117L25.5609 34.5231C25.5123 34.5129 25.4676 34.4891 25.4325 34.4545C25.3973 34.4199 25.3731 34.376 25.3627 34.3282C25.3497 34.2795 25.3513 34.2282 25.3675 34.1804C25.3836 34.1327 25.4137 34.0907 25.4538 34.0595L37.5102 24.625C37.5413 24.6003 37.5777 24.5827 37.6166 24.5736C37.6555 24.5645 37.696 24.564 37.7351 24.5723L51.2912 27.5275C51.3403 27.538 51.3854 27.5618 51.4214 27.5963C51.4574 27.6307 51.4828 27.6744 51.4948 27.7224C51.5053 27.7716 51.5019 27.8227 51.4849 27.8701C51.4679 27.9174 51.4379 27.9593 51.3983 27.9911L39.342 37.4308C39.2941 37.4661 39.2357 37.4847 39.1759 37.4835ZM26.209 34.128L39.0956 36.9357L50.611 27.9226L37.7566 25.1149L26.209 34.128Z" fill="black"/>
	<path d="M29.4973 16.1609L0.767578 10.2031V42.2572L17.3819 46.308V73.2366L39.1756 37.2265L25.6195 34.2713L29.4973 16.1609Z" fill="#FCDA48"/>
	<path d="M17.3822 73.499H17.3126C17.256 73.484 17.2059 73.4511 17.1701 73.4055C17.1343 73.3598 17.1148 73.3038 17.1144 73.2461V46.5125L0.703593 42.5143C0.645543 42.4992 0.594151 42.4658 0.557362 42.4191C0.520573 42.3725 0.500436 42.3152 0.500064 42.2562V10.2021C0.497272 10.1599 0.505094 10.1176 0.522841 10.079C0.540588 10.0404 0.567714 10.0067 0.601829 9.98087C0.631723 9.95586 0.667149 9.93807 0.705277 9.92893C0.743406 9.91978 0.78318 9.91953 0.821425 9.92819L29.5511 15.9018C29.5859 15.908 29.6191 15.9211 29.6486 15.9403C29.6781 15.9595 29.7034 15.9843 29.7229 16.0134C29.7424 16.0424 29.7558 16.075 29.7621 16.1092C29.7685 16.1435 29.7678 16.1786 29.76 16.2126L25.9358 34.0701L39.2348 36.9674C39.2762 36.9761 39.315 36.9945 39.3476 37.0211C39.3803 37.0477 39.4059 37.0817 39.4222 37.1201C39.4407 37.157 39.4503 37.1976 39.4503 37.2387C39.4503 37.2798 39.4407 37.3203 39.4222 37.3572L17.6393 73.3726C17.6131 73.4155 17.575 73.4502 17.5295 73.4726C17.4839 73.495 17.4329 73.5041 17.3822 73.499ZM1.03567 42.0507L17.4465 46.0542C17.5069 46.0691 17.5598 46.1051 17.5953 46.1555C17.6307 46.2059 17.6464 46.2672 17.6393 46.3281V72.2979L38.7581 37.3993L25.5609 34.5232C25.4922 34.508 25.4324 34.4669 25.3944 34.4088C25.3563 34.3506 25.343 34.2801 25.3573 34.2124L29.1815 16.3601L1.03567 10.5076V42.0507Z" fill="black"/>
	</g>
	<defs>
	<clipPath id="clip0_11143_33175">
	<rect width="51" height="73" fill="white" transform="translate(0.5 0.5)"/>
	</clipPath>
	</defs>
	</svg>

	<div class="headline">
		No Results
	</div>

	<small>
		Generate new content with quick actions or type your customs!
	</small>

</div>