import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthPageModule } from './auth/auth.module';
import { environment } from '../environments/environment';

const routes: Routes = [

  /*{
    path: 'app',
    loadChildren: () => import('./app/app.module').then( m => m.AppPageModule)
  },*/
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then( m => m.AuthPageModule)
  },
  {
    path: '404',
    loadChildren: () => import('./error/page404/page404.module').then( m => m.Page404PageModule)
  },

  // Share Canvas
  {
    path: 's',
    loadChildren: () => import('./app-v2/pages/editor/pdf-editor.module').then( m => m.PdfEditorPageModule)
  },

  {path: '**', redirectTo: '/404'},

];


if ( !environment.production ) {
  routes.push({
    path: 'ui',
    loadChildren: () => import('./ui-kit/uikit.module').then( m => m.UiKitModule)
  });
}


if ( environment.version === 2 ) {
  routes.unshift({
    path: 'app',
    loadChildren: () => import('./app-v2/app-v2.module').then( m => m.AppV2PageModule)
  });
} else {
  routes.unshift({
    path: 'app',
    loadChildren: () => import('./app/app.module').then( m => m.AppPageModule)
  });
}


@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'reload',
      relativeLinkResolution: 'legacy'
    }),
    AuthPageModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
