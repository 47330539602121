<ion-header>
  <ion-toolbar>
    <ion-title>Notifications</ion-title>
    <ion-buttons slot="end">
      <ion-button color="dark" (click)="dismiss()">
         <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content color="white" class="ion-padding">

<div class="attention">
  <img src="assets/v2/icons/attention.png">

  <b>
    No notifications
  </b>

  <p>
    New notifications will appear here when there’s activity in your campaigns
  </p>

</div>

</ion-content>
