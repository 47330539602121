import { Component, OnInit, Input } from '@angular/core';

import { AuthService } from '@services/auth.service';

import { PopoverController } from '@ionic/angular';
import { NavBarPopoverComponent } from './../nav-bar-popover/nav-bar-popover.component';
import { DataSourceService } from '@services/data-source.service';

import { environment } from '@env/environment';

import { FormProcessorService } from '@services/form-processor.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {

  @Input() logo;
  env = environment;

  constructor(
    public auth: AuthService,
    public popoverController: PopoverController,
    public dataSource: DataSourceService,
    
    public formProcessor: FormProcessorService,
  ) { }

  ngOnInit() {}

  async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: NavBarPopoverComponent,
      event: ev,
      // translucent: true,
      // mode: 'ios',
      showBackdrop: false,
      componentProps: {
        dismiss: () => {
          popover.dismiss();
        },
      },
    });
    return await popover.present();
  }

}
