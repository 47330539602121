
  <ion-icon class="" *ngIf="state === false" color="danger" name="alert-circle"></ion-icon>
  <ion-icon class="" *ngIf="state === true" color="success" name="checkmark-circle"></ion-icon>

  <div class="state" [ngClass]="{ 'state-valid' : state === true, 'state-invalid' : state === false }">
    <ng-content></ng-content>
  </div>
  
  <ion-text color="danger" *ngIf="message && state === false">{{ message }}</ion-text>

