<ion-header>
  <ion-toolbar>
    <ion-title><b>Info Video</b></ion-title>
    <ion-buttons slot="end">
      <ion-button shape="round" color="dark" (click)="dismiss()">
         <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">

  <div class="ion-margin-bottom" *ngIf="data">
    <iframe [src]=" '//fast.wistia.net/embed/iframe/' + data.split('/medias')[1] | safe: 'resourceUrl' " allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" allowfullscreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen width="100%" height="350"></iframe>
  </div>

</ion-content>
