import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AuthService } from '@app/-core/services/auth.service';

@Component({
  selector: 'app-website-hit-limit',
  templateUrl: './website-hit-limit.component.html',
  styleUrls: ['./website-hit-limit.component.scss'],
})
export class WebsiteHitLimitComponent implements OnInit {

  constructor(
    public modalCtrl: ModalController,
    public authService: AuthService
  ) { }

  ngOnInit() {}

  dismiss() {
    this.modalCtrl.dismiss({
      dismissed: true
    });
  }

  ok() {

    // https://secure.growthworks.io/ai-credit-packs/?passthrough[customer_email]=EMAIL
    const link = 'https://secure.growthworks.io/ai-credit-packs/?passthrough[customer_email]='+(this.authService.data.email || '');

    window.open(link, '_blank');

    this.modalCtrl.dismiss({
      forward: true
    });
  }
  
}
