import { Component, OnInit, Input } from '@angular/core';
import { PopoverController, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class ChatBarPopoverComponent implements OnInit {

  @Input() cmps;

  constructor(
    public popoverController: PopoverController,
    public modalController: ModalController,
  ) { }

  ngOnInit() {}

  select(item) {
    console.log(item);

    this.modalController.dismiss({
      item
    });
  }

}
