import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AuthService } from '@app/-core/services/auth.service';
import { Tracking } from '@tracking';

@Component({
  selector: 'app-ai-hit-limit',
  templateUrl: './ai-hit-limit.component.html',
  styleUrls: ['./ai-hit-limit.component.scss'],
})
export class AiHitLimitComponent implements OnInit {

  constructor(
    public modalCtrl: ModalController,
    public authService: AuthService,
    public tracking: Tracking,
  ) { }

  ngOnInit() {

    this.tracking.event('ai_buy_credits_popup_opened', { event_category: 'Generate AI', event_label: 'Location: AI Credits Limit Reached Popup' });

  }

  dismiss() {
    this.modalCtrl.dismiss({
      dismissed: true
    });
  }

  ok() {

    // https://secure.growthworks.io/ai-credit-packs/?passthrough[customer_email]=EMAIL
    // this.ai.redirectToBuyCredits();

    this.modalCtrl.dismiss({
      forward: true
    });
  }

}
