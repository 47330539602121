import { Component, OnInit, Input } from '@angular/core';

import { ElementRef } from '@angular/core';

@Component({
  selector: 'app-tooltip-large',
  templateUrl: './tooltip-large.component.html',
  styleUrls: ['./tooltip-large.component.scss'],
})
export class TooltipLargeComponent implements OnInit {

  tooltip: string = '';
  left: number = 0;
  top: number = 0;
  tooltipWidth: any = '360';

  renderTail = true;

  constructor(private elRef:ElementRef) {}

  ngOnInit(): void {

    const rect = this.elRef.nativeElement;
    const bound = rect.getBoundingClientRect();
    console.log('rect', {bound}, this.left, this.top);

    // Fixed Window Offset
    if ( this.left + (this.tooltipWidth / 2) > window.innerWidth ) {
      this.left = window.innerWidth - (this.tooltipWidth / 2);
      this.renderTail = false;
    }

    if ( this.left - (this.tooltipWidth / 2) < 0 ) {
      this.left = window.innerWidth + (this.tooltipWidth / 2);
      this.renderTail = false;
    }

  }

}
