import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { MomentModule } from 'ngx-moment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { PdfViewerModule } from 'ng2-pdf-viewer';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
// import { QuillModule } from 'ngx-quill';



// Components
import { SmartListingComponent } from '@components/smart-listing/smart-listing.component';
import { NavBarComponent } from '@components/nav-bar/nav-bar.component';
import { NavBarPopoverComponent } from '@components/nav-bar-popover/nav-bar-popover.component';
import { AnimatedCounterComponent } from '@components-v2/animated-counter/animated-counter.component';
import { BubblesSpinnerComponent } from '@components-v2/bubbles-spinner/bubbles-spinner.component';
import { NavigationToolbarComponent } from './components-v2/navigation-toolbar/navigation-toolbar.component';
import { ButtonActionsComponent } from './components-v2/button-actions/button-actions.component';
import { PaymentInterfaceComponent } from './components-v2/payment-interface/payment-interface.component';
import { RollOverNavBarComponent } from './components-v2/roll-over-nav-bar/roll-over-nav-bar.component';

// import { ChatBarComponent } from '@components/chat-bar/chat-bar.component';
// import { PopoverComponent } from '@components/chat-bar/popover/popover.component';
import { EditorElementComponent } from '@components/editor-element/editor-element.component';
import { LoadingBoxComponent } from '@components/loading-box/loading-box.component';
import { ColorPickerComponent, ColorPickerPopoverComponent } from '@components/color-picker/color-picker.component';
import { DragGridComponent, SamDragAndDropListContentOutletDirective, SamDragAndDropListDataDirective } from '@components/drag-grid/drag-grid.component';
import { ImageBrowserComponent } from '@components/image-browser/image-browser.component';
import { ImageUploaderInterfaceComponent } from './components-v2/image-uploader-interface/image-uploader-interface.component';

import { ChatBarComponent } from '@components-v2/chat-bar/chat-bar.component';
import { ChatBarLegacyComponent } from '@components-v2/chat-bar/legacy/chat-bar.component';
import { ChatBarPopoverComponent } from '@components-v2/chat-bar/legacy/popover/popover.component';

import { AssetCardComponent, AssetCardComponentPopover } from './components-v2/asset-card/asset-card.component';
import { DashboardCardComponent } from './components-v2/dashboard-card/dashboard-card.component';
import { VideoModalComponent } from './components-v2/video-modal/video-modal.component';
import { AiBlobComponent } from './components-v2/ai-blob/ai-blob.component';
import { GwAiAssistantComponent } from './components-v2/gw-ai-assistant/gw-ai-assistant.component';
import { AiHitLimitComponent } from './components-v2/ai-hit-limit/ai-hit-limit.component';
import { ValidationWrapperComponent } from './components-v2/validation-wrapper/validation-wrapper.component';
import { AiLoadingScreenComponent } from './components-v2/ai-loading-screen/ai-loading-screen.component';
import { PopupShareLinkComponent } from './components-v2/popup-share-link/popup-share-link.component';
import { WebsiteHitLimitComponent } from './components-v2/website-hit-limit/website-hit-limit.component';
import { ImageCropInterfaceComponent } from './components-v2/image-crop-interface/image-crop-interface.component';
import { FunnelsCreateModalComponent } from './components-v2/funnels-create-modal/funnels-create-modal.component';
import { GwInputComponent } from './components-v2/gw-input/gw-input.component';
import { AssetInPackCreateModalComponent } from './components-v2/asset-in-pack-create-modal/asset-in-pack-create-modal.component';
// Pages

// Directives
import { ViewHostDirective } from '@directives/view-host.directive';
import { TrackingDirective } from './directives/tracking.directive';
import { InvokeDropdownDirective } from '@directives/custom-dropdown/invoke-dropdown.directive';
import { CustomDropdownPopoverComponent } from '@directives/custom-dropdown/popover/popover.component';
// import { PasswordInputToggleVisibilityDirective } from './directives/password-toggle.directive';

//
import { TruncatePipe } from '@pipes/truncate.pipe';
import { StripHtmlPipe } from '@pipes/striphtml.pipe';

// import { AlertPage } from './../app-v2/templates/alert/alert.page';
// import { AlertStudioPage } from './../app-v2/templates/alert-studio/alert.page';
import { DetailPage } from './../app-v2/templates/detail/detail.page';
import { DetailVariationPage } from './../app-v2/templates/detail-variation/detail-variation.page';

import { VideoPage } from './../app/script/video/video.page';
import { AlertPage } from './../app/project/alert/alert.page';

import { SafePipeModule } from 'safe-pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';

// PDF-EDITOR SHARED COMPONENTS
import { CanvasImagePage } from './../app-v2/pages/editor/modals/canvas-image/canvas-image.page';
import { NgxMasonryModule } from 'ngx-masonry';

import { ColorChromeModule } from 'ngx-color/chrome'; // <color-chrome></color-chrome>
import { ColorAlphaModule } from 'ngx-color/alpha';

import { TooltipComponent } from './components-v2/tooltip/tooltip.component';
import { TooltipDirective } from './directives/tooltip.directive';

import { BrainstormAiModule } from './components-v2/brainstorm-ai-widget/brainstorm-ai-widget.module';

import { ModalNotificationsComponent, PopoverProjectsComponent, PopoverProfileComponent, ModalDesignMapComponent, ModalCreateProjectAIComponent, ModalGettingStartedComponent, PopoverSitesComponent } from './components-v2/navigation-toolbar/toolbar-shared-actions.component';

// Profile Upgrade Modal Popup
import { ProfileUpgradeModalComponent } from './components-v2/profile-upgrade-modal/profile-upgrade-modal.component';
import { UniversalAccountUpgradeModalComponent } from './components-v2/universal-account-upgrade-modal/modal.component';

// Wizard Modal Popup (Builder & Childs)
import { WizardWrapperComponentComponent } from './components-v2/wizard-modals-builder/wizard-wrapper-component/wizard-wrapper-component.component';
import { WizardWebsiteCreateModalPage } from './components-v2/wizard-modals-builder/wizard-website-create-modal/wizard-website-create-modal.page';

import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxStripeModule } from 'ngx-stripe';

import { CampaignDuplicateComponent } from './components-v2/campaign-duplicate/campaign-duplicate.component';
// import { AssetInPackCreateModalComponent } from './components-v2/asset-in-pack-create-modal/asset-in-pack-create-modal.component';

const declarations = [
    // Components
    SmartListingComponent,
    NavBarComponent,
    NavBarPopoverComponent,
    AnimatedCounterComponent,
    BubblesSpinnerComponent,

    // ChatBarComponent,
    // PopoverComponent,
    EditorElementComponent,
    LoadingBoxComponent,
    DragGridComponent,
    ColorPickerComponent,
    ColorPickerPopoverComponent,

    ImageBrowserComponent, ImageUploaderInterfaceComponent, ImageCropInterfaceComponent,
    FunnelsCreateModalComponent,
    AssetInPackCreateModalComponent,

    SamDragAndDropListContentOutletDirective,
    SamDragAndDropListDataDirective,

    // V2
    ChatBarComponent,
    ChatBarLegacyComponent, ChatBarPopoverComponent,
    AssetCardComponent, AssetCardComponentPopover,
    DashboardCardComponent,
    VideoModalComponent,
    AiBlobComponent,
    GwAiAssistantComponent,
    AiHitLimitComponent,
    ValidationWrapperComponent,
    AiLoadingScreenComponent,
    PopupShareLinkComponent,
    WebsiteHitLimitComponent,
    ButtonActionsComponent,
    PaymentInterfaceComponent,
    RollOverNavBarComponent,
    GwInputComponent,

    NavigationToolbarComponent,
    ModalNotificationsComponent, PopoverProjectsComponent, PopoverProfileComponent, ModalDesignMapComponent, ModalCreateProjectAIComponent, ModalGettingStartedComponent, PopoverSitesComponent,
    
    ProfileUpgradeModalComponent,
    UniversalAccountUpgradeModalComponent, // parametrized by 'reason' (template, subscription, upgrade)

    TooltipComponent,
    TooltipDirective,

    WizardWrapperComponentComponent, // Wizards Builder (Abstract & Implementations)
    WizardWebsiteCreateModalPage, // Wizard Website Create Modal (Implementation)

    CampaignDuplicateComponent,

    // Pages
    DetailPage,
    DetailVariationPage,
    VideoPage,
    AlertPage,
    CanvasImagePage,
    AssetInPackCreateModalComponent,
    // Directives
    ViewHostDirective,
    TrackingDirective,
    InvokeDropdownDirective,
    CustomDropdownPopoverComponent,
    //PasswordInputToggleVisibilityDirective,
    // Pipes
    TruncatePipe,
    StripHtmlPipe,
];

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        RouterModule,
        MomentModule,
        FormsModule,
        ReactiveFormsModule,
        // PdfViewerModule,
        SafePipeModule,
        EditorModule,
        DragDropModule,
        NgxMasonryModule,
        ColorChromeModule,
        ColorAlphaModule,

        ImageCropperModule,
        NgxStripeModule,
        
        // QuillModule.forRoot()
    ],
    declarations: declarations,
    exports: declarations,
    providers: [
        { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
    ]
})

export class CustomComponentsModule {
}