import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { ModalController, PopoverController, AlertController, AnimationController } from '@ionic/angular';
import { DataSourceService } from '@services/data-source.service';
import { ToastController } from '@ionic/angular';
import { LoadingController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse
} from "@angular/common/http";
import { environment } from '@env/environment';

@Component({
  selector: 'app-image-browser',
  templateUrl: './image-browser.component.html',
  styleUrls: ['./image-browser.component.scss']
})
export class ImageBrowserComponent implements OnInit {

  env = environment;

  tab = 0;
  subTab = null;
  menus: any = ['My Photos', 'Unsplash', { title: 'Assets', menus: null }, 'Graphical Elements'];

  assetData: any = null;
  assetFilters: any = {};

  @Input() stageId = null;

  page: any = 0;
  items: any = [];
  total = 0;
  search = '';
  active;
  loading = false;

  unsplashTags = ['abstract','animal','architecture','art','business','colorful','food','interior','minimal','nature','plant','portrait','space','technology','texture','wallpaper'];
  unsplashPage: any = 0;
  unsplashItems: any = [];
  unsplashTotal = 0;
  unsplashSearch = '';
  unsplashActive;
  unsplashLoading = false;

  assetsItems: any = [];
  assetsItemsIndex = 0;
  assetsActive;
  assetsLoading = false;

  graphicsItems = [];
  graphicsActive;
  graphicsLoading = false;
  graphicSearch = '';

  assetsGalleryAdditionalData = {};
  @Input() linkedAssetId = null;

  @ViewChild('uploader', {static: false}) uploader: ElementRef;

  constructor(
    public modalController: ModalController,
    public dataSource: DataSourceService,
    public alertController: AlertController,
    public toastController: ToastController,
    public loadingController: LoadingController,
    private storage: Storage,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    // getRecentMedia
    if ( this.linkedAssetId ) {
      (this.assetsGalleryAdditionalData as any).asset_type = this.linkedAssetId;
    }
    this.loadMore();
    this.loadMoreAssets();
    this.loadMoreGraphics();
    this.loadAssetData();

    console.log( '[Stage Id Passed]: ' + ( this.stageId ? 'Stage Id Case' : 'Non Stage Id') );
  }

  async setTab(index) {
    this.subTab = null;

    if ( index === 0 ) {
      // this.loadMore();
    }
    if ( index === 1 ) {
      // this.loadMoreUnsplash();
    }
    if ( index === 2 ) {
      // this.loadMoreAssets();
      this.menus[2].toggle = !this.menus[2].toggle;
      if ( this.menus[2].toggle ) {
        this.loadAssetData();
      }
      return;
    }

    this.tab = index;
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss();
  }

  // Regular uploader
  uploadPhotos(ev) {
    this.setTab(0);
    setTimeout(() => {
      (this.uploader.nativeElement.click(ev));
    }, 1);

  }
  loadMore(ev?) {
    this.loading = true;
    this.page++;
    const limit = 10;
    this.dataSource.getRecentMedia({ q: this.search, offset: (this.page*limit)-limit, limit }).subscribe( res => {
      if ( this.page === 1 ) {
        this.items = [];
      }
      if ( res && res.results ) {
        res.results.forEach( (item) => {
          this.items.push(item);
        });
        this.total = res.count;
      }
      this.loading = false;
      try{
        ev.target.complete();
      } catch (err) {

      }
    });
  }
  searchRecent(ev?) {
    this.page = 0;
    // this.items = [];
    this.loadMore();
  }
  changeRecent(ev) {
    console.log(ev);
    if ( this.search === '' ) {
      this.searchRecent();
    }
  }
  selectImage(item) {
    if ( this.active === item ) {
      this.active = null;
    } else {
      this.active = item;
      this.selectActive();
    }
  }
  selectActive(ev?) {
    this.modalController.dismiss({
      image: this.active,
      value: this.active.background,
      source: 'recent'
    });
  }
  uploadImage(elem) {
    const file = elem.srcElement.files[0];
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      fetch( (reader as any).result )
      .then(async (fetchRes) => {
        const loading = await this.loadingController.create({
          message: 'Uploading...',
          duration: 20000
        });
        await loading.present();
        let bytesToSize = (bytes) => {
           let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
           if (bytes == 0) return '0 Byte';
           let i: any = parseInt('' + Math.floor(Math.log(bytes) / Math.log(1024)), 10);
           return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
        }
        console.log(file);
        const toast = await this.toastController.create({
          color: 'light',
          message: file.name,
          // cssClass: 'refresh-toast',
          // message: bytesToSize(file.size),
          position: 'bottom',
          buttons: [
            {
              side: 'end',
              // icon: 'star',
              text: bytesToSize(file.size),
              handler: () => {
                console.log('Favorite clicked');
                // toast.dismiss()
              },
            },
          ]
        });
        await toast.present();
        // Preview srcElement.parentElement.children[1].children
        // const preview = elem.srcElement.parentElement.children[1].children[0];
        const formData = new FormData();
        formData.append('temp_file', await fetchRes.blob(), Math.random().toString(36).substr(2, 5) + '.png');
        formData.append('name ', '' + file.name);

        // formData.append('link_type', '3');
        this.dataSource.api.setHeader('Content-Type', 'multipart/form-data; boundary=----WebKitFormBoundaryuL67FWkv1CA');
        let endpoint;
        endpoint = this.dataSource.postRecentMediaImageData(formData, this.stageId, false);
        endpoint.subscribe(res => {
          console.log('Image uploaded', res);
          res.name = file.name;
          res.background = res.image;
          this.items.unshift(res);
          this.total++;
          try {
            loading.dismiss();
          } catch (err) {

          }
          setTimeout( () => toast.dismiss(), 3000);
        }, err => {
          console.log(err);
          setTimeout( () => {
            loading.dismiss();
            toast.dismiss();
          }, 1000);
        });
        this.dataSource.api.unsetHeader('Content-Type');
      })
      .then(console.log);
    }, false);
    if (file) {
      reader.readAsDataURL(file);
    }
    return;
  }

  async destroyImage(elem, item) {
    const alert = await this.alertController.create({
      cssClass: 'application-v2 alert-v2 delete-icon alert-wrapper my-custom-class',
      header: 'Confirm!',
      message: 'Are you sure you want to delete this image from library?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'white',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          },
        },
        {
          text: 'Delete',
          cssClass: 'danger',
          handler: () => {
            console.log('Confirm Okay', elem, item);

            if ( this.items && this.items.length ) {
              const removeIndex = this.items.map(item => item.id).indexOf(item.id);
              ~removeIndex && this.items.splice(removeIndex, 1);

              const endpoint = this.dataSource.deleteRecentMediaImage(item.id, false);
              endpoint.subscribe(res => {
              });
            }

          },
        },
      ],
    });

    await alert.present();
  }
  // End Regular


  // Unsplash
  unsplashSelectTag(tag) {
    this.unsplashSearch = tag;
    this.doSearchUnsplash();
  }
  changeUnsplash(ev) {

  }
  doSearchUnsplash(ev?) {
    this.unsplashItems = [];
    this.unsplashPage = 0;
    console.log('nameSeacrh', ev);
    if ( this.unsplashSearch ) {
      this.loadMoreUnsplash(ev);
    }
  }
  loadMoreUnsplash(ev) {
    this.unsplashLoading = true;
    this.unsplashPage++;
    this.dataSource.getUnsplashForPreview(this.unsplashSearch, this.unsplashPage, 20).subscribe( res => {
      this.unsplashTotal = res.total;
      res.results.forEach( (item) => {
        this.unsplashItems.push(item);
      });
      this.unsplashLoading = false;
      try{
        ev.target.complete();
      } catch (err) {

      }
    });
  }
  selectImageUnsplash(item) {
    if ( this.unsplashActive === item ) {
      this.unsplashActive = null;
    } else {
      this.unsplashActive = item;
      this.selectActiveUnsplash();
    }
  }
  selectActiveUnsplash(ev?) {
    console.log('selectActiveUnsplash', this.unsplashActive);
    const data: any = {
      download_location: this.unsplashActive.download_location,
      id: this.unsplashActive.id,
      thumbnail: this.unsplashActive.thumbnail,
      full: this.unsplashActive.full,
    };
    if ( this.stageId ) {
      data.stage_id = this.stageId; // Optional if in context
    }
    this.dataSource.postUnsplashDownloadData(
      data
    ).subscribe( res => {

      // this.dataSource.getRecentMedia({ q: '', offset: 0, limit: 1 }).subscribe( res => {});

      this.modalController.dismiss({
        image: {
          background: this.unsplashActive.full,
          thumbnail_picture: this.unsplashActive.thumbnail,
          name: this.unsplashActive.id,
        },
        value: this.unsplashActive.full,
        source: 'unsplash'
      });

    });



    /*
    this.modalController.dismiss({
      image: this.unsplashActive
    });
    */
  }
  // End UnSplash

  // Assets
  assetsSetActive(event) {
    console.log('setActiveAssets', event);
    this.assetsItemsIndex = event.detail.value;
  }
  async loadMoreAssets(ev?) {
    this.assetsLoading = true;

    this.dataSource.getAssetsForPreview(this.dataSource.currentActiveProject.id).subscribe( (res) => {
      this.assetsLoading = false;
      this.assetsItems = res;
    });
  }
  selectAssetImage(item) {
    if ( this.assetsActive === item ) {
      this.assetsActive = null;
    } else {
      this.assetsActive = item;
      this.selectActiveAsset();
    }
  }
  selectActiveAsset(ev?) {
    console.log('selectActiveAsset', this.assetsActive);
    this.modalController.dismiss({
      image: {
        background: this.assetsActive.picture,
        thumbnail_picture: this.assetsActive.thumbnail,
        name: this.assetsActive.title,
      },
      value: this.assetsActive.picture,
      source: 'asset'
    });

    /*
    this.modalController.dismiss({
      image: this.assetsActive,
      value: this.assetsActive.picture,
      source: 'asset'
    });
    */
  }
  // End Assets

  // Start Graphics
  async loadMoreGraphics(ev?) {
    this.graphicsLoading = true;
    //
    this.dataSource.getGraphicsList().subscribe( (res) => {
      this.graphicsLoading = false;
      this.graphicsItems = res;
      if( this.env.production === false ) {
        this.graphicsItems.push({
          name: 'demo',
          url: '/assets/graphical-elements/demo.png'
        })
      }
    });


  }

  selectGraphicsImage(item) {
    this.graphicsActive = item;
    this.selectActiveGraphic();
  }

  selectActiveGraphic(ev?) {
    console.log('this.graphicsActive', this.graphicsActive);
    this.modalController.dismiss({
      // image: this.assetsActive,
      image: {
        background: this.graphicsActive.url,
        thumbnail_picture: this.graphicsActive.url,
        name: this.graphicsActive.name,
      },
      value: this.graphicsActive.url,
      source: 'graphic'
    });
  }

  searchFiltered(array) {
    return array.filter(({name}) => name.includes(this.graphicSearch));
  }

  changeGraphics(ev){
    // graphicSearch
  }

  // let searchByFirstName = str => students.filter(({name}) => name.includes(str))
  setSubTab(subTab) {
    this.tab = 2;
    this.subTab = subTab;
    let inject = {};
    if ( this.assetData && this.assetData.id ) {
      inject = { asset_type: this.assetData.id };
    }
    this.dataSource.getGrowthworksAssetsGallery({ 
      themes_list: this.subTab, 
      ...this.assetsGalleryAdditionalData,
    }).subscribe( (res) => {

      this.assetFilters['themes_list'] = this.subTab;
      this.prepareFilters(res);

    });
  }

  toggleAccordion(item) {
    item.toggle = !item.toggle;
  }

  loadAssetData() {
    this.subTab = null;
    this.dataSource.getGrowthworksAssetsGallery({
      ...this.assetsGalleryAdditionalData
    }, false).subscribe( (res) => {

      console.log('loadAssetData', res);
      this.menus[2].menus = res.asset_type_filters;
      this.menus[2].toggle = true;

      this.prepareFilters(res);

    });
  }

  prepareFilters(res) {
    this.assetData = res;
    this.assetData.filters = {};
    delete this.assetFilters['campaign'];
    delete this.assetFilters['asset'];
    delete this.assetFilters['pack'];
    /*
      {
        "id": 1928,
        "title": "Untitled",
        "current": true
      },
    */
    let campaignsFilters = [];
    res.campaigns.forEach(element => {
      if( element.current ) {
        this.assetFilters['campaign'] = element.id;
      }
      campaignsFilters.push({
        label: element.title,
        value: element.id,
        // badge: element.current ? 'current' : null,
        // icon: 
      });
    });
    console.log('campaignsFilters', campaignsFilters, {assetData: this.assetData});
    this.assetData.filters['campaign'] = campaignsFilters;

    //
    let assetFilters = [];
    res.assets.forEach(element => {
      if( element.current ) {
        this.assetFilters['asset'] = element.id;
      }
      assetFilters.push({
        label: element.title,
        value: element.id,
        // badge: element.current ? 'current' : null,
        // icon: 
      });
    });
    console.log('assetFilters', assetFilters, {assetData: this.assetData});
    this.assetData.filters['asset'] = assetFilters;

    //
    let packFilters = [];
    res.packs.forEach(element => {
      if( element.current ) {
        this.assetFilters['pack'] = element.id;
      }
      packFilters.push({
        label: element.title,
        value: element.id,
        // badge: element.current ? 'current' : null,
        // icon: 
      });
    });
    console.log('packFilters', packFilters, {assetData: this.assetData});
    this.assetData.filters['pack'] = packFilters;

    this.assetData.filterState = JSON.stringify(this.assetFilters);

    console.warn('Prepare media gallery filters', this.assetData);

    // assetsGalleryAdditionalData
    
  }

  debounce;

  changeAssetFilters(ev, key) {

    try{

      console.log('changeAssetFilters', { filters: this.assetFilters, event: ev });

      if( this.assetData.filterState !== JSON.stringify(this.assetFilters) ) {
        this.assetFilters[key] = parseInt(ev.detail.value, 10); // ev;
        
        const filterState = JSON.parse(this.assetData.filterState);
        if ( filterState.campaign !== this.assetFilters.campaign ) {
          delete this.assetFilters['asset'];
          delete this.assetFilters['pack'];
        }
        
        if ( this.debounce) {
          clearTimeout(this.debounce);
        }
        this.debounce = setTimeout( () => {
          this.dataSource.getGrowthworksAssetsGallery({
            ...this.assetFilters,
            ...this.assetsGalleryAdditionalData
          }).subscribe( (res) => {
            console.log('loadAssetData', res);
            this.prepareFilters(res);
          });
        }, 1000);
        
      }

    } catch( err ) {
      console.log('changeAssetFilters Error', err);
    }

  }

}
