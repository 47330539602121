import { Directive, HostListener, Input, AfterViewInit, HostBinding, AfterContentInit } from '@angular/core';

import { Tracking } from '@app/-core/services/tracking.service';

@Directive({
  selector: '[tracking]',
  // usageExample: tracking="skip_btn_strategy" [trackingData]="{ event_category: 'Generate AI', event_label: 'Location: Generate Your Strategy Page' }"
})
export class TrackingDirective implements AfterContentInit {

  @Input('tracking') tag: any = 'click';
  @Input('trackingData') data;

  drawDebug: boolean = false;

  constructor(
    public tracking: Tracking
  ) { 
    try{
      this.drawDebug = (window as any).localStorage.getItem('tracking-debug') === 'true';
    } catch(err){

    }
  }

  @HostBinding('attr.trackingdebug') debugData: any;
  @HostBinding('attr.trackingoptionsdebug') debugOptionsData: any;

  @HostListener('click', ['$event']) onClick($event){
    // console.info('TrackingDirective - click:', $event);
    this.tracking.event(this.tag, this.data, $event);
  }

  ngAfterContentInit() {
    // Your code here
    if ( this.drawDebug ) {
      this.debugData = this.tag || 'unknown';
      if ( this.data ) {
        this.debugOptionsData = ( this.data.event_category || 'Unknown' ) + ' - ' + ( this.data.event_label || 'Unknown' );
      }
    }
  }

}
