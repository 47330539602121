<div class="image-uploader">

  <div class="thumbnail" [attr.style]="'background-image: url(' + (value || '/assets/v2/icons/new-image.png') + ');'">

  </div>

  <div class="controlls">
    <div class="information">
      {{ this.name || 'No uploaded images' }}
      <!-- delete --->
      <ion-icon class="delete" *ngIf="ready && this.name" (click)="clearDataValue($event)" name="trash-outline"></ion-icon>
    </div>

    <ion-button (click)="invokeUploader($event)" [disabled]="!ready">
      Upload Image
    </ion-button>
  </div>
  
</div>
