import { Component, OnInit, Input } from '@angular/core';

import { Router } from '@angular/router';

import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-nav-bar-popover',
  templateUrl: './nav-bar-popover.component.html',
  styleUrls: ['./nav-bar-popover.component.scss'],
})
export class NavBarPopoverComponent implements OnInit {

  @Input()
  public dismiss = () => {}

  constructor(
    public router: Router,
    public auth: AuthService,
  ) { }

  ngOnInit() {}

  profile() {
    //
    this.router.navigate(['/app/profile']);
    this.dismiss();
  }

  support() {
    //
    console.log( (window as any).Intercom('show') );
  }

  brand() {
    this.router.navigate(['/app/brand-center']);
    this.dismiss();
  }

  manage() {
    this.router.navigate(['/app/manage/templates']);
    this.dismiss();
  }

  coach(path) {
    // conversations
    this.router.navigate(['/app/coach-center/' + path]);
    this.dismiss();
  }

  logout() {
    this.router.navigate(['/auth/logout']);
    this.dismiss();
  }

}
