<div class="application-v2">
<div>

  <div>

    <div (click)="dismiss(6)" class="menu-item no-margin">
      <div class="label">
        <div class="icon">
          <ion-icon name="text-outline"></ion-icon>
        </div>
        <div class="title">Rename</div>
      </div>
    </div>

    <div (click)="dismiss(1)" class="menu-item no-margin">
      <div class="label">
        <div class="icon">
          <ion-icon name="pencil-outline"></ion-icon>
        </div>
        <div class="title">Edit</div>
      </div>
    </div>

    <div (click)="dismiss(4)" class="menu-item no-margin">
      <div class="label">
        <div class="icon">
          <ion-icon name="sync-outline"></ion-icon>
        </div>
        <div class="title">Update from plan</div>
      </div>
    </div>

    <div (click)="dismiss(2)" class="menu-item no-margin">
      <div class="label">
        <div class="icon">
          <ion-icon name="copy-outline"></ion-icon>
        </div>
        <div class="title">Duplicate</div>
      </div>
    </div>

    <div (click)="dismiss(3)" class="menu-item no-margin ion-color-danger">
      <div class="label">
        <div class="icon">
          <ion-icon name="trash-outline"></ion-icon>
        </div>
        <div class="title">Delete</div>
      </div>
    </div>


    <div (click)="dismiss(5)" *ngIf="!item.has_pack" class="menu-item no-margin">
      <div class="label">
        <div class="icon">
          <ion-icon [name]="!item.is_published ? 'mail-outline' : 'mail-open-outline'"></ion-icon>
        </div>
        <div class="title">{{ !item.is_published ? 'Publish' : 'Draft'}}</div>
      </div>
    </div>

    <!--
    <ng-container>
      <ion-button size="small" color="dark" fill="clear" expand="block" (click)="publishUnpublish(script.id, !script.is_published, script)">{{ !script.is_published ? 'Publish' : 'Draft'}}</ion-button>
    </ng-container>
    -->

  </div>

</div>
</div>
