import { Component, HostListener, Directive, HostBinding, Input, EmbeddedViewRef, ElementRef, ApplicationRef, Injector, ComponentFactoryResolver, ComponentRef } from '@angular/core';

import { TooltipComponent } from "./../components-v2/tooltip/tooltip.component";
import { TooltipLargeComponent } from "./../components-v2/tooltip-large/tooltip-large.component";

@Directive({
  selector: '[tooltip]'
})
export class TooltipDirective {

  @Input() tooltip = '';
  // @Input() tooltipDirection = 'default';
  @Input() tooltipComponent = 'small'; // TooltipComponent / TooltipLargeComponent
  @Input() tooltipWidth:any = 360;
  @Input() tooltipHeight:any = 220;

  private componentRef: ComponentRef<any> = null;

  constructor(
  	private elementRef: ElementRef,
  	private appRef: ApplicationRef,
  	private componentFactoryResolver: ComponentFactoryResolver,
  	private injector: Injector
  ) {
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    if (this.componentRef === null) {
        const componentFactory =
              this.componentFactoryResolver.resolveComponentFactory(
                this.tooltipComponent === 'small' ? TooltipComponent : TooltipLargeComponent );
        this.componentRef = componentFactory.create(this.injector);
        this.appRef.attachView(this.componentRef.hostView);
        const domElem =
              (this.componentRef.hostView as EmbeddedViewRef<any>)
              .rootNodes[0] as HTMLElement;
        document.body.appendChild(domElem);
        this.setTooltipComponentProperties();
    }
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.destroy();
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  destroy(): void {
    if (this.componentRef !== null) {
      this.appRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }

  private setTooltipComponentProperties() {
    if (this.componentRef !== null) {
      this.componentRef.instance.tooltip = this.tooltip;
      this.componentRef.instance.tooltipWidth = this.tooltipWidth + 36;
      // this.componentRef.instance.tooltipHeight = this.tooltipHeight;
      const boundry = this.elementRef.nativeElement.getBoundingClientRect();
      console.log('boundry', boundry);
      const {top, left, right, bottom} = this.elementRef.nativeElement.getBoundingClientRect();
      
      if ( this.tooltipComponent === 'small' ) {
        this.componentRef.instance.left = right + 12; // (right - left) / 2 + left;
        this.componentRef.instance.top = (bottom - top) / 2 + top; // bottom;
      }

      if ( this.tooltipComponent === 'large' ) {
        this.componentRef.instance.left = ((left - right) / 2) + right - (this.tooltipWidth/2) + 0 - 16; // bottom;
        this.componentRef.instance.top = ((bottom - top) / 2 + top - 16) - 160;
      }

    }
  }

}
