import { Component, OnInit, Input } from '@angular/core';

import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';

import { ModalController } from '@ionic/angular';
import { AuthService } from '@app/-core/services/auth.service';

import { DataSourceService } from '@app/-core/services/data-source.service';

@Component({
  selector: 'app-image-crop-interface',
  templateUrl: './image-crop-interface.component.html',
  styleUrls: ['./image-crop-interface.component.scss'],
})
export class ImageCropInterfaceComponent implements OnInit {

  @Input() thumb: any; // optional
  @Input() image: any;
  // @Input() name: any;
  @Input() crop: any;

  cropperPosition: any = {};

  ready = false;
  uploading = false;

  constructor(
    public modalCtrl: ModalController,
    public authService: AuthService,
    public dataSource: DataSourceService,
  ) { }

  ngOnInit() {
    console.log('ImageCropInterfaceComponent ngOnInit', this.image, this.crop);
    this.ready = false;
  }

  imageChangedEvent: any = '';
  croppedImage: any = '';

  fileChangeEvent(event: any): void {
      // this.imageChangedEvent = event;
      // console.log('ImageCropInterfaceComponent fileChangeEvent', event);
  }
  imageCropped(event: ImageCroppedEvent) {
      console.log('ImageCropInterfaceComponent imageCropped', event);
      this.croppedImage = event.blob;
      // console.log('ImageCropInterfaceComponent imageCropped', event, this.cropperPosition);
      // this.crop = this.cropperPosition.x1 + '/' + this.cropperPosition.y1 + '/' + this.cropperPosition.x2 + '/' + this.cropperPosition.y2;
  }
  imageLoaded(image: LoadedImage) {
      // show cropper
      // console.log('true imageLoaded', image);
      this.ready = true;

      setTimeout(() => {
        if (this.crop) {
          // console.log('crop', this.crop);
          const crop = this.crop.split('/');
          this.cropperPosition = {
            x1: parseInt(crop[0], 10),
            y1: parseInt(crop[1], 10),
            x2: parseInt(crop[2], 10),
            y2: parseInt(crop[3], 10)
          };
        }
      }, 100);
  }
  cropperReady() {
      // cropper ready
      // console.log('ImageCropInterfaceComponent cropperReady');
  }
  loadImageFailed() {
      // show message
      // console.log('ImageCropInterfaceComponent loadImageFailed');
  }



  dismiss() {
    this.modalCtrl.dismiss({
      cancel: true
    });
  }

  save() {
    this.crop = this.cropperPosition.x1 + '/' + this.cropperPosition.y1 + '/' + this.cropperPosition.x2 + '/' + this.cropperPosition.y2;

    this.modalCtrl.dismiss({
      cropped: true,
      value: this.croppedImage,
      crop: this.crop,
    });
  }

  saveAsRecent() {

    this.ready = false;
    
    const formData = new FormData();
    const genName = Math.random().toString(36).substr(2, 5);
    formData.append('temp_file', this.croppedImage, genName + '-crop.png');
    formData.append('name ', 'Crop ' + genName);

    // formData.append('link_type', '3');
    this.dataSource.api.setHeader('Content-Type', 'multipart/form-data; boundary=----WebKitFormBoundaryuL67FWkv1CA');
    let endpoint;
    endpoint = this.dataSource.postRecentMediaImageData(formData, null, false);
    endpoint.subscribe(res => {

      this.crop = this.cropperPosition.x1 + '/' + this.cropperPosition.y1 + '/' + this.cropperPosition.x2 + '/' + this.cropperPosition.y2;

      this.modalCtrl.dismiss({
        cropped: true,
        value: res.image,
        crop: this.crop,
      });

    }, err => {

    });
    this.dataSource.api.unsetHeader('Content-Type');
    
  }

}
