<!-- Hello im sites

<a href="https://www.google.com" target="_blank" (click)="dismiss(0)">Open</a> -->
<div class="application-v2 sites-component">
  <div class="popover-content">
    <div class="popup-title">
      CURRENT APP
    </div>
    <div class="app-box">
      <div class="app-logo original"></div>
      <div>
        <div class="app-title">
          Growthwork
        </div>
        <div class="app-sub">
          Content Generation
        </div>
      </div>
      <ion-icon name="checkmark-outline"></ion-icon>
    </div>
    <div class="border-line"></div>
    <div class="popup-title">
      OTHER APPS
    </div>
    <a href="https://crm.growthworks.io/" target="_blank" (click)="dismiss(0)">
      <div class="app-box ion-margin-bottom">
        <div class="app-logo crm"></div>
        <div>
          <div class="app-title">
            Growthworks CRM
          </div>
          <div class="app-sub">
            Automation
          </div>
        </div>
      </div>
    </a>
    <a href="https://app.launchmaps.io/" target="_blank" (click)="dismiss(0)">
      <div class="app-box">
        <div class="app-logo launchmaps"></div>
        <div>
          <div class="app-title">
            Launchmaps
          </div>
          <div class="app-sub">
            Planning
          </div>
        </div>
      </div>
    </a>
  </div>
</div>