<ion-content>
  <wizard-wrapper #wizardRef [modalRef]="this">

    <div class="controls">
      
      <h1 class="title">Generate Authority Website</h1>

      <div class="field-list">

        
        <div class="label">Select Campaign</div>
        <div class="field">
          <ion-select [disabled]="!data" [(ngModel)]="values.campaign" name="campaign" interface="popover" placeholder="Select Campaign">
            <ng-container *ngIf="data">
              <ion-select-option *ngFor="let campaign of data.campaigns; let i = index;" [value]="i + 1">{{ campaign.title }}</ion-select-option>
            </ng-container>
          </ion-select>
        </div>


        <div class="label">Select Hot Step</div>
        <div class="field">
          <ion-select [disabled]="!data || !values.campaign" [(ngModel)]="values.hot_step" name="hot_step" interface="popover" placeholder="Select Hot Step">
            <ng-container *ngIf="values.campaign">
              <ion-select-option *ngFor="let hotstep of data.campaigns[values.campaign - 1].hot_steps" [value]="hotstep.order + 1">{{ hotstep.title }}</ion-select-option>
            </ng-container>
          </ion-select>
        </div>


      </div>

      <div class="actions">

        <ion-button [disabled]="!values.hot_step" size="large" color="primary" (click)="saveOptions()">Select Content <ion-icon name="chevron-forward-outline"></ion-icon></ion-button>
        &nbsp;
        <!--
        <ion-button size="large" color="dark" fill="outline" (click)="saveOptions()">Skip & Use Default</ion-button>
        -->
      </div>

    </div>

  </wizard-wrapper>
</ion-content>