<div class="button-wrapper">

  <div class="button-container">

    <div class="button-inner-slot">
      <ng-content></ng-content>
    </div>

    <div class="button-action" (click)="emitAction($event)">
      <ion-icon name="chevron-down-outline"></ion-icon>
    </div>

  </div>

</div>
