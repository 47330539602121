import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PopoverController, AlertController } from '@ionic/angular';
import { DataSourceService } from '@app/-core/services/data-source.service';

@Component({
  selector: 'app-campaign-duplicate',
  templateUrl: './campaign-duplicate.component.html',
  styleUrls: ['./campaign-duplicate.component.scss'],
})
export class CampaignDuplicateComponent implements OnInit {

  @Input() cloningData: any = {};
  @Input() item: any;
  @Input() campaingList: any;
  // @Input() cloningStatus: any;
  @Output() reloadEvent = new EventEmitter<void>();

  status = false;

  // items = [
  //   { name: 'Plan Data (Campaign Planner, Product Roadmap)', id: 1 },
  //   { name: 'Design Studio', id: 2 },
  //   { name: 'Assets', id: 3, dropdown: 'true', inside: [
  //     {assetName: 'Ninja Nurture Sequence 1', updateTime: 'Last Updated June 18'},
  //     {assetName: 'Ninja Nurture Expansion 2', updateTime: 'Last Updated May 24'},
  //     {assetName: 'Ninja Nurture Sequence 1', updateTime: 'Last Updated June 18'},
  //     {assetName: 'Ninja Nurture Expansion 2', updateTime: 'Last Updated May 24'},
  //     {assetName: 'Ninja Nurture Sequence 1', updateTime: 'Last Updated June 18'},
  //     {assetName: 'Ninja Nurture Expansion 2', updateTime: 'Last Updated May 24'},
  //     {assetName: 'Ninja Nurture Sequence 1', updateTime: 'Last Updated June 18'},
  //     {assetName: 'Ninja Nurture Expansion 2', updateTime: 'Last Updated May 24'}
  //   ] },
  // ];

  // items = [
  //   { name: 'Plan Data (Campaign Planner, Product Roadmap)', id: 'plan', checked: 'true', disabled: 'true' },
  //   { name: 'Design Studio', id: 'design' },
  //   { name: 'Assets', id: 'asset' , dropdown: 'true' },
  // ];

  items = [
    { name: 'Plan Data (Campaign Planner, Product Roadmap)', id: 'plan', checked: true, disabled: true },
    { name: 'Design Studio', id: 'design', checked: false, disabled: false },
    { name: 'Assets', id: 'asset', checked: false, disabled: false },
  ];

  private isChangingState = false;

  constructor (
    public popoverController: PopoverController,
    public dataSource: DataSourceService,
    private cdr: ChangeDetectorRef,
    private alertController: AlertController
  ) {}

  ngOnInit() {
    console.log('cloningData', this.cloningData);
    console.log('item', this.item.id); // Campaign ID
    console.log('campaignList', this.campaingList); //Campaign List
    // console.log('cloningStatus', this.cloningStatus); //Cloning Status  
  }

  clickToggle(item) {
    item.toggle = !item.toggle;
  }

  onCheckboxChange(item, event) {

    if (item.disabled) {
      return;
    }

    if (this.isChangingState) return;

    this.isChangingState = true;

    if (item.id === 'asset') {
      item.checked = event.detail.checked;
      this.cloningData.assets.forEach(subItem => {
        subItem.checked = item.checked;
      });
    } else {
      item.checked = event.detail.checked;
    }

    this.cdr.detectChanges();
    this.isChangingState = false;
  }

  onSubItemCheckboxChange(item, subItem, event) {
    if (this.isChangingState) return;

    this.isChangingState = true;

    subItem.checked = event.detail.checked;

    if (item.id === 'asset') {
      if (this.cloningData.assets.every(subItem => !subItem.checked)) {
        item.checked = false;
      } else {
        item.checked = true;
      }
    }

    this.cdr.detectChanges();
    this.isChangingState = false;
  }

  async projectLimit() {
    const alert = await this.alertController.create({
      message: 'You have reached your campaign limit. Contact support to learn more about unlocking unlimited campaign in your account; support@fletchermethod.com',
      buttons: ['OK']
    });
    await alert.present();
  }
  
  duplicateCampaign() {

    // Cloning status check 'Source' status
    const selectedCampaing = this.campaingList.find(campaign => campaign.id === this.item.id);
    // console.log('selectedCampaing', selectedCampaing);
    if (selectedCampaing.cloning_status === 'source') {
      console.log('Status is Source', selectedCampaing);
      this.status = true;
    }
    // Cloning status end

    const requestData: any = {};

    const designItem = this.items.find(item => item.id === 'design');
    requestData.design_studio = designItem && designItem.checked;

    const assetItem = this.items.find(item => item.id === 'asset');
    if (assetItem && assetItem.checked) {
      const selectedAssets = this.cloningData.assets.filter(subItem => subItem.checked).map(subItem => subItem.id);
      if (selectedAssets.length === this.cloningData.assets.length) {
        requestData.all_assets = true;
      } else {
        requestData.assets_ids = selectedAssets;
      }
    }

    const campaignId = this.item.id;
    if (!campaignId) {
      console.error('Campaign ID not found');
      return;
    }

    this.dataSource.postGrowthworksCloningCampaign(campaignId, requestData).subscribe(response => {
      console.log('Cloning started', response);
    }, error => {
      console.error('Cloning failed', error);
      if (error.status === 403) {
        this.projectLimit();
      }
    });
    this.popoverController.dismiss({ reload: true, status: this.status });
    this.reloadEvent.emit();
  }

  dismiss() {
    this.popoverController.dismiss();
  }

}
