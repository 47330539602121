import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'input-validation',
  templateUrl: './validation-wrapper.component.html',
  styleUrls: ['./validation-wrapper.component.scss'],
})
export class ValidationWrapperComponent implements OnInit {

  @Input() state: any = null; // Null is none, true is success, false is error
  @Input() message: string;

  constructor() { }

  ngOnInit() {}

}
