import { Component, OnInit, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-animated-counter',
  templateUrl: './animated-counter.component.html',
  styleUrls: ['./animated-counter.component.scss'],
})
export class AnimatedCounterComponent implements OnInit {

  @Input() public count: number | string = 0;

  public displayCount: number | string = 0;

  steps = 20;

  constructor() { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    
    if ( !this.count ) {
      this.displayCount = 0;
      return;
    } else {

      if ( typeof this.count === 'string' ) {
        this.displayCount = '' + this.count;
        return;
      } else {
        if ( typeof this.displayCount === 'string' ) {
          this.displayCount = this.count;
        }
      }

      for( let i = 0; i < this.steps; i++ ) {
        const stepValue = (this.count - (this.displayCount as number)) / this.steps;
        setTimeout(() => {
          this.displayCount = (this.displayCount as number) + stepValue;
        }, 25 * i);
      }
    }

  }

  _renderMode() {
    if ( typeof this.count === 'string' ) {
      return 'string';
    } else {
      return 'number';
    }
  }

}
