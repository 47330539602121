  <!--
  <div>
      <input type="color" id="body" name="body"
              [(ngModel)]="value"/>
      <label for="body">Body</label>
  </div>

  {{ this.value | json }}
  -->

<div class="active-area">
<div class="section section-picker">

  <div class="main picker-component" [ngClass]="{ 'no-value' : !this.value }">

    <color-chrome #pickerState [color]="( this.value ? this.value : '#407b7e' )" (onChange)="change($event)" (onChangeComplete)="changeComplete($event)"></color-chrome>

    <color-alpha-picker [color]="( this.value ? this.value : '#407b7e' )" (onChange)="changeAlpha($event)" (onChangeComplete)="changeCompleteAlpha($event)"></color-alpha-picker>

  </div>

  <div class="main">
    <ion-row>
      <ion-col size="5" class="ion-align-self-center">

        <div class="color-box" (click)="removeColor()">
          <div class="inner-color" [ngStyle]="{ 'background-color' : this.value }">

          </div>
        </div>

        <div class="color-mode">
          Hex
        </div>

      </ion-col>
      <ion-col size="7" class="ion-align-self-center">

        <div class="color-input">
          <ion-input (ionChange)="onValidInput($event, pickerState)" (ionBlur)="validateInput($event, pickerState)" [(ngModel)]="this.value" placeholder="Theme Color"></ion-input>
        </div>

      </ion-col>
    </ion-row>

    <ion-row *ngIf="opacity">
      <ion-col>
        


      </ion-col>
    </ion-row>

  </div>

</div>

<div class="main">

  <div class="main">
    <div *ngIf="brandData && brandData.colors && brandData.colors.length" class="main">Platform colors</div>
    <div *ngIf="brandData && brandData.colors && brandData.colors.length">

      <div class="box" (click)="setColor(color.color_hex)" [title]="color.color_hex" [ngStyle]="{ 'background-color' : color.color_hex }" *ngFor="let color of brandData.colors;">
        {{ color.color_hex }}
      </div>

    </div>
  </div>

  <div class="main">

    <div *ngIf="recentData && recentData.colors && recentData.colors.length" class="main">Recent colors</div>
    <div *ngIf="recentData && recentData.colors && recentData.colors.length">

      <div class="box" (click)="setColor(color)" [title]="color" [ngStyle]="{ 'background-color' : color }" *ngFor="let color of recentData.colors;">
        {{ color }}
      </div>

    </div>

  </div>

</div>
</div>

  <!--
  <ion-button (click)="submit($event)">sumbit</ion-button>
  -->
