<ion-header class="large">
  <ion-toolbar>
    <ion-title>
      Getting Started
      <small>
      How to get the most out Growthworks
      </small>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button color="dark" (click)="dismiss()">
         <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="compact" color="white" *ngIf="sidemenuProgress">


          <div class="todo-list">

            <ng-container *ngFor="let option of sidemenuProgress.data; let i = index;">

              <div (click)="toggleOption(option)" class="todo-item" [ngClass]="{ 'done' : option.progress, 'open' : option.open }">

                <div>
                  <div class="icon" (click)="checkmarkDone(option)">
                    <ion-icon name="checkmark-outline"></ion-icon>
                  </div>
                </div>

                <div>
                  <div class="content">
                    <b>{{ option.title }}</b>
                    <div class="fold">
                      <p>{{ option.description }}</p>
                      <div class="img" (click)="formProcessor.openVideoPlayer(option.video)">
                        <img [src]="option.video_preview"/>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </ng-container>

            <!--
            <ng-container *ngFor="let item of [1]">

              <div class="todo-item" [ngClass]="{ 'done' : item < 2}">

                <div>
                  <div class="icon">
                    <ion-icon name="checkmark-outline"></ion-icon>
                  </div>
                </div>

                <div>
                  <div class="content">
                    <b><s>1. Compaign Planner</s></b>
                  </div>
                </div>

              </div>

            </ng-container>

            <div class="todo-item open">

              <div>
                <div class="icon"></div>
              </div>

              <div>
                <div class="content">
                  <b>2. Design Studio</b>
                  <p>Messaging Plan with a lot of interaction points, which you should consider to your work</p>
                  <div class="img" (click)="formProcessor.openVideoPlayer('')">
                    <ion-img src="assets/v2/blocks/video.png" class="ion-margin-top"></ion-img>
                  </div>
                </div>
              </div>

            </div>

            <div class="todo-item default">

              <div>
                <div class="icon"></div>
              </div>

              <div>
                <div class="content">
                  <b>3. Compaign Planner</b>
                </div>
              </div>

            </div>

            <div class="todo-item default">

              <div>
                <div class="icon"></div>
              </div>

              <div>
                <div class="content">
                  <b>4. Assets</b>
                </div>
              </div>

            </div>
            -->

          </div>





</ion-content>
