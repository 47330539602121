<ion-header>
  <ion-toolbar>
    <ion-title>Select Campaign</ion-title>
    <ion-buttons slot="end">
      <ion-button color="dark" (click)="modalController.dismiss()">
         <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>


<ion-content color="white">

  <!-- <div class="ion-padding">

    <div class="ion-text-center">
    <img src="/assets/alert-new-canvas.png"/>
    </div>
    <br/>

    Hey there, we noticed you have not published your product design asset yet.<br/>
    Please complete Product design asset and publish the asset.<br/>
    <br/>
    Creating lead magnet before your product design asset is completed & published will leave you with more information to fill in or require you to delete and recreate the asset.
  </div> -->



  <div class="item" (click)="select(item)" *ngFor="let item of cmps;">

    {{ item.title }} <div class="unread" *ngIf="item.unread">{{ item.unread }}</div>

  </div>


</ion-content>
